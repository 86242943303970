.press-images {
    @include custom-container;
    > .row {
        > .col-auto {
            padding: 10px;
            padding-bottom: 4px;
            &:first-child {
                .ms-content-block {
                    overflow: hidden;
                }
                .ms-content-block__image {
                    height: auto;
                    max-height: 30rem;
                    overflow: hidden;
                    border-radius: 4px;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        // transition: all 0.3s ease-in-out;
                        transform: scale(1.5);
                    }
                    @include breakpoint(sm) {
                        height: 40rem;
                        max-height: fit-content;
                    }
                    @include breakpoint(md) {
                        height: 52rem;
                    }
                    img {
                        border-radius: 4px;
                        @include img-fit;
                        object-fit: contain;

                        @include breakpoint(sm) {
                            object-fit: cover;
                        }
                    }
                }
            }
            &:nth-child(2) {
                .row {
                    gap: 2rem;
                    @include breakpoint(sm) {
                        gap: 0;
                    }
                    @include breakpoint(md1) {
                        gap: 2rem;
                    }
                    .col-12 {
                        overflow: hidden;
                        padding: 0;
                        &:first-child {
                            padding-right: 0;

                            @include breakpoint(sm) {
                                padding-right: 1rem;
                                padding-left: 1rem;
                            }
                            @include breakpoint(md1) {
                                padding-left: 0;
                            }
                        }
                        &:last-child {
                            padding-left: 0;

                            @include breakpoint(sm) {
                                padding-left: 1rem;
                                padding-right: 1rem;
                            }
                            @include breakpoint(md1) {
                                padding-left: 0;
                            }
                        }
                    }
                }

                .ms-content-block__image {
                    height: auto;
                    max-height: 30rem;
                    overflow: hidden;
                    transition: all 0.3s ease-in-out;
                    border-radius: 4px;
                    &:hover {
                        transform: scale(1.5);
                    }
                    @include breakpoint(sm) {
                        height: 25rem;
                    }
                    img {
                        border-radius: 4px;
                        @include img-fit;
                        object-fit: contain;

                        @include breakpoint(sm) {
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}
