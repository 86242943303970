.ms-order-confirmation {
    width: 84% !important;
    margin: 5rem auto !important;
    @include breakpoint(md) {
        width: 90% !important;
        // margin: 4rem auto !important;
    }
    @include breakpoint(md1) {
        margin: 6rem auto !important;
        width: 92% !important;
    }

    box-shadow: 0px 4px 12px 0px rgba(163, 163, 163, 0.25);
    padding: 4rem;
    border-radius: 8px;
    // margin: 5rem auto !important;
    background: #fff;
    p,
    span {
        font-family: $font-family-primary;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.04em;
        text-align: left;
    }
    // --------------------- order header-------------------//
    .order-confirmation-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;
        margin-bottom: 4rem;
        padding-bottom: 3rem;
        border-bottom: 1px solid rgba(232, 214, 192, 1);
        > .ms-order-confirmation__btn-keep-shopping {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            width: auto;
        }
        h3 {
            font-family: $font-family-primary;
            font-size: 2.6rem;
            font-weight: bold;
            line-height: 140%;
            letter-spacing: 0.04em;
            text-align: left;
            margin: 0;
            text-transform: capitalize;
        }
        .back-img-div {
            height: 3rem;
            width: 3rem;
            cursor: pointer;
            img {
                @include img-fit;
            }
        }
    }
    // ---------------------columns layout ------------------------------//
    .order-info-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        gap: 2rem;
        @include breakpoint(md1) {
            flex-direction: row;
            gap: 5rem;
        }
        .order-details-col,
        .order-items-col,
        .order-summary-col {
            // flex: 1;
            width: 100%;
            height: 100%;
            min-height: auto;
            @include breakpoint(md1) {
                width: 33.33%;
                min-height: 30rem;
            }
        }
        .order-items-col {
            position: relative;
            &::before,
            &::after {
                position: absolute;
                content: '';
                top: 0;
                width: 1px;
                height: 100%;
                background: transparent;
                @include breakpoint(md1) {
                    background: #e8d6c0;
                }
            }
            &:before {
                left: -2.5rem;
            }
            &:after {
                right: -2.5rem;
            }
        }
    }

    // 1st columns-------------------------------------------------------------------------------------------
    .ms-order-confirmation__order-summary-heading {
        font-family: $font-family-primary;
        font-size: 2.6rem;
        font-weight: bold;
        line-height: 140%;
        letter-spacing: 0.04em;
        text-align: left;
        margin-bottom: 2.4rem;
        padding-bottom: 2.4rem;
        border-bottom: 1px solid rgba(232, 214, 192, 1);
    }
    .ms-order-confirmation__order-information {
        padding: 0;
    }
    .space-between {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        width: 100%;
        p {
            margin-bottom: 0;
        }
    }
    .ms-order-confirmation__payment-methods {
        width: 100% !important;
        padding: 0 !important;
        > .row {
            width: 100%;
        }
        .ms-order-confirmation__payment-methods-card {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 0;
            text-align: right;
        }
    }
    .ms-order-confirmation__order-information-channel-reference-id {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .ms-order-confirmation__payment-methods-line {
        margin: 0;
    }
    // Hide QR Code
    .ms-order-confirmation__order-information-qr-code {
        display: none;
    }
    .ms-order-confirmation__payment-methods-title {
        margin: 0;
        font-family: $font-family-primary;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.04em;
        text-align: left;
    }
    // -------------------------------------------------------------------------------------------------------
    // -------------------------------------- 2nd columns-----------------------------------------------------------------

    .ms-order-confirmation__group-deliveryType-ship.salesStatus-processing {
        padding: 0;
        display: flex;
    }
    .ms-order-confirmation__group-deliveryType-ship.salesStatus-processing {
        > .ms-order-confirmation__sales-lines {
            display: flex;
            flex-direction: column;
            padding: 0;
        }
    }
    // -------------------------------------------------------------------------------------------------------
    .ms-order-confirmation__groups {
        max-height: 30rem;
        overflow-y: auto;
    }
    .ms-order-confirmation__groups,
    .ms-order-confirmation__group-deliveryType-ship {
        border: 0;
        .ms-order-confirmation__sales-line {
            margin-bottom: 0;
            p {
                margin-bottom: 12px;
                font-weight: bold;
            }
        }
    }

    .ms-order-confirmation__order-summary-line-total-amount {
        border-top: 1px solid rgba(232, 214, 192, 1);
    }
    .ms-order-confirmation__order-summary {
        padding: 0;
        width: 100%;
        border-top: 0;
    }
    .download-pdf {
        @include cta_styling($primary-blue);
        // width: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 0;
        border: none;

        @include breakpoint(sm) {
            width: max-content;
            min-width: 200px;
            padding-left:10px;
            padding-right:10px;
        }
    }
}
