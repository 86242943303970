@import "./00-settings/colors.scss";
@import "./00-settings/font.scss";
@import "./01-tools/mixins.scss";
@import "./00-settings/icons.scss";

$msv-checkout-payment-instrument-min-height: 80px;
$msv-checkout-payment-instrument-card-title-margin-bottom: 8px;
$msv-checkout-payment-instrument-address-title-margin-bottom: 8px;
$msv-checkout-payment-instrument-address-title-margin-top: 20px;
$msv-checkout-payment-instrument-loading-dimension: 100%;
$msv-checkout-payment-instrument-border: 0;
$msv-checkout-payment-container-padding: 0;
$msv-checkout-payment-container-margin-zero: 0;
$msv-checkout-payment-ready-margin-top: 20px;
$msv-checkout-payment-ready-margin-bottom: 20px;
$msv-checkout-payment-ready-flex-basis: 100%;
$msv-checkout-payment-ready-margin-left: 10px;
$msv-checkout-payment-instrument-mutation-observer-margin-top: 20px;
$msv-checkout-payment-instrument-heading-margin-bottom: 8px;
$msv-checkout-payment-instrument-paypal-max-width: 300px;
$msv-checkout-payment-instrument-error-padding: 20px;
$msv-checkout-payment-instrument-icon-margin-right: 22px;
$msv-checkout-payment-instrument-error-message-margin-left: 42px;
$msv-checkout-payment-instrument-error-margin-bottom: 44px;
$msv-checkout-payment-instrument-error-margin-left: 8px;
$msv-checkout-payment-instrument-googlepay-max-width: 340px;
$msv-checkout-payment-instrument-googlepay-margin-top: -3px;

//style presets
:root {
    --msv-checkout-payment-instrument-bg: #{$msv-gray-100};

    // error
    --msv-checkout-payment-instrument-error-message-bg: #{$msv-pink};
    --msv-checkout-payment-instrument-error-message-border: transparent;
    --msv-checkout-payment-instrument-error-message-color: #{$msv-red};
}

.checkout-payment-instrument {
    position: relative;
    min-height: $msv-checkout-payment-instrument-min-height;

    &__loading-background {
        display: flex;
        height: $msv-checkout-payment-instrument-loading-dimension;
        position: absolute;
        width: $msv-checkout-payment-instrument-loading-dimension;
        background-color: var(--msv-checkout-payment-instrument-bg);

        .waiting {
            display: block;
            margin: auto;
        }
    }

    &__iframe {
        border: $msv-checkout-payment-instrument-border;
        width: $msv-checkout-payment-instrument-loading-dimension;
    }

    &__show {
        &-card-title {
            font-weight: var(--msv-font-weight-bold);
            line-height: $msv-line-height-s;
            margin-bottom: $msv-checkout-payment-instrument-card-title-margin-bottom;
        }

        &-billing-address-title {
            margin-bottom: $msv-checkout-payment-instrument-card-title-margin-bottom;
            margin-top: $msv-checkout-payment-instrument-address-title-margin-top;
        }

        &-card-name,
        &-card-number,
        &-card-expiration,
        &-billing-address-1,
        &-billing-address-2,
        &-billing-address-3 {
            margin-bottom: $msv-checkout-payment-container-margin-zero;
        }
    }
}

.ms-checkout-payment-instrument {
    display: flex;
    flex-direction: column-reverse;

    &__overlay-modal {
        display: none;
    }

    &__error {
        background-color: var(--msv-checkout-payment-instrument-error-message-bg);
        border: 1px solid var(--msv-checkout-payment-instrument-error-message-border);
        color: var(--msv-checkout-payment-instrument-error-message-color);
        padding: $msv-checkout-payment-instrument-error-padding;
        margin-bottom: $msv-checkout-payment-instrument-error-margin-bottom;
        border-radius: 3px;
        margin-left: $msv-checkout-payment-instrument-error-margin-left;

        &-title {
            display: flex;
            align-items: center;

            @include font-body-bold-m();
            @include add-icon($msv-times-circle, before);
            color: var(--msv-checkout-payment-instrument-error-message-color);

            &::before {
                margin-right: $msv-checkout-payment-instrument-icon-margin-right;
                font-size: $msv-font-size-ml;
            }
        }

        &-message {
            @include font-body-regular-m();
            display: block;
            color: var(--msv-checkout-payment-instrument-error-message-color);
            margin-left: $msv-checkout-payment-instrument-error-message-margin-left;
        }
    }
}

.ms-checkout-payment-instrument-paypal {
    display: block;

    .ms-checkout-payment-instrument__add {
        max-width: $msv-checkout-payment-instrument-paypal-max-width;
        float: left;
    }
}

.ms-checkout-payment-instrument-googlepay {
    transform: scale(0.92);
    margin-top: $msv-checkout-payment-instrument-googlepay-margin-top;

    .ms-checkout-payment-instrument__add {
        max-width: $msv-checkout-payment-instrument-googlepay-max-width;
        float: left;
    }
}

.ms-checkout-section-container {
    padding-left: $msv-checkout-payment-container-padding;
    padding-right: $msv-checkout-payment-container-padding;

    &__item {
        .ms-text-block {
            @include font-body-regular-xs();
            background: $msv-blue-20;
            border-radius: 10px;
            padding: 20px;

            h3 {
                @include font-body-bold-m();
                padding-bottom: 8px;
            }
        }
    }
}

.ms-checkout__guided-card.ready {
    .ms-checkout-payment-instrument {
        margin-top: $msv-checkout-payment-ready-margin-top;
        margin-bottom: $msv-checkout-payment-ready-margin-bottom;

        &__show {
            display: flex;
            flex-flow: row wrap;
        }

        &__card-title {
            @include font-heading-h5-l();
            flex-basis: $msv-checkout-payment-ready-flex-basis;
            margin-bottom: $msv-checkout-payment-instrument-heading-margin-bottom;
        }

        &__billing-address-title {
            @include font-heading-h5-l();
            flex-basis: $msv-checkout-payment-ready-flex-basis;
            margin-bottom: $msv-checkout-payment-instrument-address-title-margin-bottom;
            margin-top: $msv-checkout-payment-instrument-address-title-margin-top;
        }

        &__card-number {
            margin-left: $msv-checkout-payment-ready-margin-left;
        }

        &__card-name,
        &__card-number,
        &__card-expiration,
        &__card-type-id {
            @include font-body-regular-m();
        }

        &__card-name,
        &__card-expiration {
            flex-basis: $msv-checkout-payment-ready-flex-basis;
        }

        &__card-type-id {
            text-transform: lowercase;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        &__billing-address-1,
        &__billing-address-2,
        &__billing-address-3 {
            @include font-body-regular-m();
        }
    }
}

.ms-checkout__guided-card.updating {
    .ms-checkout-payment-instrument__mutation-observer {
        margin-top: $msv-checkout-payment-instrument-mutation-observer-margin-top;
    }
}
