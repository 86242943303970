.ms-search-result-container__refiner-section {
    .ms-search-result-container__refine-menu {
        .ms-refine-submenu {
            .msc-btn {
                font-family: $font-family-primary;
                font-size: 2rem;
                font-weight: 400;
            }
            .ms-refine-submenu__toggle_expanded {
                &::after {
                    background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB2bhN');
                    content: '' !important;
                    font-family: 'Roboto';
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 2rem;
                    width: 20px;
                    height: 20px;
                    transition: all 0.3s ease-in-out;
                    transform: rotate(180deg);
                }
            }
            .ms-refine-submenu__toggle_collapsed {
                &::after {
                    background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB2bhN');
                    content: '' !important;
                    font-family: 'Roboto';
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 2rem;
                    width: 20px;
                    height: 20px;
                    transition: all 0.3s ease-in-out;
                }
            }
            > div {
                transition: all 0.3s ease-in-out;
                .ms-refine-submenu__list {
                    .ms-refine-submenu-item {
                        .multi-select,
                        .single-select {
                            position: relative;
                            &:focus {
                                border: 0;
                                outline: 0;
                            }
                            &:before {
                                content: '';
                                position: absolute;
                                border: 2px solid #102750;
                                background: transparent;
                                left: 0px;
                                top: 10px;
                                width: 22px;
                                height: 22px;
                                z-index: 1;
                                border-radius: 2px;
                            }
                        }
                        .multi-select-checked,
                        .single-select-checked {
                            position: relative;

                            &:before {
                                content: '';
                                background: transparent;
                                position: absolute;
                                z-index: 2;
                                top: 13px;
                                color: #fff;
                                left: 7px;
                                width: 7px;
                                height: 13px;
                                border: solid white;
                                border-width: 0px 0.3rem 0.3rem 0px;
                                -webkit-transform: rotate(45deg);
                                -ms-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }
                            &::after {
                                content: '';
                                position: absolute;
                                background: #102750;
                                left: 0px;
                                top: 10px;
                                width: 22px;
                                height: 22px;
                                z-index: 1;
                                border-radius: 2px;
                            }
                            &:focus,
                            &:focus-visible {
                                border: 0 !important;
                                outline: none !important;
                            }
                        }
                        a {
                            text-decoration: none;
                            .ms-refine-submenu-item__label {
                                padding-left: 30px;
                                font-family: $font-family-primary;
                                font-size: 1.8rem;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
}
.ms-search-result-container__refiner-section .slider .slider__thumb {
    background: $primary-blue;
}

.ms-search-result-container__refiner-section .slider .slider__track {
    background-color: $primary-blue;
}
.ms-search-result-container__refiner-section .slider .slider__labels-item {
    font-family: $font-family-primary;
    font-size: 1.6rem;

}
