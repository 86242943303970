.arabic {
    .ms-search-result-container {
        &__title{
            letter-spacing:0;
        }
        .msc-button {
            color: #1e1e1e;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            @include breakpoint(md1, max-width) {
                float: left;
            }
        }
        &__refine-product-section {
            .ms-search-result-container__refiner-section {
                .ms-search-result-container__refine-menu {
                    .ms-refine-submenu {
                        > div {
                            .ms-refine-submenu__list {
                                .ms-refine-submenu-item {
                                    a {
                                        .ms-refine-submenu-item__label {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .merchandising-single-product {
        .ms-buybox__media-gallery-div {
            > ul {
                @include breakpoint(md) {
                    right: auto;
                    left: 0;
                }
            }
        }
    }
    .ms-search-result-container__refiner-section {
        .ms-search-result-container__refine-menu {
            .ms-refine-submenu {
                .msc-btn {
                }
                .ms-refine-submenu__toggle_expanded {
                    &::after {
                    }
                }
                .ms-refine-submenu__toggle_collapsed {
                    &::after {
                    }
                }
                > div {
                    .ms-refine-submenu__list {
                        .ms-refine-submenu-item {
                            .multi-select,
                            .single-select {
                                &:before {
                                    right: 0;
                                    left: auto;
                                }
                            }
                            .multi-select-checked,
                            .single-select-checked {
                                &:before {
                                    right: 7px;
                                    left: auto;
                                }
                                &::after {
                                    left: auto;
                                    right: 0;
                                }
                            }
                            a {
                                .ms-refine-submenu-item__label {
                                    padding-left: 12px;
                                    padding-right: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ms-search-result-wrapper-sort-by-category {
        .ms-search-result-container__Sort-by-category {
            label {
                margin-right: 0;
                margin-left: 8px;
            }
            select {
                background-position: 4%;
            }
        }
    }
    .msc-review-modal-body {
        .ms-search-result-container__Sort-by-category {
            label {
                margin-right: 0;
                margin-left: 8px;
            }
            select {
                background-position: 4% !important;
            }
        }
    }

    .msc-choice-summary {
        .msc-choice-summary__item {
            padding: 3px 13px 5px 6px;
            margin-left: 12px;
            margin-right: 0;
        }
    }
    .msc-modal__dialog.msc-search-result-modal {
        .msc-modal__content .msc-modal__header {
            .msc-modal__close-button {
                right: auto;
                left: 20px;
            }
        }
    }
  
}

// .merchandising-single-product {
//     .ms-buybox__media-gallery-div {
//         @include breakpoint(md1) {
//         }
//         .ms-buybox__media-gallery {
//             @include breakpoint(md) {
//             }
//             ul.msc-ss-carousel-vert-slide {
//                 li {
//                     img {
//                     }
//                 }
//             }
//         }
//         > ul {
//             @include breakpoint(md) {
//             }
//             li {
//             }
//         }
//     }
//     .ms-buybox__content {
//         @include breakpoint(md1) {
//         }
//         .ms-buybox__quantity {
//             .ms-buybox__product-quantity-label-heading {
//             }
//             .quantity {
//                 button {
//                 }
//                 .quantity-input {
//                 }
//             }
//         }
//     }

//     .ms-buybox__configure {
//         .ms-buybox__dropdown {
//             label {
//                 background-color: red !important;
//             }
//             .msc-swatch-container {
//                 &__item {
//                     &__selected {
//                     }
//                 }
//             }
//         }
//     }
// }
