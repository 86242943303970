@keyframes slideIn {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
.ms-cart-icon {
    > div {
        animation: slideIn 0.5s ease-in-out;
        // transition: width 0.3s ease-in-out;
    }
}
.ms-cart-icon__flyout-container {
    width: 0;
    transition: width 0.3s ease-in-out;
}

.ms-header .ms-cart-icon__flyout-container.show {
    
    height: 90vh;
    width: 400px;
    background: #fff;
    position: fixed !important;
    top: 0 !important;
    bottom: auto;
    right: auto !important;
    left: calc(100vw - 417px) !important;
    transform: none !important;
    z-index: 1060;
    transition: width 0.3s ease-in-out;

    @media screen and (max-width: 767px) {
        display:none !important;
    }

    &:before{
        position: absolute;
        content: '';
        width: 5px;
        height: 5px;
        z-index: 1;
        bottom: -10px;
        right: 62px;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid #fff;
    }
}
