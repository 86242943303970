.gcp-container {
    background: #fff;
    padding-bottom: 0;
    margin-bottom: -4px;
    .gcp-head {
        background-image: url(https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBIdX?pubver=1);
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        padding: 0px 0px 40px;
        @include breakpoint(md) {
            padding: 20px 0px 100px;
        }
        h2 {
            color: $primary-blue !important;
        }
    }
}

// -----------------------------------
.gcp-container.partners-container {
    .ms-content-block {
        box-shadow: none !important;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: none;
        height: auto;
        margin: 0;
        background: transparent;
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease-in-out;

        &:hover {
            .ms-content-block__details {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%),
                    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
                top: 0 !important;
                height: 100%;
                transition: all 0.3s ease-in-out;
            }

            .ms-content-block__text {
                visibility: visible;
                margin-top: 0;
                display: block !important;
            }
            // Remove CTA/ Learn More Button
            .ms-content-block__cta {
                // display: block !important;
            }

            .ms-content-block__title:before {
                background: red;
            }
        }

        .ms-content-block__image {
            img {
                width: 100%;
                object-fit: cover;
                object-position: center;
                transition: all 0.3s ease-in-out;
            }
        }
        .ms-content-block__details {
            position: absolute;
            top: 50%;
            width: 100%;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 0;
            text-align: center;
            transition: all 0.3s ease-in-out;

            .ms-content-block__title {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 30px;
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 600;
                font-size: 3.6rem;
                line-height: 140%;
                /* identical to box height, or 56px */

                letter-spacing: -0.3px;
                text-transform: uppercase;

                color: #ffffff;
                position: relative;
                width: fit-content;
                margin: 0 auto 50px auto;
                
                @include breakpoint(xxxl){
                    font-size: 4rem;
                }
                &:before {
                    @include bottom-line;
                    width: 80%;
                    bottom: -10px;
                    left: auto;
                    right: auto;
                    bottom: -20px;
                }
            }
            .ms-content-block__text {
                // visibility: hidden;
                // margin-top: 270px;
                display: none;
                transition: all 0.3s ease-in-out;
                padding:0px 9% 0 8%;
                p {
                    font-size: 16px;
                }
            }
            .ms-content-block__cta {
                display: none;
                padding: 5px 12px;
                width: 222px;
                height: 40px;
                border: 2px solid #ffffff;

                border-radius: 24px;
                margin-top: 50px;
                transition: all 0.3s ease-in-out;
                &:hover {
                    background: #000;
                }
                a {
                    color: #fff;
                    text-decoration: none;
                    font-size: 1.6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                }
            }
        }
    }
}

// -----------------------------------

.gcp-container.partners-container .slick-track .slick-slide:hover {
    z-index: 1;
    position: relative;
}
// .gcp-container.partners-container .slick-track:hover .slick-slide > div {
//     //  border:4px solid red;
// }
.gcp-container.partners-container .slick-track .slick-slide {
    transition: all 0.3s ease-in-out;
}
.gcp-container.partners-container .slick-track .slick-slide:hover > div {
    //  border:4px solid green;

    transform: scale(1.2, 1);
}

// .gcp-container.partners-container .slick-track .slick-slide:hover ~ .slick-slide > div {
//     //  border:4px solid yellow;
// }

.gcp-container.partners-container
    .slick-track
    .slick-slide:hover
    > div
    .ms-content-block
    .ms-content-block__details
    .ms-content-block__title {
    transform: scale(0.8, 0.9);
}
.gcp-container.partners-container
    .slick-track
    .slick-slide:hover
    > div
    .ms-content-block
    .ms-content-block__details
    .ms-content-block__text {
    transform: scale(0.8, 0.9);
}

.gcp-container.partners-container
    .slick-track
    .slick-slide:hover
    > div
    .ms-content-block
    .ms-content-block__details
    .ms-content-block__cta {
    transform: scale(0.8, 0.9);
}
.gcp-container.partners-container .slick-track .slick-slide:hover > div .ms-content-block .ms-content-block__image img {
    transform: scale(1.5);
}

.gcp-container.partners-container .slick-track .slick-slide > * {
    transition: all 0.3s ease-in-out;
}

.gcp-container.partners-container {
    .slick-track {
        .slick-slide {
            &:nth-child(1),
            &:nth-child(n + 5) {
                .ms-content-block__title {
                    &:before {
                        background: #0037a8 !important;
                    }
                }
            }
            &:nth-child(2),
            &:nth-child(2n + 5) {
                .ms-content-block__title {
                    &:before {
                        background: #aa2d2d !important;
                    }
                }
            }
            &:nth-child(3),
            &:nth-child(3n + 5) {
                .ms-content-block__title {
                    &:before {
                        background: $primary_yellow !important;
                    }
                }
            }
            &:nth-child(4),
            &:nth-child(4n + 5) {
                .ms-content-block__title {
                    &:before {
                        background: #3d9546 !important;
                    }
                }
            }
            &:nth-child(5),
            &:nth-child(5n + 5) {
                .ms-content-block__title {
                    &:before {
                        background: #9747ff !important;
                    }
                }
            }
        }
    }
}

// Arrows
.gcp-container.partners-container .slick-arrow.slick-next {
    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBkOS?pubver=1');
    background-repeat: no-repeat;
    background-position: center;
    width: 35px;
    height: 35px;
    top: 57%;
    right: 2%;
}

.gcp-container.partners-container .slick-arrow.slick-next:before,
.gcp-container.partners-container .slick-arrow.slick-next:before {
    content: '';
}
.gcp-container.partners-container .slick-arrow.slick-prev {
    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBkOY?pubver=0');
    background-repeat: no-repeat;
    background-position: center;
    width: 35px;
    height: 35px;
    top: 57%;
    left: 2%;
    z-index: 2;
}
