.contact_us {
    // background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBNqI?pubver=0');
    background-repeat: no-repeat;
    background-size: contain;
    // background-size: 100% $secondary-banner-size;
    // background-position: center 0;

    .secondary-banner {
        background: $banner-overlay;
        height: 100%;
        // height: auto;
        display: flex;
        align-items: center;
        padding: 0 6rem;
        padding-top: 9rem;
        @include breakpoint(md) {
            padding-top: $header-height;
        }
        // @include breakpoint(sm) {
        //     height: 300px;
        // }
        // background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 101%);

        h2 {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 2.5rem;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-transform: uppercase;
            color: #ffffff;
            @include breakpoint(sm) {
                font-size: 4rem;
            }
        }
    }
}
