.rules-list {
    > .row {
        // column-gap: 8rem;
        justify-content: space-between;
        > .col-12 {
            max-width: 100%;
            @include breakpoint(sm) {
                max-width: 50%;
            }
            @include breakpoint(md1) {
                max-width: 23%;
            }
            .ms-content-block {
                margin-top: 3.5rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 24px 14px;
                gap: 20px;

                // width: 272px;
                height: 216px;

                /* Base/background white */

                background: #ffffff;
                /* Gray/200 */

                border: 1px solid #eaecee;
                border-radius: 12px;

                .ms-content-block__image {
                    width: 100px;
                    height: 100px;
                    img {
                        @include img-fit;
                    }
                }
                p {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 150%;
                    /* or 24px */

                    text-align: center;

                    /* Gray/700 */

                    color: #373f47;
                }
            }
        }
    }
}
