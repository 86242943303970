.status-modal {
    width: auto;
    @include breakpoint(md) {
        max-width: 60rem !important;
    }

    .msc-modal__content {
        .msc-modal__header {
            .msc-modal__title {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding-bottom: 1.5rem;
                border-bottom: 1px solid #d0cfcd;
                h3 {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 2.7rem;
                    line-height: 3.8rem;
                    letter-spacing: 0.04em;
                    color: #1e1e1e;
                }
                button {
                    outline: none;
                    border: none;
                    background: transparent;
                    width: 3rem;
                    height: 3rem;
                }
            }
        }
        .ms-content-block__image {
            height: 20rem;
            width: 20rem;
            margin: 1rem auto;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        h4 {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.4rem;
            text-align: center;
            letter-spacing: 0.04em;
            text-transform: capitalize;
            color: #1e1e1e;
        }
    }
}
