.competition-register-btn-container {
    > button {
        @include cta_styling($primary_blue);
        border: 0;
    }
}
.competition-register-btn-container.yellow-btn {
    .competition-register-btn-container{
        > button {
            @include cta_styling($primary_yellow);
            border: 0;
        }
    } 
}
//====================Register Modal==========================//
.competition-register-modal {
    @include breakpoint(md1, max-width) {
        height: auto;
    }
    @include breakpoint(md, max-width) {
        min-width: 90vw !important;
        margin-left: auto !important;
        margin-right: auto !important;
        max-height: 85vh;
        overflow-y: auto;
    }
    .msc-modal__content {
        .msc-modal__header {
            .msc-modal__title {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding-bottom: 1.5rem;
                border-bottom: 1px solid #d0cfcd;
                h3 {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 2.7rem;
                    line-height: 3.8rem;
                    letter-spacing: 0.04em;
                    color: #1e1e1e;
                }
            }
        }
        .msc-modal__body {
            padding-top: 15px;
            form {
                .form-floating,
                .phone-group {
                    margin-bottom: 3rem !important;

                    @include breakpoint(xxxl) {
                        margin-bottom: 3.5rem !important;
                    }
                    .form-control {
                        // margin-bottom: 3rem;
                        border: 1px solid #79747e !important;
                        border-radius: 4px;

                        &:focus {
                            color: #212529;
                            background-color: #fff !important;
                            border-color: $primary-blue !important;
                            outline: 0 !important;
                            box-shadow: 0 0 0 0.05rem $primary-blue;
                        }
                    }
                    label {
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1.6rem;
                        // line-height: 24px;
                        letter-spacing: 0.5px;
                        color: #49454f;
                        margin-top: 3px;
                        height: auto;
                        // @include breakpoint(xs) {
                        //     font-size: 15px;
                        // }
                        @include breakpoint(sm) {
                            font-size: 1.4rem;
                        }
                    }
                }
                // ===============================//
                .phone-group {
                    .invalid-feedback {
                        display: block;
                    }
                }
                .form-control {
                    // margin-bottom: 3rem;
                    border: 1px solid #79747e;
                    border-radius: 4px;
                    &:focus {
                        color: #212529;
                        background-color: #fff;
                        border-color: $primary-blue;
                        outline: 0;
                        box-shadow: 0 0 0 0.05rem $primary-blue;
                    }
                }
                .form-control.is-invalid {
                    &:focus {
                        box-shadow: none;
                    }
                }
                .form-control.invalidphone {
                    &:focus {
                        border-color: #dc3545 !important;
                        box-shadow: none;
                    }
                }
    
                .form-control.invalidphone + .flag-dropdown {
                    border-color: #dc3545 !important;
                    box-shadow: none;
                }
                //================================//

                // Floating Labels
                .form-floating > .form-control:focus ~ label,
                .form-floating > .form-control:not(:placeholder-shown) ~ label,
                .form-floating > .form-select ~ label {
                    height: 10px !important;
                }
                .tnc-checkbox-div {
                    padding: 0;
                    .custom-checkbox {
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 1.5rem;
                        line-height: 140%;
                        /* or 20px */
                        text-align: justify;
                        letter-spacing: -0.3px;
                        color: $primary-blue;
                        margin-bottom: 0;
                    }
                }
                .tnc-link {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.3rem;
                    line-height: 140%;
                    /* or 20px */
                    letter-spacing: -0.3px;
                    color: $primary-blue;
                    cursor: pointer;
                    margin-bottom: 15px;
                }
                .submit-registration {
                    @include cta_styling($primary-blue);
                    border: 0;
                }
                .multiSelect-container-div {
                    label {
                        font-size: 1.4rem !important;
                        opacity: 1 !important;
                        transform: scale(0.85) translateY(-2rem) translateX(-4rem) !important;
                        background: #ffffff;
                        padding-right: 4px;
                        padding-left: 4px;
                        height: 10px !important;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        position: absolute;

                        z-index: 1;
                        top: 1px !important;
                        left: 3.7rem !important;
                        background: #fff;
                        height: 15px !important;
                        @include breakpoint(sm) {
                            top: 7px !important;
                            left: 3.7rem !important;
                        }
                    }
                    .invalid-feedback {
                        display: block !important;
                    }
                }
                .multiSelectContainer {
                    input {
                        border: none !important;
                        font-family: $font-family-primary;
                    }
                    .optionListContainer {
                        z-index: 5;
                    }
                }
            }
        }
    }
}
// ====================== TnC Modal =========================== //
.competitions-tnc-modal {
    @include breakpoint(md1, max-width) {
        height: auto;
    }
    @include breakpoint(md, max-width) {
        min-width: 90vw !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .msc-modal__content {
        max-height: 70vh;
        @include breakpoint(md) {
            max-height: 680px;
        }

        overflow-y: auto;
        .msc-modal__header {
            .msc-modal__title {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 500;
                font-size: 2.7rem;
                line-height: 3.8rem;
                letter-spacing: 0.04em;
                color: #1e1e1e;
                font-weight: bold;
            }
        }
        .msc-modal__body {
            h4 {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: bold;
                font-size: 2.1rem;
                line-height: normal;
                color: #1e1e1e;
            }
            ul {
                list-style: auto;
                li {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.7rem;
                    line-height: normal;
                    color: #1e1e1e;
                }
            }
        }
    }
}
