.ms-cart-icon {
    z-index: 2;
    position: fixed;
    right: 4rem;
    bottom: 3rem;
    border-radius: 50px;
    width: 7rem !important;
    height: 7rem !important;
    background: $primary-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    border:2px solid #fff;
    > a {
        // background-color: rgba(35, 35, 39, 1) !important;
        // border-radius: 50px !important;
        // width: 100%;
        // height: 100%;
        display: flex !important;
        justify-content: center;
        padding: 0;
        background-color: transparent !important;
        width: 100%;

        &:before {
            background: url(https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB3ejn?pubver=1);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 2.7rem;
            width: 100% !important;
            height: 100%;
            font-size: 0 !important;
        }
        &:focus {
            border: 0 !important;
            outline: none !important;
        }
        .msc-cart-icon__count {
            position: absolute;
            top: -7px;
            right: 0px;
            background-color: rgba(242, 101, 93, 1) !important;
            font-size: 12px !important;
            min-height: 2.5rem;
            min-width: 2.5rem;
            margin-bottom: 0 !important;
        }
    }
}
