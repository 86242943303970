.pavilions-images-list {
    @include custom-container;
    .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1.5rem 2rem;
        justify-content: center;
        .col-12 {
            box-shadow: 0px 4px 10px rgba(161, 159, 181, 0.25);
            width: calc(100% - 40px);
            padding:0;
            // @include breakpoint(sm) {
            //     width: 100%
                
            // }
            @include breakpoint(md) {
                width: 47%;
                
            }
            @include breakpoint(md1) {
                width: 31%;
            }
            .ms-content-block {
                overflow: hidden;
                &:hover img {
                    transform: scale(1.3);
                }
                .ms-content-block__image {
                    width: 100%;
                    height: 45rem;
                    img {
                        @include img-fit;
                        border-radius: 4px;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
    }
}
