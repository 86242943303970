.attractions-tab {
    @include custom-container;
    padding: 3rem 2.4rem;
    border-radius: 8px;
    background: #fff;
    /* drop shadow */
    box-shadow: 0px 4px 12px 0px rgba(163, 163, 163, 0.25);
    @include breakpoint(md) {
        padding: 5rem;
    }
    .szf-buybox-attraction-card {
        border-bottom: 1px solid #d9d9d9;
        padding: 3rem 0;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        @include breakpoint(md) {
            gap: 4rem;
        }
        @include breakpoint(md1) {
            flex-direction: row;
        }
        &:last-child {
            border-bottom: 0;
        }
        .szf-buybox-attraction-image {
            width: 100%;
            height: 25rem;
            border-radius: 8px;
            // background: url(https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBnqm?pubver=1);
            // background-repeat: no-repeat;
            // background-position: center;
            // background-size: cover;
            @include breakpoint(xs) {
                height: 30rem;
            }
            @include breakpoint(sm) {
                height: 32rem;
            }
            @include breakpoint(md) {
                height: 40rem;
            }
            @include breakpoint(md1) {
                max-width: 308px;
                min-width: 308px;
                height: 179px;
            }
            img {
                @include img-fit;
                border-radius: 8px;
            }
        }
        .szf-buybox-attraction-detail {
            h3 {
                color: #1e1e1e;
                font-family: $font-family-primary;
                font-size: 3.2rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 1.28px;
                margin-bottom: 12px;
            }
            p {
                color: $primary-blue;
                font-family: $font-family-primary;
                font-size: 1.8rem;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
                letter-spacing: -0.3px;
                margin-bottom: 12px;
            }
            .szf-buybox-attraction-book-now {
                @include cta_styling($primary-yellow);
                border: none;
            }
        }
    }
}
