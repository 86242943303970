//==============================================================================
// FONT FAMILY
//==============================================================================

$font-family-primary: 'Roboto', sans-serif;
// $arabic-font-family: 'bukra', 'Roboto', sans-serif;
$arabic-font-family: 'Cairo', sans-serif;
$font-family-primary-for-ticket: 'Roboto';

// $font-family-system: -apple-system,
// BlinkMacSystemFont,
// "Segoe UI",
// Roboto,
// Oxygen,
// Ubuntu,
// Cantarell,
// "Open Sans",
// "Helvetica Neue",
// sans-serif;
// $font-family-times: Times,
// "Times New Roman",
// serif;

//==============================================================================
// FONT SIZES -- 1rem = 14px
//==============================================================================

$font-size-xs: 0.72rem; //10px
$font-size-s: 0.86rem; //12px
$font-size-m: 1rem; //14px
$font-size-l: 1.143rem; //16px
$font-size-xl: 1.29rem; //18px
$font-size-xxl: 1.72rem; //24px
$font-size-xxxl: 2rem; //28px
$font-size-xxxxl: 2.571rem; //36px
$font-size-xxxxxl: 3rem; //48px

//==============================================================================
//
//==============================================================================
$header-height: 90px;
$secondary-banner-size: 200px + $header-height;
//==============================================================================
// Colors
//==============================================================================

$primary_yellow: #ffd367;
$primary_blue: #13294b;
$secondary_blue: #08215c;

$dark-yellow: #f8b319;
// $primary_blue: #283C63; // brand light blue
// $secondary_blue: #0D1020; // brand dark blue

$brand_dark_blue: #0D1020; // brand dark blue
$brand_beige:#E8D6C0;


$primary_maroon: #945042;
$home_maroon: #5c000d;
$text-color: #373f47;
$white: #fff;
$blue-hover: #060f1c;
$banner-overlay: linear-gradient(90deg, #010b23 14.74%, rgba(0, 0, 0, 0) 101%);
$arabic-banner-overlay: linear-gradient(270deg, #010b23 14.74%, rgba(0, 0, 0, 0) 101%);
// $banner-overlay:linear-gradient(90deg, #010B23 44.74%, rgba(0, 0, 0, 0) 101%);
