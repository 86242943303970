.ms-cart {
    @include custom-container;
    .msc-cart-lines-group {
        margin-right: 0;
        @media screen and (min-width: 1070px) {
            margin-right: 21px;
        }
        .msc-cart-lines-group-wraper {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .msc-cart-lines-group-wraper__bopis-heading {
                display: flex;
                align-items: center;
                padding: 1.9rem 2rem;
                gap: 2rem;
                .msc-cart-lines-group-wraper__bopis-heading-shipping-icon {
                }
                .msc-cart-lines-group-wraper__bopis-heading-shipping {
                }
                > p:nth-child(1) {
                    position: relative;
                    margin-bottom: 0;
                    padding-top: 0;
                    &:before {
                        content: '' !important;
                        background: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB41JZ');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        width: 3.8rem !important;
                        height: 3rem;
                        font-size: 0 !important;
                        display: flex;
                    }
                }
                > p:nth-child(2) {
                    font-family: $font-family-primary;
                    font-size: 2.6rem;
                    font-weight: bold;
                    line-height: 140%;
                    letter-spacing: 0;
                    text-align: left;
                    margin: 0px;
                }
            }
            .msc-cart-lines-item {
                border-bottom: 1px solid $brand_beige;
                padding: 0 2rem 2rem;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 2rem;

                &:last-child {
                    border-bottom: 0;
                }
                .msc-cart-line {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    width: 100%;
                    margin: 0;
                    @include breakpoint(sm) {
                        flex-direction: row;
                    }
                    .msc-cart-line__product-image {
                        max-width: 100%;
                        height: 25rem;
                        border-radius: 6px;
                        @include breakpoint(sm) {
                            max-height: 35rem;
                            width: 40rem;
                        }
                        @include breakpoint(md) {
                            max-height: 18rem;
                        }

                        img {
                            @include img-fit;
                            border-radius: 6px;
                        }
                    }
                    .msc-cart-line__content {
                        display: flex;
                        gap: 2rem;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: column;
                        width: 100%;
                        margin-top: 0;
                        @include breakpoint(md1) {
                            flex-direction: row;
                            justify-content: space-between;
                        }
                        .msc-cart-line__product {
                            width: 100%;
                            margin-left: 0;
                            min-height: auto;

                            @include breakpoint(md) {
                                // margin-left: 200px !important;
                                // width: calc(100% - 200px) !important;
                                // position: relative;
                                // margin-top: -120px;
                                // min-height: 100px;
                            }
                            @include breakpoint(md1) {
                                width: 55%;
                                // width: 50%;
                                // margin-top: 0;
                                // min-height: auto;
                            }
                            .msc-cart-line__product-title {
                                font-family: $font-family-primary;
                                font-size: 1.8rem;
                                font-weight: 500;
                                line-height: 140%;
                                letter-spacing: 0.04em;
                                text-align: left;
                                color: $primary-blue;
                                text-decoration: none;
                            }
                            .msc-cart-line__product-variants {
                                .msc-cart-line__product-variant-item {
                                    font-family: $font-family-primary;
                                    font-size: 1.6rem;
                                    font-weight: 400;
                                    line-height: 140%;
                                    letter-spacing: 0.04em;
                                    text-align: left;
                                    // margin-top: 6px;
                                    .msc-cart-line__product-variant-size {
                                        span {
                                            margin-left: 4px;
                                        }
                                    }
                                }
                            }
                            .msc-cart-line-item-product-discount {
                                font-family: $font-family-primary;
                                font-size: 1.4rem;
                                font-weight: 400;
                                line-height: 140%;
                                letter-spacing: 0.04em;
                                text-align: left;
                                color: $primary_maroon;
                            }
                        }
                        // select {
                        //     font-family: $font-family-primary;
                        //     word-wrap: normal;
                        //     border: 1px solid $primary-blue;
                        //     width: 5rem;
                        //     height: 3rem;
                        //     font-size: 1.8rem;
                        //     border-radius: 4px;
                        // }
                        .msc-cart-line__quantity {
                            font-family: $font-family-primary;
                            font-size: 1.6rem;
                            font-weight: 500;
                            line-height: 140%;
                            letter-spacing: 0.04em;
                            margin: 0 4px;
                            text-align: left;
                            @include breakpoint(md1) {
                                width: 20%;
                            }
                            .quantity-value {
                                margin-left: 4px;
                            }
                        }
                        .msc-cart-line__product-price {
                            font-family: $font-family-primary;
                            font-size: 1.4rem;
                            font-weight: 600;
                            line-height: 140%;
                            letter-spacing: 0.04em;
                            text-align: right;

                            // margin-top: 6px;
                            @include breakpoint(md1) {
                                width: 20%;
                            }

                            .discount-value {
                                .msc-price__strikethrough {
                                    color: #595959;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
                .msc-cart-line__remove-item.msc-btn {
                    @include cta_styling($primary-blue);
                    border: 0;
                    font-size: 1.6rem;
                    height: 4rem;
                    @include breakpoint(sm) {
                        width: 10rem;
                    }
                }
            }
        }
    }
    .msc-order-summary-wrapper {
        @include breakpoint(md1, max-width) {
            p.msc-order-summary__line-total {
                margin-right: 10px !important;
                padding: 15px 25px 15px !important;
            }
        }
    }
}
