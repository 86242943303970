.cdp-container {
    > .row {
        > .col-12 {
            padding: 0;
        }
    }
}
.szf-category-details__banner-container {
    background: $banner-overlay;
    display: flex;
    align-items: center;
    padding: 0 6rem;
    position: relative;
    min-height: 300px;
    padding-top: $header-height;
    margin-top: -$header-height;
    .szf-category-details__banner {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        left: 0;
        top: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .szf-category-details__banner-title {
        h2 {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 2.5rem;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-transform: uppercase;
            color: #ffffff;
            @include breakpoint(sm) {
                font-size: 4rem;
            }
        }
    }
}
