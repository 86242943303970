.msc-cartline-wraper {
    @include custom-container;
    
}
.msc-cart__empty-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 4rem 4rem;
    border: 0 !important;
    height: 20rem;
    margin:0 !important;
    .msc-cart-line {
        font-family: $font-family-primary;
        font-size: 3rem;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.04em;
        text-align: left;
        padding: 20px 0px 0;
    }
    .msc-cart__btn-backtoshopping {
        @include cta_styling($primary-yellow);
    }
}
