.partners-list {
    width: 95%;
    margin: 0 auto;
    padding: 4rem 0px 7rem;
    > .row {
        display: flex;
        justify-content: center;
        > .col-12 {
            max-width: 100%;

            @include breakpoint(sm) {
                max-width: 50%;
            }
            @include breakpoint(md1) {
                max-width: 33%;
            }
            @include breakpoint(xl) {
                max-width: 25%;
            }
            .ms-content-block {
                background: #ffffff;
                box-shadow: 0px 3px 12px rgba(166, 166, 166, 0.25);
                border-radius: 8px;
                display: flex;
                justify-content: center;
                border-bottom: 0;
                height: 170px;
                margin: 60px 20px 30px;
                margin:4rem 2rem 3rem;
                padding: 20px 10px;
                img {
                    @include img-fit;
                }
            }
        }
    }
}
