.details-div {
    @include custom-container;
    .ms-content-block {
        .ms-content-block__title {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 3rem;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-transform: uppercase;
            color: $primary-blue;
            margin-bottom: 1rem;
            @include breakpoint(sm) {
                margin-bottom: 3rem;
                font-size: 4rem;
            }
        }
        p {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 400;
            font-size: 2rem;
            line-height: 140%;
            /* or 28px */
            text-align: justify;
            letter-spacing: -0.3px;
            color: #122553;
        }
        ul {
            margin-bottom: 2rem;
            li {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 400;
                font-size: 2rem;
                line-height: 140%;
                /* or 28px */
                text-align: justify;
                letter-spacing: -0.3px;
                color: #122553;
            }
        }
        .ms-content-block__cta {
            display: flex;
            gap: 3rem;
            margin-top: 5rem;
            flex-direction: column;
            display: none;
            @include breakpoint(sm) {
                flex-direction: row;
            }
            .msc-cta__primary {
                @include cta_styling($primary-yellow);
                border-radius: 24px;
            }
            .msc-cta__secondary {
                @include cta_styling($primary-blue);
                border-radius: 24px;
            }
        }
    }
}
