.ms-cart-icon__flyout-container.show {
    .msc-flyout__close-button {
        width: 3rem;
        height: 3rem;
        font-size: 0;
        background: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB46Wt');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;
        &:before {
            content: '';
        }
    }
    .msc-flyout-inner {
        .ms-cart-icon__flyout-title {
            color: #1e1e1e;
            font-family: $font-family-primary;
            font-size: 2rem;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            letter-spacing: 0.64px;
            text-transform: capitalize;
        }
        .ms-cart-icon__cart-lines {
            height: calc(90vh - 24rem) !important;
            max-height: 100%;
            margin-top: 2rem;
            &:empty {
                height: calc(90vh - 18rem) !important;
            }

            /* width */
            &::-webkit-scrollbar {
                width: 6px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 8px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $primary-blue;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: $primary-blue;
            }
            .msc-cart-line__content {
                width: 75%;
            }
            .msc-cart-line.msc-cart-line__compact {
                display: flex;
                gap: 1rem;
                .msc-cart-line__product-image {
                    width: 120px;
                    height: 120px;
                    img {
                        @include img-fit;
                    }
                }
            }
            .msc-cart-line__product-title {
                color: #1e1e1e;
                font-family: $font-family-primary;
                font-size: 1.8rem;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                letter-spacing: 0.64px;
            }
            .msc-cart-line__product-variants {
                color: #1e1e1e;
                font-family: $font-family-primary;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0.64px;
                margin-top: 0;
            }
            .msc-cartline__product-unit-of-measure {
                color: #1e1e1e;
                font-family: $font-family-primary;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0.64px;
            }
            .msc-cart-line__quantity {
                color: #1e1e1e;
                font-family: $font-family-primary;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0.64px;
                margin:0;
                label {
                    margin-right: 4px;
                }
            }
            .msc-price.discount-value {
                display: flex;
                flex-wrap: wrap;
                .msc-price__strikethrough {
                    margin-right: 5px !important;
                }
                .msc-price__actual {
                    font-weight: 600;
                }
            }

            .msc-cart-line__product-price {
                color: #1e1e1e;
                font-family: $font-family-primary;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0.64px;
            }
            .msc-cart-line__remove-item {
                background-color: transparent !important;
                color: #1e1e1e;
                font-family: $font-family-primary;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0.64px;
                text-decoration: underline;
                padding: 0;
                margin-left: 120px;
                margin-bottom: 20px;
            }
        }
        .ms-cart-icon__subtotal {
            color: #1e1e1e;
            font-family: $font-family-primary;
            font-size: 2rem;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            letter-spacing: 0.64px;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
        .ms-cart-icon__btn-checkout.msc-btn {
            @include cta_styling($primary-blue);
            width: 100% !important;
            margin-bottom: 10px;
        }
        .ms-cart-icon__btn-gotocart.msc-btn {
            @include cta_styling($primary-yellow);
            width: 100% !important;
            background: transparent;
            text-transform: capitalize;
        }
    }
}
