.sponsors-container {
    background: $white;
    background-image: url(https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBIdX?pubver=1);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-attachment: fixed;
    padding: 100px 0px;
    > .row {
        width: 100%;
        .col-12 {
            &:nth-child(2) {
                max-width: 85%;
                margin: 0 auto;
            }
        }

        .sponsors-heading {
            h2 {
                color: $primary-blue;
                &:before {
                    background-color: $primary-yellow;
                }
            }
        }
        .sponsors-images {
            @include breakpoint(xxxl) {
                max-width: 75%;
            }

            > .row {
                width: 100%;
                > .col-12 {
                    // max-width: 20%;
                }
            }
            .ms-content-block {
                border-bottom: 0 !important;
                margin-left: 40px !important;
            }
        }
        .ms-content-block {
            background: #ffffff;
            box-shadow: 0px 3px 12px rgba(166, 166, 166, 0.25);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            border-bottom: 0;
            height: 170px;

            margin: 60px 20px 30px;
            padding:1rem 2rem;

            img {
                @include img-fit;
                object-fit: contain;
            }
        }
    }
}
// Arrows
.festival-activities-content .slick-arrow.slick-next,
.sponsors-container .slick-arrow.slick-next,
.partners-container .slick-arrow.slick-next {
    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBXPH?pubver=1');
    background-repeat: no-repeat;
    background-position: center;
    width: 35px;
    height: 35px;
    top: 57%;
   right:-7%;
    left:auto;
    @include breakpoint(sm){
         right: -3%;
    }
}
.festival-activities-content .slick-arrow.slick-next {
    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBkOS?pubver=1');
    // transform: rotate(180deg);
}
.festival-activities-content .slick-arrow.slick-next:before,
.festival-activities-content .slick-arrow.slick-prev:before,
.sponsors-container .slick-arrow.slick-next:before,
.sponsors-container .slick-arrow.slick-prev:before,
.partners-container .slick-arrow.slick-next:before,
.partners-container .slick-arrow.slick-prev:before {
    content: '';
}
.festival-activities-content .slick-arrow.slick-prev,
.sponsors-container .slick-arrow.slick-prev,
.partners-container .slick-arrow.slick-prev {
    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB10qZ?pubver=1');
    background-repeat: no-repeat;
    background-position: center;
    width: 35px;
    height: 35px;
    top: 57%;
    left: -7%;
    z-index: 2;
    @include breakpoint(sm){
        left: -3%;
   }
}
.festival-activities-content .slick-arrow.slick-prev {
    background-image: url("https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBkOY?pubver=0");
    
}
