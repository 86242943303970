.media-booklet-div {
    @include custom-container;
    background: #ffffff;
    border: 1px solid #eaecee;
    border-radius: 8px;
    margin-bottom: 8rem;
    
    .ms-content-block__cta{
        a{
            background: $primary-blue;
            background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2gu9?pubver=3');
            margin: 3rem;
            position: relative;
            color: #fff;
            font-size: 1.8rem;
            padding: 10px 20px 10px 6rem;
            background-repeat: no-repeat;
            background-position: 19px 9px;
            background-size: 2rem;
            border-radius: 24px;
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-decoration: none;
            width: 22rem;
            transition: all 0.3s ease-in-out;
            &:hover {
                box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
            }
            @include breakpoint(sm) {
                width: 21rem;
            }
        }
    }
   
}
