.reach-festival {
    > .row {
        > .col-12 {
            &:first-child {
                background: #f8f9fa;
                padding: 1.8rem 4.8rem;
            }
        }
    }
    h5 {
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 600;
        font-size: 4rem;
        line-height: 140%;
        letter-spacing: -0.3px;
        text-transform: uppercase;
        color: #111f48;

        margin-bottom: 0;
    }
}
