.szf-category-details__image-container {
    > .row {
        gap: 2rem;
        @include breakpoint(md1) {
            gap: 0;
        }
        > .col-12 {
            overflow: hidden;
            // padding: 10px;
            // padding-bottom: 4px;
        }

        // > .col-auto {
        // }
        .row {
            gap: 2rem;
            @include breakpoint(sm) {
                gap: 0;
            }
            @include breakpoint(md1) {
                gap: 2rem;
            }
            .col-12 {
                overflow: hidden;
                &:first-child {
                    @include breakpoint(sm) {
                        padding-right: 1rem;
                    }

                    @include breakpoint(md1) {
                        padding-left: 0px;
                        padding-right: 15px;
                    }
                }
                &:last-child {
                    @include breakpoint(sm) {
                        padding-left: 1rem;
                    }
                    @include breakpoint(md1) {
                        // padding-left: 15px;
                        padding-left: 0px;
                    }
                }
            }
        }
    }
    .image1Container {
        height: auto;
        max-height: 30rem;
        overflow: hidden;
        @include breakpoint(sm) {
            height: 40rem;
            max-height: fit-content;
        }
        @include breakpoint(md) {
            height: 52rem;
        }
    }
    .image2Container,
    .image3Container {
        height: auto;
        max-height: 30rem;
        overflow: hidden;
        @include breakpoint(sm) {
            height: 25rem;
        }
    }
    .image-main-container {
        overflow: hidden;
        .image1Container,
        .image2Container,
        .image3Container {
            border-radius: 4px;
            transition: all 0.3s ease-in-out;

            &:hover {
                transform: scale(1.5);
            }
            img {
                border-radius: 4px;
                @include img-fit;
                object-fit: contain;

                @include breakpoint(sm) {
                    object-fit: cover;
                }
            }
        }
    }
}

// .image3Container {
//     border-top: 1rem solid #fff;
// }

// .image2Container {
//     border-bottom: 1rem solid #fff;
//     @include breakpoint(md){
//         border-bottom: 0;
//     }
// }
