@include breakpoint(md1) {
    header {
        background: transparent !important;
        .ms-header {
            padding: 0;
            &__container {
                background: linear-gradient(180deg, #08215c 0%, rgba(0, 32, 108, 0) 100%);
                @include breakpoint(md1) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 90px;
                    padding: 0 20px;
                }
                > .ms-nav {
                    z-index: 1;
                    background: transparent;
                    border-top: 0 !important;
                    display: none;

                    @include breakpoint(md1) {
                        display: flex !important;
                    }

                    @include breakpoint(xxl) {
                        width: 91%;
                    }
                    @include breakpoint(xxxl) {
                        width: 86%;
                    }
                    @media screen and (min-width: 1130px) and (max-width: 1610px) {
                        width: 89%;
                    }

                    > .ms-nav__list {
                        background: transparent;
                        overflow-x: visible !important;
                        height: 100vh;
                        display: flex;
                        flex-direction: column;
                        @include breakpoint(md1) {
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                            @include list-reset;
                            height: auto;
                        }

                        > .ms-nav__list__item {
                            background: transparent;
                            cursor: pointer;
                            margin-top: 0 !important;
                            margin-right: 0 !important;
                            padding-top: 0 !important;
                            @include breakpoint(md1) {
                                margin-left: 11px;
                            }

                            @include breakpoint(md1) {
                                margin-left: 2.2rem;
                            }
                            // @include breakpoint(lg) {
                            //     margin-left: 18px;
                            // }
                            @include breakpoint(xl) {
                                margin-left: 2.2rem;
                            }
                            @include breakpoint(xxl) {
                                margin-left: 1.7rem;
                            }
                            &:nth-child(2) {
                                order: -1;
                                margin-left: 0;

                                @include breakpoint(md1) {
                                    margin-left: 2.2rem;
                                }
                            }
                            &:first-child {
                                li {
                                    &:first-child {
                                        order: -1 !important;
                                    }
                                    &:nth-child(2) {
                                        order: 4 !important;
                                    }
                                    &:nth-child(3) {
                                        order: 3 !important;
                                    }
                                    &:nth-child(4) {
                                        order: 5 !important;
                                    }
                                    &:nth-child(5) {
                                        order: 9 !important;
                                    }
                                    &:nth-child(6) {
                                        order: 7 !important;
                                    }
                                    &:nth-child(7) {
                                        order: 8 !important;
                                    }
                                    &:nth-child(8) {
                                        order: 6 !important;
                                    }
                                }
                            }
                            > .ms-nav__list__item__button,
                            > .ms-nav__list__item__link,
                            > .ms-nav__list__item__span {
                                text-decoration: none;
                                background-color: transparent;
                                border: none;
                                @include font-content();
                                color: $white;
                                font-size: 1.4rem;
                                @include no-outline;
                                margin-bottom: 0 !important;
                                margin-top: 0;
                                font-weight: 600;
                                @media screen and (min-width: 1130px) and (max-width: 1610px) {
                                    font-size: 1.3rem;
                                }
                            }
                            > .ms-nav__list__item__button.active,
                            > .ms-nav__list__item__link.active,
                            > .ms-nav__list__item__span.active {
                                position: relative;

                                &:after {
                                    position: absolute;
                                    content: '' !important;
                                    background: #ffd367;
                                    border-radius: 4px;
                                    width: 100%;
                                    height: 4px;
                                    bottom: -8px;
                                    left: 0;
                                    transform: rotate(0deg);
                                    margin: 0 !important;
                                    display: inline-block;
                                    @include breakpoint(xl) {
                                        bottom: -20px;
                                    }
                                }
                            }
                            &:last-child {
                                > .ms-nav__list__item__link.active {
                                    &::after {
                                        background: transparent;
                                    }
                                }
                            }
                            &:last-child,
                            &:nth-last-child(2),
                            &:nth-last-child(3),
                            &:nth-last-child(4) {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 10px 12px !important;
                                background: $brand_dark_blue;
                                color: $white;
                                border-radius: 24px;
                                margin-bottom: 10px;
                                width: 170px;
                                margin-left: 10px;
                                font-weight: bold;

                                @include breakpoint(md1) {
                                    margin-bottom: 0;
                                    width: auto;
                                    // margin-left: 0;
                                }
                                @media screen and (min-width: 991px) and (max-width: 1133px) {
                                    margin-top: 10px !important;
                                }
                                span {
                                    padding: 0;
                                    margin-top: 0;
                                    display: flex;
                                    justify-content: center;
                                    font-weight: 500;
                                    @include breakpoint(md1) {
                                    }
                                }
                            }
                            &:nth-last-child(3) {
                                background: $primary_yellow;

                                //    margin-left: 30px;
                                // margin-left: 1%;
                                // @include breakpoint(xl) {
                                //     margin-left: 6%;
                                // }
                                // @include breakpoint(xxl) {
                                //     margin-left: 1%;
                                // }
                                // @include breakpoint(xxxl) {
                                //     margin-left: auto;
                                // }

                                span,
                                a {
                                    color: $primary_blue;
                                    font-weight: 500;
                                    &:after {
                                        background: transparent !important;
                                    }
                                }
                            }
                            &:nth-last-child(4) {
                                background: #945042;

                                //    margin-left: 30px;
                                margin-left: 1%;
                                @include breakpoint(xl) {
                                    margin-left: 6%;
                                }
                                @include breakpoint(xxl) {
                                    margin-left: 1%;
                                }
                                @include breakpoint(xxxl) {
                                    margin-left: auto;
                                }

                                span,
                                a {
                                    color: #fff;
                                    font-weight: 500;
                                    &:after {
                                        background: transparent !important;
                                    }
                                }
                            }

                            &:last-child {
                                background: $brand_beige;
                                span,
                                a {
                                    color: $primary_blue;
                                }
                            }
                            &:nth-last-child(2) {
                                span,
                                a {
                                    &:after {
                                        background: transparent !important;
                                    }
                                }
                            }
                            // Inner List
                            .ms-nav__deafult {
                                position: relative;
                                top: 22px;
                                left: 0;
                                color: $white !important;
                                ul {
                                    @include list-reset();
                                    background: $primary_blue !important;
                                    border: 1px solid var(--msv-nav-dropdown-border);
                                    outline: none;
                                    z-index: 1;
                                    height: auto;

                                    min-width: 310px;
                                    overflow: hidden;
                                    padding-bottom: 10px;

                                    // position: absolute;

                                    @include breakpoint(md1) {
                                        display: flex !important;
                                        flex-direction: column !important;
                                        align-items: flex-start;
                                    }
                                }
                                li {
                                    padding: 10px 0 0 10px;
                                    margin: 0;
                                    background: $primary_blue;
                                    color: $white;

                                    width: auto;
                                    background-image: none;
                                    padding: 0;
                                    @include breakpoint(md1) {
                                        // background-image: url(https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBNqA?pubver=1);
                                        // background-repeat: no-repeat;
                                        // background-position: 5% 63%;
                                        width: 100% !important;
                                        padding: 10px 14px 0 20px !important;

                                        padding: 0 !important;
                                    }
                                    // &:nth-child(2) {
                                    //     order: 1;
                                    // }

                                    a {
                                        font-family: $font-family-primary !important;
                                        font-size: 14px !important;
                                        color: $white;
                                        margin-bottom: 0 !important;
                                        font-weight: 400 !important;
                                        &::before {
                                            height: 0 !important;
                                        }
                                        &:focus {
                                            border-color: transparent !important;
                                            outline: none !important;
                                        }

                                        width: 100%;
                                        display: flex;
                                        justify-content: flex-start;
                                        padding: 10px 14px 0 20px !important;
                                    }
                                    button,
                                    span {
                                        font-family: $font-family-primary !important;

                                        font-size: 14px !important;
                                        color: $white;
                                        margin-bottom: 0 !important;
                                        border: none;
                                        padding: 0;
                                        background: transparent;
                                        color: #fff;

                                        width: 100%;
                                        display: flex;
                                        justify-content: flex-start;
                                        padding: 10px 14px 0 50px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &__topbar {
                padding-left: 0 !important;
                padding-right: 0 !important;
                z-index: 2;
                @include breakpoint(xxl) {
                    width: 9%;
                }
                @include breakpoint(xxxl) {
                    width: 14%;
                }
                @media screen and (min-width: 1130px) and (max-width: 1610px) {
                    width: 11%;
                }
                .ms-header__logo {
                    height: 40px;
                    display: block !important;
                    // @include breakpoint(md1) {
                    //     margin-right: 10px !important;
                    // }
                    @include breakpoint(md1) {
                        margin-right: 10px !important;
                        height: 6.8rem;
                    }
                    @include breakpoint(xxxl) {
                        height: 6.3rem;
                    }

                    img {
                        @include img-fit;
                        object-fit: contain;
                        height: 100% !important;
                        min-width: 100% !important;
                        min-height: 100% !important;
                    }
                }
            }

            &__nav-icon {
                background-color: transparent !important;
                @include breakpoint(md1) {
                    display: none !important;
                }
            }
        }
    }
}
@media screen and (min-width: 991px) and (max-width: 1133px) {
    header {
        .ms-header__container {
            > .ms-nav {
                > .ms-nav__list {
                    flex-wrap: wrap;
                    > .ms-nav__list__item {
                        .ms-nav__list__item__button,
                        .ms-nav__list__item__link,
                        .ms-nav__list__item__span {
                            font-size: 13px;
                        }
                        &:nth-last-child(3) {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 991px) and (max-width: 1135px) {
    header {
        .ms-header__container {
            > .ms-nav {
                > .ms-nav__list {
                    flex-wrap: wrap;
                    > .ms-nav__list__item {
                        .ms-nav__list__item__button,
                        .ms-nav__list__item__link,
                        .ms-nav__list__item__span {
                            font-size: 13px;
                        }
                        &:nth-last-child(4) {
                            margin-left: 23rem;
                        }
                    }
                }
            }
        }
    }
}
//Hide sign in, wishlist, cart
.header_szf {
    .ms-header__topbar {
        .ms-header__account-info,
        .msc-wishlist-icon
        // .msc-cart-icon 
        {
            display: none !important;
        }
    }
}
// Make header sticky
.sticky {
    background: #13294b;
    transition: 0.5s all ease-in-out;
}

.sticky + #main {
    padding-top: 70px;
}
.header_szf {
    position: fixed;
    top: 0;
    z-index: 22;
    width: 100%;
    padding: 0 !important;
}
// -----------menu caret------------------
.ms-nav__list__item__button {
    position: relative;
    margin-left: 2px;
    @include breakpoint(xxl) {
        margin-right: 1.7rem;
    }
}
.ms-nav__list__item__button::before {
    content: '';
    position: absolute;
    background: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBsCG?pubver=0') !important;
    background-repeat: no-repeat !important;
    // top: 0.5rem;
    right: -1rem !important;
    width: 9px !important;
    height: 100%;
    left: auto !important;
    background-position: center !important;
    visibility: visible !important;

    @include breakpoint(md1) {
        right: -1.7rem !important;
    }
    @include breakpoint(lg) {
        right: -13px;
    }
}

.ms-header__container .ms-header__topbar .ms-site-picker {
    display: flex !important;
}
.ms-nav__list__item__link {
    display: block !important;
}
// responsive------------------

@include breakpoint(md1, max-width) {
    .ms-nav {
        display: none !important;
    }
    .header_szf {
        background-color: transparent !important;
        background: linear-gradient(180deg, #08215c 0%, rgba(0, 32, 108, 0) 100%);
    }
    .ms-header__container .ms-header__topbar .ms-header__nav-icon {
        background: transparent !important;
        justify-content: center;
        &:focus {
            border: none !important;
            outline: none !important;
        }
    }
    .ms-nav__list__mobile__container {
        padding: 0 !important;
        .ms-nav__list__mobile__container__button {
            background-color: $secondary_blue !important;
        }
    }
    .ms-header__container {
        .ms-header__topbar {
            justify-content: space-between;
            .ms-header__logo {
                display: block !important;
                margin-right: 12px !important;
                width: 90px;

                img {
                    width: 100% !important;
                    height: 100% !important;
                    object-fit: cover;
                    object-position: center;
                    min-width: 100% !important;
                    min-height: 100% !important;
                }
            }
            .ms-header__divider {
                display: none;
            }
        }
    }
}
.ms-header-open {
    background: $secondary_blue !important;
    overflow: auto;
    height: 100%;
    .ms-nav {
        background: transparent !important;
        display: flex !important;
        background: #08215c !important;

        > .ms-nav__list {
            padding: 0 20px;
        }
        .ms-nav__list {
            background: $secondary_blue;
            display: flex;
            flex-direction: column;
            height: 100vh;
            > .ms-nav__list__item {
                margin: 0;
                background: transparent !important;

                // width: 100% !important;
                // margin-left: 0 !important;
                // margin-bottom: 0 !important;
                // border-radius: 0 !important;
                // --------------------
                //   .ms-nav__list__item__link{
                //         background-color: transparent !important;
                //         padding:0;
                //         margin:0;

                //     }
                //     .ms-nav__list__item__button{
                //         margin-top: 0;
                //     }
                //     .ms-nav__list__item__span{

                //     }
                //     .ms-nav__deafult {
                //         margin-bottom:20px;
                //         ul{
                //             background: transparent !important;
                //             li {
                //                 background: transparent !important;
                //             a{
                //                 background-color: transparent !important;
                //                 padding:0;
                //                 margin:0;

                //             }
                //             button{
                //                 margin-top: 0;
                //             }
                //         }
                //         }

                //     }
                // -------------------------
                .ms-nav__list__item__button,
                .ms-nav__list__item__link,
                .ms-nav__list__item__span {
                    background-color: transparent;
                    background: transparent;
                    padding: 0;
                    margin: 0;
                    margin-bottom: 30px;
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 500 !important;
                    font-size: 14px;
                    line-height: 140%;
                    letter-spacing: -0.3px;
                    color: #ffffff;
                    text-decoration: none;
                }

                .ms-nav__list__item__button {
                    &:before {
                        background: none !important;
                    }
                }
            }
        }
    }
    .ms-nav.parent {
        .ms-nav__list {
            > .ms-nav__list__item {
                &:last-child,
                &:nth-last-child(2),
                &:nth-last-child(3),
                &:nth-last-child(4) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 14px !important;
                    background: $brand_dark_blue !important;
                    color: $white;
                    border-radius: 24px;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    width: 170px;
                    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.35);
                    text-align: center;
                    span {
                        padding: 0;
                        margin-top: 0;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 0;
                    }
                    a {
                        margin-bottom: 0;
                    }
                }
                &:nth-last-child(3) {
                    background: $primary_yellow !important;
                    margin-top: 10px;
                    margin-left: 0;
                    span,
                    a {
                        color: $primary_blue;
                    }
                }
                &:nth-last-child(4) {
                    background: #945042 !important;
                    margin-top: 50px;
                    span,
                    a {
                        color: #fff;
                    }
                }

                &:first-child {
                    padding-top: 0;
                }
                &:nth-child(2) {
                    order: -1;
                    padding-top: 16px;
                }

                .ms-nav__deafult {
                    margin-bottom: 20px;
                    ul {
                        background: #1e1e1e !important;
                        li {
                            a {
                                background-color: transparent;
                                padding: 0;
                                margin: 0;
                                margin-left: 10px;
                            }
                        }
                    }
                }
                &:last-child {
                    background: $brand_beige !important;
                    span,
                    a {
                        color: $primary_blue;
                    }
                }
            }
        }
    }

    .ms-header__container {
        > .ms-nav {
            > .ms-nav__list {
                > .ms-nav__list__item {
                    > .ms-nav__list__item__button.active,
                    > .ms-nav__list__item__link.active,
                    > .ms-nav__list__item__span.active {
                        position: relative;
                        &:before {
                            position: absolute;
                            content: '' !important;
                            background: $primary-yellow !important;
                            height: 40px;
                            width: 4px !important;
                            bottom: -8px;
                            left: -11px !important;
                            display: inline-block;
                            margin: 0;
                            top: -10px !important;
                            transform: rotate(0deg);
                            border-top-left-radius: 4px;
                            border-top-right-radius: 0;
                            border-bottom-left-radius: 4px;
                            border-bottom-right-radius: 0;
                        }
                    }
                    &:nth-last-child(4),
                    &:nth-last-child(3),
                    &:nth-last-child(2),
                    &:last-child {
                        > .ms-nav__list__item__link,
                        > .ms-nav__list__item__span {
                            &:before {
                                background: transparent !important;
                            }
                        }
                    }
                }
            }
        }
    }
    @keyframes slideDown {
        from {
            top: -100%;
        }
        to {
            top: 0;
        }
    }
    animation: slideDown 0.5s ease-in-out !important;
}

// Solid Header
.szf-solid-header {
    background: $primary_blue !important;
    .ms-nav > .ms-nav__list > .ms-nav__list__item:last-child,
    .ms-nav > .ms-nav__list > .ms-nav__list__item:nth-last-child(2) {
        // box-shadow: rgb(0 0 0 / 30%) 0px 19px 38px, rgb(0 0 0 / 22%) 0px 15px 12px;
        box-shadow: 0px 2px 12px rgba(166, 166, 166, 0.25);
    }
}

.ms-header__logo {
    a {
        width: 100%;
        height: 100%;
        &:focus {
            border: none;
            outline: none;
        }
    }
}

// Hide header modal
.ms-header__modal {
    display: none;
}

// Mobile Icons
.ms-header__container {
    .ms-header__topbar {
        .ms-header__nav-icon {
            &::before {
                content: url(https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2m2y?pubver=0) !important;
                height: 20px;
                width: 25px;
            }
        }
    }
}
.ms-nav__list__mobile__container__button {
    &:before {
        content: url(https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2j4Z?pubver=1) !important;
        width: 22px;
    }
}
.ms-nav {
    > .ms-nav__list {
        > .ms-nav__list__item {
            > .ms-nav__list__item__button {
                @include breakpoint(md1, max-width){
                    display:flex !important;
                    border:0;
                    width:100%;
                }
                &:after {
                    content: url(https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBsCG?pubver=1) !important;
                    transform: rotate(-90deg);
                }
            }
        }
    }
}
.ms-nav__list__item {
    .ms-nav__list {
        .ms-nav__list__item__button {
            @include breakpoint(md1, max-width){
                display: flex;
            }
            &:after {
                content: url(https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBsCG?pubver=1) !important;
                transform: rotate(-90deg);
            }
        }
    }
}

// Order----------------------------
header .ms-header__container > .ms-nav > .ms-nav__list > .ms-nav__list__item .ms-nav__deafult > li:nth-child(2) {
    order: 1;
}

.ms-header__container .ms-header__topbar .ms-header__logo a:focus-visible,
.ms-header__container .ms-header__topbar .ms-header__logo a:focus {
    border-color: transparent !important;
    outline: none !important;
}
// Trial---------------------------------- Remove All option

li.ms-nav__list__item:first-child .ms-nav__deafult ul li:first-child {
    background: red !important;
    display: none !important;
}
[data-exp-event-id*='menu.الكل'] {
    background: yellow !important;
    display: none !important;
}
[data-exp-event-id*='menu.All'] {
    background: green !important;
    display: none !important;
}
// -------------------------------------------------
#renderPage > header {
    position: relative;
    // top: 90px;
}
// -----------------------------------------------Fix mobile header

@media screen and (max-width: 991px) {
    #renderPage > header {
        // height: auto;
        // position: relative;
        // top: $header-height;
        // padding-top: 20px;
        // margin-top: - $header-height - 20px; 
        //  background: $secondary_blue;
        //  overflow: auto;

         height: auto;
         position: relative;
        //  top: 90px;
         /* padding-top: 20px; */
         /* margin-top: -110px; */ // header height + padding top
         background: transparent;
        //  overflow-y: auto;
    }
    .ms-header-open{
        height: 100vh !important;
    }
    .ms-header-open .ms-nav .ms-nav__list{
        height: 100% !important;
    }
    .ms-header-open nav{
        height: 100% !important;
        overflow: auto;
        padding-bottom:90px;
    }
}
