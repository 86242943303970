// ================ Hide Payment section save & continue button ================ //
.ms-checkout__guided-card:nth-child(3) {
    .ms-checkout-payment-instrument {
        flex-direction: column;
        > button {
            @include cta_styling($primary-yellow);
            width: fit-content !important;
            border: 0;
            &:hover {
                background: $primary-yellow;
                color: $primary-blue;
            }
            &:focus {
                &::before {
                    border: 0;
                }
            }
        }
        // ================ payment cards================ //

        .payment-card-images {
            display: flex;
            row-gap: 4rem;
            margin: 0 0 4rem 0;
            // max-width: 220px;
            flex-wrap: wrap;

            .single-payment-card {
                width: 50%;
                height: 5rem;
                border-radius: 4px;
                // border: 1px solid #f3f4f6;
                // padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include breakpoint(sm) {
                    width: 33%;
                }
                @include breakpoint(md) {
                    width: 25%;
                }
                @include breakpoint(md1) {
                    width: 20%;
                }

                // input {
                //     position: absolute;
                //     left: -9999px;
                // }

                // input[type='radio'] {
                //     &:checked + label {
                //         border: 1px solid #fff;
                //         box-shadow: 0 0 3px 3px #0d2453;
                //         transition: 300ms all;
                //         border-radius: 4px;
                //     }
                // }
                // label {
                //     width: 100%;
                //     height: 100%;
                //     padding: 10px;
                //     margin: 0;
                //     cursor: pointer;
                // }

                button {
                    background: transparent;
                    border: 0;
                    padding: 0;
                    height: 100%;
                    width: 60%;
                    border-radius: 4px;
                    transition: all 0.1s ease-in-out;
                    &:focus,
                    &:active,
                    &:target,
                    &:focus-visible,
                    &:visited {
                        padding: 4px;
                        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
                        outline: none;
                        border: 2px solid $brand_dark_blue;
                    }
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }
            .single-payment-card.discover-card {
                // label {
                //     padding: 12px 6px 0px 12px;
                // }
            }
        }
        .payment-selection-error {
            font-family: $font-family-primary;
            margin-left: 0;
            font-size: 1.6rem;
            margin-bottom: 2rem;
            color: #a80000;
        }
        .ms-checkout-payment-instrument__card-number {
            margin-left: 0;
            margin-right: 0;
        }
        .ms-checkout-payment-instrument__show {
            p {
                font-family: $font-family-primary;
                margin-bottom: 5px;
                color: #1e1e1e;

                font-size: 1.8rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                width: 100%;
            }
        }
    }
    .ms-checkout__guided-card-header {
        > button {
            display: none !important;
        }
    }
    > .ms-checkout__guided-card-body {
        .ms-checkout-section-container__item {
            .ms-text-block {
                // background: $primary-yellow;
                padding: 8px 10px;
                border-radius: 4px;
                h5 {
                    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB3rjR?pubver=0');
                    background-repeat: no-repeat;
                    background-position: 0 center;
                    padding-left: 2.5rem;
                    padding-right: 1rem;
                    background-size: 1.7rem !important;
                    font-size: 1.4rem !important;
                    text-align: left;
                    color: $primary_blue !important;

                    @include breakpoint(md1) {
                        background-size: contain;
                    }
                }
            }
        }
        > .ms-checkout__guided-card-footer {
            display: none;
        }
    }
    .ms-checkout-payment-instrument__error-title {
        display: none;
    }

    .ms-checkout-payment-instrument__error-message {
        font-family: $font-family-primary !important;
        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/pmtjhhhpmz/imageFileData/MB2HF?pubver=1');
        background-repeat: no-repeat;
        background-position: 0rem center;
        padding: 0 1rem 0 3rem;
        background-size: 2rem;
        font-size: 1.6rem !important;
        color: $primary-blue !important;
        text-align: left;
        /* width: 100% !important; */
        margin: 0;
    }
    .payment-method-retrieval-error {
        background: #ff57222e;
        margin-bottom: 2rem;
        border-radius: 4px;
        padding: 1rem;
        p {
            font-family: $font-family-primary !important;
            background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/pmtjhhhpmz/imageFileData/MB2HF?pubver=1');
            background-repeat: no-repeat;
            background-position: 0rem center;
            padding: 0 1rem 0 2.5rem;
            background-size: 1.7rem;
            font-size: 1.4rem !important;
            color: $primary-blue !important;
            text-align: left;
            /* width: 100% !important; */
            margin-bottom: 0;
            line-height: normal;
        }
    }
}
