.eqrab-list {
    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/pmtjhhhpmz/imageFileData/MB7S3?pubver=1');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 100px 8%;
    > .row {
        > .col-12 {
            max-width: 100%;
            @include breakpoint(sm) {
                max-width: 50%;
            }
            @include breakpoint(md) {
                max-width: 33%;
            }
            @include breakpoint(xl) {
                max-width: 25%;
            }

            .ms-content-block {
                background: #ffffff;
                box-shadow: 0px 3px 12px rgba(166, 166, 166, 0.25);
                border-radius: 8px;
                display: flex;
                justify-content: center;
                border-bottom: 0;
                height: 170px;
                margin: 30px 20px 30px;
                padding: 1rem 2rem;
                img {
                    @include img-fit;
                    object-fit: contain;
                }
            }
        }
    }
}
