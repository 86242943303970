// // Import bootstrap css

// @import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/mixins";
// @import "bootstrap/scss/grid";
// @import "../00-settings/responsive-breakpoints";

// .row {
//     margin: 0;
// }

// div[class^="col"] {
//     padding: 0;
//     margin: 0;
// }

// // Container width
// body .container {
//     padding-left: 20px;
//     padding-right: 20px;

//     @media screen and (min-width: $msv-breakpoint-s) {
//         max-width: $msv-breakpoint-xl;
//         width: 100%;
//     }
// }
