.merchandising-single-product {
    @include custom-container;
    position: relative;
    padding: 3rem 2.4rem;
    border-radius: 8px;
    background: #fff;
    /* drop shadow */
    box-shadow: 0px 4px 12px 0px rgba(163, 163, 163, 0.25);
    gap: 2rem;
    margin-bottom: 4rem;
    flex-direction: column;

    @include breakpoint(md) {
        padding: 5rem;
        margin-bottom: 6rem;
    }
    @include breakpoint(md1) {
        margin-bottom: 6rem;
        gap: 4rem;
        flex-direction: row;
    }
    .ms-buybox__media-gallery-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 7rem;
        @include breakpoint(md) {
            margin-top: 7rem;
        }
        @include breakpoint(md1) {
            width: 65%;
            flex-direction: row;
        }
        // @include breakpoint(md) {
        //     margin-top: 7rem;
        // }
        .ms-buybox__media-gallery {
            max-width: 100%;
            z-index: 1;
            position: relative;
            margin: 0 auto;

            @media screen and (max-width: 767px) {
                width: 100%;
            }
            @include breakpoint(md1) {
                max-width: 45%;
                margin: 0;
            }
            // ul.msc-ss-carousel-vert-slide {
            //     li {
            //         height: 42rem !important;
            //         img {
            //             object-fit: contain;
            //         }
            //     }
            // }

            .ms-media-gallery {
                @include breakpoint(sm, max-width) {
                    padding-top: 210px;
                }
                @include breakpoint(md, max-width) {
                    width: 100%;
                    padding-top: 0;
                }
                .msc-carousel__item {
                    @include breakpoint(md1) {
                        width: 55rem !important;
                        height: 36.7rem !important;
                    }

                    img {
                        @include img-fit;
                    }
                    .ms-fullscreen-section {
                        width: 100%;
                        height: 100%;
                        img {
                            @include img-fit;
                        }
                    }
                }
                .msc-carousel__indicators {
                    @include breakpoint(md1, max-width) {
                        bottom: -28px;
                    }
                    li{
                        width: 1.4rem;
                        height: 1.4rem;
                        border: 1px solid $primary-blue;
                        margin: 0 0.5rem;

                    }
                    li.active{
                        background-color: $primary-blue;
                    }
                }
            }
            .ms-media-gallery.vertical {
                .ms-media-gallery__thumbnail-item {
                    width: 10rem;
                    height: 6.7rem;
                    .msc_image {
                        @include img-fit;
                    }
                }
            }
        }
        > ul {
            width: 100%;
            padding: 0 10px 0px 10px;

            border-radius: 4px;
            z-index: 6 !important;
            list-style: circle;
            /* width */
            &::-webkit-scrollbar {
                width: 6px;
                border: 0;
                border-radius: 0;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                // box-shadow: inset 0 0 5px grey;
                border-radius: 0;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $primary-blue;
                border-radius: 0;
                border: 0;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: $primary-blue;
            }
            @include breakpoint(md) {
                box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
                padding: 25px 40px;
            }
            @include breakpoint(md1) {
                width: 55%;
                position: absolute;
                right: -20px;
                top: 3rem;
                max-height: 25rem;
                min-height: calc(36.7rem - 6rem) !important;
                background: #fff;
                overflow: auto;
            }
            @include breakpoint(xl) {
                right: 0;
            }
            li {
                font-size: 1.4rem;
                font-family: $font-family-primary;
            }
        }
    }
    .ms-buybox__content {
        width: 100%;
        margin-top: 5rem;
        @include breakpoint(md) {
            margin-top: 7rem;
        }
        @include breakpoint(md1) {
            width: 35%;
        }
        .ms-buybox__quantity {
            .ms-buybox__product-quantity-label-heading {
                display: block;
                font-size: 1.2rem;
                font-family: $font-family-primary;
                color: #000;
            }
            .quantity {
                display: flex;
                justify-content: space-between;
                align-items: center;
                button {
                    border: 0;
                    background: #13294b;
                    border-radius: 50px;
                    color: #fff;
                    width: 3.5rem;
                    height: 3rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .quantity-input {
                    border: 0;
                    text-align: center;
                    font-size: 2rem;
                    font-weight: 400;
                    font-family: $font-family-primary;
                    color: #13294b;
                    height: 100%;
                }
            }
        }
    }

    .ms-buybox__configure {
        .ms-buybox__dropdown {
            label {
                font-family: $font-family-primary;
                font-size: 2.2rem;
            }
            .msc-swatch-container {
                &__item {
                    border-radius: 4px;
                    font-size: 1.6rem;
                    text-transform: capitalize !important;
                    font-family: $font-family-primary;
                    padding: 2px 20px;
                    margin: 2px 4px 2px 0;
                    transition: all 0.3s ease-in-out;
                    &__selected {
                        background: $primary-blue;
                        color: #fff;
                        border: 1px solid $primary-blue;
                    }
                }
            }
        }
    }
    //============================================== PDP Slider New styling=======================================//

    // --------------image of product and content-------------------------//
    .ms-buybox__media-gallery-div {
        // width: 100%;
        // @include breakpoint(md1) {
        //     width: 40%;
        // }
        .ms-buybox__media-gallery {
            padding-right: 0;
            .ms-media-gallery {
                margin-bottom: 1.5rem;
                .ms-media-gallery__carousel.msc-carousel {
                    @include breakpoint(md, max-width) {
                        position: relative;
                    }
                }
                .ms-media-gallery__thumbnails-container {
                    .msc-ss-carousel-vert {
                        // width: 100%;
                    }
                }
            }

            .msc-flipper {
                display: none;
            }
            ul.msc-ss-carousel-vert-slide {
                // li {
                //     width: 100% !important;
                //     height: 25rem !important;
                //     @include breakpoint(xs) {
                //         height: 30rem !important;
                //     }
                //     @include breakpoint(sm) {
                //         height: 32rem !important;
                //     }
                //     @include breakpoint(md) {
                //         height: 40rem !important;
                //     }
                //     @include breakpoint(md1) {
                //         height: 32rem !important;
                //     }
                //     border-radius: 8px;
                //     img {
                //         @include img-fit;
                //         border-radius: 8px;
                //         // background:red;
                //     }
                // }
            }
        }
        .ms-buybox__product-description {
            font-family: $font-family-primary;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-align: left;
            margin-bottom: 36px !important;
        }
    }

    .ms-buybox__content {
        // width: auto;
        // @include breakpoint(md1) {
        //     padding-top: 6rem;
        // }
        // @include breakpoint(md1) {
        //     width: 60%;
        //     // padding-top: 0;
        // }

        .szf-title-with-price {
            position: absolute;
            left: 2.4rem;
            top: 2.2rem;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            width: 100%;
            @include breakpoint(sm) {
                flex-direction: row;
                top: 3rem;
            }
            @include breakpoint(md) {
                left: 5rem;
                top: 5rem;
            }
            @include breakpoint(md1) {
                width: auto;
            }
            .ms-buybox__product-title {
                font-family: $font-family-primary;
                font-size: 2.6rem;
                font-weight: 500;
                line-height: 140%;
                letter-spacing: 0.04em;
                text-align: left;
                color: rgba(30, 30, 30, 1);
                padding-bottom: 0;
                margin: 0 10px 0 0;
                // min-width: 60%;
                @include breakpoint(md) {
                    font-size: 3.2rem;
                }
            }
            .msc-price {
                flex-direction: row;
                &__actual {
                    font-family: $font-family-primary;
                    font-size: 1.8rem;
                    font-weight: 500;
                    line-height: 140%;
                    letter-spacing: 0.04em;
                    text-align: left;
                    color: rgba(30, 30, 30, 1);
                    position: relative;
                    // padding: 0 5px;
                    @include breakpoint(md) {
                        font-size: 2rem;
                    }
                    // &:before {
                    //     position: absolute;
                    //     content: '(';
                    //     left: 0;
                    // }
                    // &:after {
                    //     position: absolute;
                    //     content: ')';
                    //     right: 0;
                    // }
                }
                &__strikethrough {
                    font-family: $font-family-primary;
                    font-size: 1.8rem;
                    font-weight: 500;
                    line-height: 140%;
                    letter-spacing: 0.04em;
                    text-align: left;
                    color: #9e9e9e;
                }
            }
        }
    }
    // ---------------------Carousel Arrows ---------------------//
    .msc-carousel__control__prev,
    .msc-carousel__control__next {
        background-color: transparent;
        border: 0;
        &:before {
            background-repeat: no-repeat;
            // top: 13px;
            // left: 18px;
            background-position: center;
            @media screen and (max-width: 768px) {
                width: 18px;
                height: 18px;
                background-size: contain;
            }
        }
        &:focus {
            background-color: transparent;
            box-shadow: none;
            border: none;
        }
        &:hover {
            background-color: transparent;
            box-shadow: none;
        }
        &__icon {
            &:before {
                content: '' !important;
            }
        }
    }
    .msc-carousel__control__prev {
        &:before {
            background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB10qZ?pubver=1');
        }
    }
    .msc-carousel__control__next {
        &:before {
            background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBXPH?pubver=1');
        }
    }
}
