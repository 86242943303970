.photo-video-gallery {
    .gallery-btns-div {
        @include custom-container;
        display: flex;
        column-gap: 4rem;
        row-gap: 2rem;
        margin-bottom: 5rem;
        flex-direction: column;
        @include breakpoint(md) {
            flex-direction: row;
        }
        button {
            display: flex;
            padding: 2.4rem;
            width: 100%;

            background: #ffffff;
            border: 1px solid #eaecee;
            box-shadow: 0px 4px 12px rgba(163, 163, 163, 0.25);
            border-radius: 12px;
            transition: all 0.3s ease-in-out;

            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 100px;
            @include breakpoint(md) {
                width: 344px;
                height: 209px;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
            }
            &:active,
            &:hover,
            &:focus {
                background-color: #122553 !important;
                .btn-img {
                    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2tKK?pubver=1');
                }
                p,
                span {
                    color: #fff;
                }
            }
            .btn-img {
                background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2m3b?pubver=1');
                background-repeat: no-repeat;
                background-position: left center;
                background-size: contain;
                height: 80%;
                width: 20%;

                @include breakpoint(md) {
                    margin-bottom: 20px;
                    height: 100%;
                    width: 30%;
                }
            }
            p {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 600;
                font-size: 2rem;
                line-height: 150%;
                color: #373f47;
                margin-bottom: 0;
                text-align: right;
                @include breakpoint(md){
                    text-align: left;
                }
            }
            span {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 600;
                font-size: 1.4rem;
                line-height: 150%;
                text-align: left;
                color: #373f47;
                display: none;
                @include breakpoint(md) {
                    display: inline-block;
                }
            }
        }
        .img-gallery-btn.active {
            background-color: #122553 !important;
            .btn-img {
                background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2tKK?pubver=1');
            }
            p,
            span {
                color: #fff;
            }
        }
        .video-gallery-btn {
            .btn-img {
                background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2tKH?pubver=1');
            }
            &:active,
            &:hover,
            &:focus {
                background-color: #122553 !important;
                .btn-img {
                    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2m3e?pubver=1');
                }
            }
        }
        .video-gallery-btn.active {
            background-color: #122553 !important;
            .btn-img {
                background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2m3e?pubver=1') !important;
            }
            p,
            span {
                color: #fff !important;
            }
        }
        // Media Booklet //
           .media-booklet-btn {
            .btn-img {
                background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/pmtjhhhpmz/imageFileData/MB1G44?pubver=1');
            }
            &:active,
            &:hover,
            &:focus {
                background-color: #122553 !important;
                .btn-img {
                    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/pmtjhhhpmz/imageFileData/MB1G47?pubver=1');
                }
            }
        }
        .media-booklet-btn.active {
            background-color: #122553 !important;
            .btn-img {
                background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/pmtjhhhpmz/imageFileData/MB1G47?pubver=1') !important;
            }
            p,
            span {
                color: #fff !important;
            }
        }
    }

    // Arrows
    .slick-arrow.slick-next {
        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBXPH?pubver=1');
        background-repeat: no-repeat;
        background-position: center;
        width: 35px;
        height: 35px;
        top: 50%;
        right: -8%;
        left: auto;
        @include breakpoint(sm) {
            right: -5%;
        }
        @include breakpoint(md) {
            right: -3%;
        }
    }

    .slick-arrow.slick-next:before,
    .slick-arrow.slick-prev:before {
        content: '';
    }
    .slick-arrow.slick-prev {
        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB10qZ?pubver=1');
        background-repeat: no-repeat;
        background-position: center;
        width: 35px;
        height: 35px;
        top: 50%;
        left: -8%;
        z-index: 2;
        @include breakpoint(sm) {
            left: -5%;
        }
        @include breakpoint(md) {
            left: -3%;
        }
    }
}
