@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.ms-checkout__guided-card-btn-cancel.is-submitting::before,
.ms-checkout__guided-card-btn-save.is-submitting::before,
.is-busy.ms-checkout__btn-place-order::before {
    content: '' !important;
    margin-right: 8px !important;
    margin-left: 8px !important;
    width: 2rem;
    height: 2rem;
    border: 3px solid #fff;
    border-bottom-color: gray;
    border-radius: 50%;
    display: inline-block !important;
    box-sizing: border-box;
    animation: rotation 1s linear infinite !important;
    margin-top: -3px;
}
