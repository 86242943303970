.photo-gallery-row {
    padding: 4.5rem 0;
    &:nth-child(even) {
        background: #faf7f4;
    }
    > div {
        width: 84%;
        margin: 0rem auto;
        @include breakpoint(md) {
            width: 90%;
        }
        @include breakpoint(md1) {
            // margin: 0rem auto;
            width: 92%;
        }
    }
    .szf-photo-card-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4.5rem;
        flex-direction: column;
        @include breakpoint(md){
            flex-direction: row;
        }
        h3 {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 3rem;
            line-height: 140%;
            /* identical to box height, or 56px */
            letter-spacing: -0.3px;
            text-transform: uppercase;

            /* Primary/color-primary-blue */
            color: #111f48;

            @include breakpoint(md){
                font-size: 4rem;
            }
        }
        button {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: 1rem 2rem;
            gap: 1rem;
            min-width: 18rem;
            height: 4.8rem;
            border: 1px solid #283c63;
            border-radius: 24px;
            background: #fff;
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.4px;
            color: #0d1020;
            background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2joZ?pubver=0');
            background-repeat: no-repeat;
            background-position: 2rem center;
            background-size: 2rem;
            &:hover {
                background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2tKN?pubver=1');
                background-position: 2rem center;
                background-size: 2rem;
                background-color: $primary-blue !important;
                color: #fff;
            }
        }
    }

    .szf_pandv__gallery-img-div {
        margin: 0 auto;
        height: 25rem;
        width: 95% !important;

        img {
            @include img-fit;
        }
    }
    .szf_pandv__gallery-img {
        width: 100%;
        height: 100%;

        img {
            @include img-fit;
        }
    }
}

// Modal - images modal
.modal-all-img-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4rem;
    row-gap: 4rem;
    justify-content: center;
    .szf_pandv__gallery-img {
        border-radius: 4px;
        height: 22rem;
        width: 100%;
        cursor: pointer;

        @include breakpoint(sm) {
            width: calc(51% - 3rem);
        }
        @include breakpoint(md) {
            width: calc(33% - 3rem);
        }
        @include breakpoint(md1) {
            width: calc(25% - 3rem);
        }
        .modal-single-img-container {
            width: 100%;
            height: 100%;
            overflow: hidden;
            &:hover{
                img{
                    transform: scale(1.3)
                }
            }
            img {
                @include img-fit;
                border-radius: 4px;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}


// Single image Modal
.single-image-modal .modal-single-img-container {
    width: 90% !important;
    margin: 0 auto;
    @include breakpoint(sm) {
        width: 70% !important;
    }
    img{
        @include img-fit;
    }
}

// images-modal
.modal-single-img-container {
    width: 100% !important;
}