//==============================================================================
// Mobile and Desktop Hidden
//==============================================================================

@mixin mobile_hidden {
    display: none;
    @include breakpoint(md) {
        width: block;
    }
}

@mixin desktop_hidden {
    display: block;
    @include breakpoint(md) {
        width: none;
    }
}

//==============================================================================
// Placeholder Styling
//==============================================================================

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

//==============================================================================
// Text Selection
//==============================================================================
@mixin selection {
    &::selection {
        @content;
    }
    & ::-moz-selection {
        @content;
    }
}
//==============================================================================
// MEDIA QUERY BREAKPOINTS
//==============================================================================

//==============================================================================
// Reset List
//==============================================================================
@mixin list-reset {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

//==============================================================================
// Horizontal List
//==============================================================================
@mixin horizontal-list {
    @include list-reset;
    display: inline-block;
}

//==============================================================================
// Fit Object image (cover)
//==============================================================================
@mixin img-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

//==============================================================================
// Remove outline on focus
//==============================================================================
@mixin no-outline {
    &:focus {
        outline: 0 !important;
    }
    // &:focus-visible {
    //     outline: 0 !important;
    // }
}

//==============================================================================
// Remove Hover Effect
//==============================================================================
@mixin no-hover {
    &:hover,
    &:focus {
        text-decoration: none;
        color: inherit !important;
    }
}

//==============================================================================
//
//==============================================================================
@mixin no-decoration {
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: none !important;
    }
}

//==============================================================================
// Text Ellipsis
//==============================================================================

@mixin text-ellipsis($lineCount: 1) {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: $lineCount;
    -webkit-box-orient: vertical;
}

//==============================================================================
// Border Radius
//==============================================================================
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box; /* stops bg color from leaking outside the border: */
}
//==============================================================================
//Center Block
//==============================================================================
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
//==============================================================================
//Center Flex
//==============================================================================
@mixin center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

//==============================================================================
//Pseudo Elements (::before | ::after)
//==============================================================================
// @mixin pseudo($display: block, $pos: absolute, $content: '') {
//     display: $display;
//     position: $pos;
//     content: $content;
// }
@mixin pseudo($display: block, $pos: absolute, $content: '', $top: auto, $right: auto, $bottom: auto, $left: auto) {
    display: $display;
    position: $pos;
    content: $content;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

//==============================================================================
//Caret
//==============================================================================

@mixin caret($direction: down, $size: 5px, $color: #555) {
    width: 0;
    height: 0;

    @if ($direction == left) {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
    } @else if ($direction == right) {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
    } @else if ($direction == down) {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
    } @else {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
    }
}

//==============================================================================
//Font Styles
//==============================================================================
@mixin font-content($font-family: $font-family-primary, $font-size: $font-size-m, $font-weight: 400, $line-height: 1) {
    font-family: $font-family-primary;
    font-weight: $font-weight;
    font-size: $font-size;
    line-height: $line-height;
}
//==============================================================================
//Text size
//==============================================================================

//==============================================================================
//heading bottom border
//==============================================================================
@mixin bottom-line($line-color: $primary-yellow) {
    @include pseudo;
    width: 30%;
    height: 6px;
    background: $line-color;
    bottom: -6px;
    left: 0px;
    @include breakpoint(sm) {
        width: 120px;
    }
}
//==============================================================================
//Button
//==============================================================================

@mixin cta_styling($cta-color) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 100%;
    height: 4.8rem;
    background: $cta-color;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.4px;
    color: $white;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
    @if ($cta-color == $primary_yellow) {
        color: $primary_blue;
    }
    &:hover {
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }
    @include breakpoint(sm) {
        width: 200px;
    }
}
@mixin ghost_cta_with_icon {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 14px;
    min-width: auto;
    height: 6.5rem;
    width: 6.5rem;
    border: 1px solid #0d1020;
    border-radius: 4px;
    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB1NRH?pubver=1');
    background-repeat: no-repeat;
    background-position: 2rem center;
    background-size: 2.4rem;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    border-radius: 24px;

    @include breakpoint(md1) {
        min-width: 17rem;
        padding: 10px 20px 10px 5rem;
        width: auto;
        font-size: 2rem;
        height: auto;
    }
    &:hover {
        background-color: $primary-blue;
        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2gu9?pubver=3');

        a {
            color: #fff;
        }
    }
    // a {
    font-family: $font-family-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 0;
    line-height: 140%;
    letter-spacing: -0.3px;
    color: #0d1020;
    text-decoration: none;
    a {
        color: #0d1020;
        text-decoration: none;
    }
}

//==============================================================================
//Text Truncate
//==============================================================================

//==============================================================================
//Checkbox
//==============================================================================
/* Customize the label (the container) */
.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 3.5rem;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2.2rem;
    width: 2.2rem;
    border-radius: 3px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
    // background-color: red;
    background-color: #13294b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
    left: 0.7rem;
    top: 0.3rem;
    width: 0.7rem;
    height: 1.3rem;
    border: solid white;
    border-width: 0 0.3rem 0.3rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

//==============================================================================
//custom container
//==============================================================================
@mixin custom-container {
    width: 84%;
    margin: 2rem auto;
    @include breakpoint(md) {
        width: 90%;
    }
    @include breakpoint(md1) {
        margin: 6rem auto;
        width: 92%;
    }
}
@mixin custom-smaller-container {
    width: 84%;
    margin: 2rem auto;
    @include breakpoint(md) {
        width: 80%;
    }
    @include breakpoint(md1) {
        margin: 6rem auto;
    }
}
