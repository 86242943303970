
// ---------------------------header.scss ---------------------------// 

@import "./00-settings/colors.scss";
@import "./00-settings/font.scss";
@import "./00-settings/responsive-breakpoints.scss";
@import "./00-settings/icons.scss";
@import "./01-tools/mixins.scss";
@import "./01-tools/layout.scss";
@import "./01-tools/visually-hidden.scss";
@import "./02-generic/reset.scss";

//spacing
$header-container-max-width: 1440px;
$header-container-spacing-to-bottom-tablet: 10px;
$signin-info-spacing-left: 8px;
$heading-padding-top: 28px;
$heading-padding-bottom: 28px;
$header-height: 48px;
$header-mobile-height: 48px;
$header-icon-width: 40px;
$header-hamburger-icon-width: 21px;
$account-link-height: 35px;
$account-links-max-width: 239px;
$popover-menu-item-height: 48px;
$header-acc-info-desktop-margin-right: 26px;
$header-nav-icon-margin-right-desktop: 10px;
$header-logo-margin-right-desktop: 32px;
$header-logo-padding-mobile: 7px 0;
$header-logo-min-width: 116px;
$header-logo-min-height: 20px;
$header-cart-count-margin-bottom: 20px;
$header-cart-count-border-radius: 100px;
$header-cart-count-padding: 2px 6px 0 6px;
$header-search-margin-right-desktop: 40px;
$header-locator-margin-right-desktop: 26px;
$header-search-margin-right-tablet: 20px;
$header-container-topbar-account-info-margin-left: 0;
$header-container-topbar-account-info-signin-button-padding: 0;
$msv-profile-button-text-margin-left: 10.33px;
$msv-pop-over-box-shadow-color: rgba(0, 0, 0, 0.1);
$msv-account-pop-over-box-shadow-color: rgba(0, 0, 0, 0.13);

$header-modal-mobile-hamburger-content-padding: 0;
$header-modal-mobile-hamburger-body-padding: 0;
$header-modal-mobile-hamburger-body-button-padding: 12px 8px;
$header-modal-mobile-close-button-padding-right: 12px;
$header-modal-mobile-close-button-padding-top: 10px;

//style presets
:root {
    --msv-header-bg: var(--msv-accent-brand-color);
    --msv-header-font-color: var(--msv-font-secondary-color);
    --msv-header-icon-font-size: #{$msv-icon-size-xs};
}

.ms-header .msc-btn {
    background-color: var(--msv-header-bg);
}

.msc-modal .ms-header__mobile-hamburger .msc-btn {
    display: block;
    background-color: var(--msv-header-bg);
}

.lock-opaque {
    top: 0;
    position: fixed;
    z-index: 1010;
    width: 100%;
    box-shadow: $msv-depth8;

    .ms-header__collapsible-hamburger.show {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        min-height: auto !important;
    }
}

.ms-header {
    background-color: var(--msv-header-bg);
    color: var(--msv-header-font-color);

    margin-left: auto;
    margin-right: auto;

    &__container {
        margin: 0 auto;

        .ms-header__preferred-store-btn,
        .ms-search__icon,
        .ms-header__signin-button,
        .msc-wishlist-icon,
        .msc-cart-icon,
        .ms-header__profile-button {
            @include font-body-regular-m();

            color: var(--msv-header-font-color);
            border: 1px solid transparent;

            @include vfi();

            @media (max-width: $msv-breakpoint-l - 1) {
                height: 48px;
                width: 44px;
            }
        }

        .ms-header__switchcustomer-button-text {
            width: 100%;

            > div {
                &.ms-header__shoppingas-text {
                    display: none;
                }
            }
        }

        .ms-profile-button-text {
            display: inline-block;
            text-align: left;
        }

        .ms-profile-button-splitter {
            border-top: 1px solid $msv-secondary;
        }

        .ms-header__account-info {
            @include font-body-regular-m();
            justify-content: flex-end;
            height: $header-height;
            margin-right: $header-acc-info-desktop-margin-right;
            display: flex;

            .ms-header__signin-button, .ms-header__profile-button {
                @include font-body-regular-m();

                background-color: var(--msv-header-bg);
                color: var(--msv-header-font-color);
                align-items: center;
                display: flex;
                height: 48px;
                white-space: nowrap;
                cursor: pointer;

                &::before {
                    @include msv-icon();
                    content: $msv-signin;
                    font-size: var(--msv-header-icon-font-size);
                    width: auto;
                    vertical-align: text-bottom;
                    text-align: center;
                    font-weight: normal;
                    color: var(--msv-header-font-color);
                }
            }

            &__account-link-container {
                height: $account-link-height;
            }

            a {
                @include font-body-regular-m();
                align-items: center;
                display: flex;
                height: $header-height;
                line-height: $msv-line-height-m;
                background-color: $msv-white;
                color: $msv-primary;
                white-space: nowrap;
                padding-left: 20px;
                padding-right: 20px;
            }

            .msc-popover {
                background-clip: padding-box;
                background-color: $msv-white;
                display: block;
                left: 0;
                max-width: $account-links-max-width;
                position: absolute;
                word-wrap: break-word;
                z-index: 1060;
                box-shadow: 0 0.6px 1.8px $msv-pop-over-box-shadow-color, 0 3.2px 7.2px $msv-account-pop-over-box-shadow-color;

                .msc-popover-inner {
                    .ms-signin-info__account-link-button:hover, .ms-header__signout-button:hover {
                        background-color: $msv-white-20;
                    }
                }

                .msc-btn {
                    background-color: $msv-white;
                    color: $msv-primary;
                    font-weight: normal;
                    height: $popover-menu-item-height;
                }

                .msc-arrow {
                    display: none;
                }
            }

            .ms-profile-button-text, .ms-header__signin-button-text {
                margin-left: $msv-profile-button-text-margin-left;
                vertical-align: top;
            }
        }

        .ms-header__topbar {
            margin: 0 auto;
            max-width: $header-container-max-width;
            align-items: center;
            display: flex;
            flex-wrap: nowrap;
            height: $header-mobile-height;

            padding-left: $msv-layout-container-padding-m;
            padding-right: $msv-layout-container-padding-m;

            @media screen and (max-width: $msv-breakpoint-m) {
                padding-left: $msv-layout-container-padding-s;
                padding-right: $msv-layout-container-padding-s;
            }

            @media screen and (min-width: $msv-breakpoint-l) {
                padding-left: $msv-layout-container-padding-l;
                padding-right: $msv-layout-container-padding-l;
            }

            .ms-header__nav-icon {
                @include vfi();
                background: var(--msv-header-bg);
                display: flex;
                align-items: center;
                color: var(--msv-header-font-color);
                border: 1px solid transparent;
                margin-right: $header-nav-icon-margin-right-desktop;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }

                &::before {
                    @include msv-icon();
                    content: $msv-GlobalNavButton;
                    font-size: var(--msv-header-icon-font-size);
                    width: $header-hamburger-icon-width;
                    vertical-align: text-bottom;
                    text-align: center;
                }

                @media (max-width: $msv-breakpoint-m - 1) {
                    height: 44px;
                    width: 44px;
                    margin-right: 0;
                }
            }

            .ms-header__logo {
                display: block;
                margin-right: $header-logo-margin-right-desktop;

                a {
                    @include vfi();
                    display: block;
                }

                img {
                    width: auto;
                    height: auto;
                    min-width: $header-logo-min-width;
                    min-height: $header-logo-min-height;
                }
            }

            .ms-header__mobile-logo {
                display: none;
            }

            .ms-header__preferred-store-container {
                .ms-header__preferred-store-btn {
                    border: 1px solid transparent;
                    display: flex;
                    height: $header-height;
                    line-height: $msv-line-height-m;
                    align-items: center;
                    padding: 0;
                    cursor: pointer;

                    @include vfi();
                    @include add-icon($msv-Shop-Address);

                    &::before {
                        font-size: var(--msv-header-icon-font-size);
                        display: inline-block;
                        width: auto;
                        text-align: center;
                    }

                    .ms-header__preferred-store-text {
                        @include font-body-regular-m();
                        color: var(--msv-header-font-color);
                        white-space: nowrap;
                    }
                }
            }

            .ms-header__wishlist-desktop {
                display: none;
                width: $header-icon-width;
                height: $header-height;
                align-items: center;

                @include add-icon($msv-Heart, before, 400);

                &::before {
                    font-size: var(--msv-header-icon-font-size);
                    width: $header-icon-width;
                    vertical-align: text-bottom;
                    text-align: center;
                }

                .msc-wishlist-icon__text {
                    display: none;
                }
            }

            .msc-cart-icon {
                display: flex;
                align-items: center;

                &:hover {
                    text-decoration: none;
                }

                &::before {
                    @include msv-icon();
                    content: $msv-shopping-bag;
                    font-size: var(--msv-header-icon-font-size);
                    vertical-align: text-bottom;
                    text-align: center;
                }

                &__count {
                    @include font-content-s();
                    color: var(--msv-header-font-color);
                    margin-bottom: $header-cart-count-margin-bottom;
                    padding: $header-cart-count-padding;
                    background-color: $msv-blue;
                    border-radius: $header-cart-count-border-radius;
                    text-align: center;
                }
            }

            .ms-cart-icon-wrapper {
                border: 1px solid transparent;
                padding: 0;
                cursor: pointer;

                @include vfi();
            }

            .ms-header__account-info a:hover {
                text-decoration: none;
            }
        }

        .ms-header__collapsible-hamburger {
            position: absolute;
            width: 100%;
            left: 0;

            &.collapsing {
                display: none;
            }

            @media (min-width: $msv-breakpoint-l) {
                top: 104px;
            }

            @media (max-width: $msv-breakpoint-l) {
                top: 68px;
            }

            .ms-header__mobile-hamburger-menu-links {
                display: none;
            }
        }
    }

    /* stylelint-disable no-descending-specificity -- Suppressed. */
    &.hide-header-options {
        .ms-header__nav-icon,
        .msc-cart-icon,
        .msc-wishlist-icon,
        .ms-header__account-info {
            display: none;
        }
    }

    @media (min-width: $msv-breakpoint-xs) {
        .msc-cart-icon {
            &::before {
                width: $header-icon-width / 2;
            }
        }
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        .ms-header__preferred-store-container {
            .ms-header__preferred-store-btn {
                cursor: pointer;

                &::before {
                    margin-right: $header-nav-icon-margin-right-desktop;
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
        .mobile-navicon-container {
            display: none;
        }

        .ms-nav.desktop-vp {
            display: flex;
            position: relative;
            border-top: 1px solid var(--msv-header-font-color);
        }

        &__container {
            .ms-header__topbar {
                .ms-search {
                    margin-right: $header-search-margin-right-tablet;
                    height: $header-height;
                }

                .ms-header__account-info {
                    margin-right: $header-search-margin-right-tablet;
                }

                .ms-header__preferred-store-container {
                    margin-right: 0;
                }

                .ms-header__preferred-store-btn {
                    margin-right: $header-search-margin-right-tablet;
                    cursor: pointer;

                    &::before {
                        margin-right: 0;
                    }
                }

                .ms-header__container .ms-header__topbar .ms-header__account-info {
                    margin-right: $header-search-margin-right-tablet;
                    margin-top: 0;
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-l) {
        padding-top: $heading-padding-top;
        padding-bottom: $heading-padding-bottom;

        &__modal {
            display: none;
        }

        &__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);

            &::before {
                @include font-body-regular-m();
                width: $header-icon-width;

                @include msv-icon();
                content: $msv-signin;
                font-weight: normal;
            }

            &:focus {
                outline: none;
                border: none;
            }
        }

        &__divider {
            display: none;
        }

        .ms-search {
            border: 1.5px solid $msv-gray-50;
            border-radius: 30px;
            justify-content: flex-end;
            flex-basis: 0;
            flex-grow: 1;
            margin-right: $header-search-margin-right-desktop;

            .hide {
                display: none;
            }

            .ms-search__label.bx-show {
                display: none;
            }
        }

        &__preferred-store-container {
            margin-right: $header-locator-margin-right-desktop;
        }

        .ms-header__wishlist-desktop {
            display: flex;
        }

        .ms-nav.desktop-vp {
            display: flex;
            position: relative;
            border-top: 1px solid var(--msv-header-font-color);
        }

        .mobile-navicon-container {
            display: none;
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .ms-profile-button-text {
            display: none;
        }

        .ms-header__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);

            &::before {
                @include font-body-regular-m();
                @include msv-icon();
                content: $msv-signin;
                color: var(--msv-header-font-color);
            }
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        padding-top: $header-container-spacing-to-bottom-tablet;
        padding-bottom: $header-container-spacing-to-bottom-tablet;

        &__container {
            .ms-header__topbar {
                .ms-header__mobile-logo {
                    display: block;

                    a {
                        @include vfi();
                        padding: $header-logo-padding-mobile;
                    }
                }

                .ms-header__logo {
                    display: none;
                }

                .ms-header__preferred-store-container {
                    .ms-header__preferred-store-btn {
                        cursor: pointer;

                        @include vfi();

                        &::before {
                            font-size: var(--msv-header-icon-font-size);
                            vertical-align: text-bottom;
                            text-align: center;
                        }

                        .ms-header__preferred-store-text {
                            display: none;
                        }
                    }
                }

                .ms-header__account-info {
                    margin-left: $header-container-topbar-account-info-margin-left;

                    .ms-header__signin-button {
                        padding: $header-container-topbar-account-info-signin-button-padding;

                        .ms-header__signin-button-text {
                            display: none;
                        }
                    }
                }

                .ms-site-picker {
                    display: none;
                }
            }
        }

        .ms-search {
            display: flex;
            justify-content: flex-end;
            flex-basis: 0;
            flex-grow: 1;
            margin-right: $header-search-margin-right-tablet;
            border: none;

            .hide {
                display: none;
            }

            .ms-search__label.bx-show {
                display: block;

                .ms-search__icon {
                    align-items: center;
                    color: var(--msv-header-font-color);
                    font-weight: normal;

                    @media (max-width: $msv-breakpoint-l - 1) {
                        padding: 0;

                        &::before {
                            font-size: var(--msv-header-icon-font-size);
                        }
                    }

                    .ms-search__icon-text {
                        display: none;
                    }
                }
            }
        }

        .ms-profile-button-text {
            @include visually-hidden();
        }

        .ms-header__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);

            &::before {
                @include font-body-regular-m();
                color: var(--msv-header-font-color);
                width: $header-icon-width;

                @include msv-icon();
                content: $msv-signin;
            }
        }

        .ms-header__switchcustomer-button-text {
            > div {
                @include basicTruncation;
                @include font-body-regular-s();

                &.ms-header__shoppingas-text {
                    @include font-body-bold-s();
                    display: block;
                }
            }
        }
    }

    @media (max-width: ($msv-breakpoint-m)-1) {
        &__container {
            .ms-header__topbar {
                .desktop-navicon-container {
                    display: none;
                }

                .ms-search {
                    margin-right: auto;
                }

                .ms-header__account-info {
                    margin-right: 0;
                    background-color: $msv-blue;
                }

                .ms-header__preferred-store-btn {
                    margin-right: 0;
                    cursor: pointer;

                    &::before {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.ms-header__modal {
    @media (min-width: ($msv-breakpoint-m)-1) {
        display: none;
    }

    .msc-modal {
        .ms-header__mobile-hamburger {
            background: var(--msv-header-bg);
            height: 100%;
            margin: 0;
            max-width: none;

            .msc-modal__content {
                height: 100%;
                padding: $header-modal-mobile-hamburger-content-padding;
                background: var(--msv-header-bg);
                color: var(--msv-header-font-color);

                .ms-header__mobile-hamburger-menu-body {
                    padding: $header-modal-mobile-hamburger-body-padding;

                    .msc-btn {
                        font-size: 16px;
                        height: unset;
                        line-height: 22px;
                        padding: $header-modal-mobile-hamburger-body-button-padding;
                    }
                }

                .msc-modal__header {
                    display: block;
                    height: 18px;

                    .msc-modal__close-button {
                        color: var(--msv-header-font-color);
                        right: 0;
                        margin: 0;
                        opacity: 1;
                        padding-right: $header-modal-mobile-close-button-padding-right;
                        padding-top: $header-modal-mobile-close-button-padding-top;
                        z-index: 1000;

                        &:focus {
                            outline-offset: 0;
                        }

                        &::before {
                            @include msv-icon();
                            content: $msv-Cancel;
                            font-size: var(--msv-header-icon-font-size);
                            position: unset;
                            text-align: center;
                            vertical-align: text-bottom;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }

            .ms-header__mobile-hamburger-menu-links {
                display: none;
            }
        }
    }
}

.skip-to-main {
    @include font-body-regular-s();
    color: $msv-black;
}


// --------------------------------navigation menu -----------------------------//

@import "./00-settings/colors.scss";
@import "./00-settings/font.scss";
@import "./00-settings/responsive-breakpoints.scss";
@import "./00-settings/icons.scss";
@import "./01-tools/mixins.scss";
@import "./02-generic/image.scss";
@import "./02-generic/button.scss";

$navigation-parent-menu-margin-left: 20px;
$navigation-parent-menu-margin-right: 20px;
$navigation-parent-menu-margin-top: 20px;
$navigation-menu-list-min-width: 250px;
$navigation-menu-item-line-height: 28px;
$navigation-menu-item-level2-line-height: 24px;
$navigation-menu-button-icon-margin-left: 8px;
$navigation-menu-icon-line-height: 40px;
$navigation-menu-wrapper-width: 100%;
$navigation-menu-wrapper-left: 0;
$navigation-menu-image-padding: 40px;
$navigation-ms-nav-list-width: 100%;
$navigation-ms-nav-submenu-margin-left: 31px;
$navigation-ms-nav-area-width: 63%;
$navigation-menu-list-min-width: 180px;
$navigation-ms-nav-list-item-link-margin-bottom: 20px;

$navigation-ms-nav-list-mobile-container-padding-left: 16px;
$navigation-ms-nav-list-mobile-container-padding-right: 34px;
$navigation-ms-nav-list-button-mobile-padding-left: 0;
$navigation-ms-nav-list-button-mobile-padding-right: 0;
$navigation-ms-nav-list-button-before-mobile-font-size: 21px;
$navigation-ms-nav-list-item-link-mobile-margin-top: 16px;
$navigation-ms-nav-list-item-link-mobile-padding-top: 16px;
$navigation-ms-nav-list-item-link-mobile-padding: 16px;
$navigation-ms-nav-list-feature-mobile-padding-bottom: 20px;
$navigation-ms-nav-child-mobile-padding-top: 12px;

$navigation-ms-nav-list-tablet-margin-left: 0;
$navigation-ms-nav-list-item-tablet-margin-left: 0;
$navigation-ms-nav-list-level3-tablet-margin-left: 20px;
$navigation-ms-nav-ms-nav-list-tablet-padding-left: 24px;
$navigation-ms-nav-list-item-link-level2-tablet-margin-bottom: 25px;
$navigation-ms-nav-list-item-link-level3-tablet-margin-bottom: 15px;
$navigation-ms-nav-list-item-tablet-first-child-margin-left: 0;
$navigation-ms-nav-close-button-tablet-padding-right: 16px;
$navigation-ms-nav-close-button-tablet-padding-top: 10px;
$navigation-ms-nav-list-item-link-button-tablet-margin-bottom: 28px;
$navigation-ms-nav-list-item-nav-feature-tablet-padding-left: 24px;
$navigation-ms-nav-list-item-drawer-button-tablet-margin-bottom: 15px;
$navigation-ms-nav-list-item-drawer-collapse-tablet-padding-left: 40px;
$navigation-ms-nav-list-item-link-tablet-padding-zero: 0;
$navigation-ms-nav-list-item-button-first-child-tablet-margin-left: 0;
$navigation-ms-nav-drawer-open-close-tablet-padding: 13px;

$navigation-ms-nav-list-desktop-margin-left: 0;
$navigation-ms-nav-list-item-desktop-margin-left: 0;
$navigation-ms-nav-list-level2-desktop-margin-left: 20px;
$navigation-ms-nav-list-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-link-desktop-margin-bottom: 25px;
$navigation-ms-nav-list-item-link-level2-desktop-margin-bottom: 15px;
$navigation-ms-nav-list-item-button-link-desktop-margin-bottom: 40px;
$navigation-ms-nav-list-item-first-child-desktop-margin-left: 0;
$navigation-ms-nav-default-nav-list-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-nav-feature-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-nav-feature-desktop-padding-right: 60px;
$navigation-ms-nav-list-item-levle2-desktop-padding-right: 20px;
$navigation-ms-nav-list-item-drawer-button-desktop-margin-bottom: 15px;
$navigation-ms-nav-list-item-drawer-collapse-desktop-padding-left: 40px;
$navigation-ms-nav-list-item-link-desktop-padding-left: 0;
$navigation-ms-nav-list-item-link-desktop-padding-right: 0;
$navigation-ms-nav-list-item-button-first-child-desktop-margin-left: 0;
$navigation-ms-nav-drawer-open-desktop-padding-right: 13px;
$navigation-ms-nav-drawer-close-desktop-padding-right: 13px;
$navigation-ms-nav-list-item-link-disable-top-menu-padding-right: 20px;
$navigation-ms-nav-list-item-link-disable-top-menu-padding-bottom: 25px;

$msv-width-onequarter: 25%;

//style presets
:root {
    --msv-nav-bg: var(--msv-accent-brand-color);
    --msv-nav-border: transparent;
    --msv-nav-dropdown-border: transparent;
    --msv-nav-carot-color: var(--msv-font-secondary-color);
    --msv-nav-font-color: var(--msv-font-secondary-color);
    --msv-nav-font-size: var(--msv-body-font-size-m);
    --msv-nav-close-icon-font-size: #{$msv-icon-size-xs};
}

.ms-nav {
    background: var(--msv-nav-bg);
    color: var(--msv-nav-font-color);
    width: $msv-width-full;
    padding-left: 0;
    padding-right: 0;

    &__list {
        z-index: 1000;

        &.level-2 {
            width: $msv-width-full;
            overflow-y: auto;
        }

        .level-2 {
            > li {
                @include font-body-regular-m();
                color: var(--msv-nav-font-color);
            }

            &.navmenu-multi-level {
                li.ms-nav__list__item {
                    .havesubmenu {
                        font-weight: normal;
                    }
                }
            }
        }

        &__item {
            @include font-body-regular-m();
            color: var(--msv-nav-font-color);

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .havesubmenu {
                @include font-body-regular-m();
                color: var(--msv-nav-font-color);

                .drawer {
                    .drawer__button {
                        margin-bottom: 15px;
                        border-bottom: none;
                        border: none;
                        color: var(--msv-nav-font-color);
                    }

                    .collapse.show {
                        display: grid;
                        margin-left: 10px;

                        > .ms-nav__list__item__link {
                            padding-left: 10px;
                            cursor: pointer;
                        }

                        .ms-nav__list__item__link {
                            margin-left: $navigation-ms-nav-submenu-margin-left;
                            display: block;
                        }
                    }

                    .collapsing {
                        display: grid;
                        margin-left: 10px;

                        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                        .ms-nav__list__item__link {
                            margin-left: $navigation-ms-nav-submenu-margin-left;
                            display: block;
                        }
                    }
                }
            }

            &__image {
                position: absolute;
                right: 0;
            }
        }
    }

    > .ms-nav__list {
        background: var(--msv-nav-bg);

        > .ms-nav__list__item {
            background: var(--msv-nav-bg);

            > .ms-nav__list {
                background: var(--msv-nav-bg);
                min-width: $navigation-menu-list-min-width;
            }

            .ms-nav__deafult {
                > .ms-nav__list {
                    border: 1px solid var(--msv-nav-dropdown-border);
                }
            }

            > .ms-nav__deafult {
                left: 0;
            }

            > .ms-nav__feature {
                background: var(--msv-nav-bg);
                border: 1px solid var(--msv-nav-dropdown-border);
                height: auto;
                left: $navigation-menu-wrapper-left;
                min-width: $navigation-menu-list-min-width;
                position: absolute;
                outline: none;
                overflow: auto;
                width: $navigation-menu-wrapper-width;
                display: inline-flex;

                > .category-image-container {
                    .category-image {
                        position: relative;
                        margin-right: 3px;

                        .category-image-title {
                            text-align: center;
                            display: none;
                        }

                        picture {
                            + div.category-image-title {
                                display: block;
                            }
                        }

                        .ms-nav__list__item__link {
                            @include feature-link-dark-m();
                            text-align: center;
                            word-break: break-all;
                            white-space: break-spaces;
                            height: auto;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            padding: 0;
                            border-bottom: 3px solid $msv-blue;
                            transform: translate(-50%, -50%);
                            background-color: transparent;
                        }

                        img {
                            margin: 0 auto;
                            margin-bottom: $navigation-ms-nav-list-item-link-margin-bottom;

                            > .ms-nav-image__item {
                                display: block;
                                padding: $navigation-menu-image-padding;
                            }

                            &:hover {
                                transform: scale(1.025);
                                transition: transform 0.4s;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $msv-breakpoint-xs) {
    .ms-nav {
        &__list {
            display: block;
            flex-wrap: nowrap;

            &__mobile__container {
                border-bottom: 1px solid var(--msv-nav-border);
                display: flex;
                padding-left: $navigation-ms-nav-list-mobile-container-padding-left;
                padding-right: $navigation-ms-nav-list-mobile-container-padding-right;

                &__button {
                    @include vfi();
                    @include add-icon($msv-BackArrow);
                    @include font-body-regular-m();
                    background-color: var(--msv-nav-bg);
                    border: none;
                    color: var(--msv-nav-font-color);
                    padding-left: $navigation-ms-nav-list-button-mobile-padding-left;
                    padding-right: $navigation-ms-nav-list-button-mobile-padding-right;

                    &:focus {
                        outline-offset: -1px;
                    }
                }

                &__button::before {
                    font-size: $navigation-ms-nav-list-button-before-mobile-font-size;
                }

                &__span {
                    @include font-body-bold-m();
                    margin: 0 auto;
                    color: var(--msv-nav-font-color);
                }
            }

            @media screen and (max-width: $msv-breakpoint-m) {
                &__item {
                    &__image {
                        display: none;
                    }

                    &__button,
                    &__link,
                    &__span {
                        @include font-heading-h4-s();
                        display: flex;
                        align-items: center;
                        color: var(--msv-header-font-color);
                        height: auto;

                        @media screen and (max-width: ($msv-breakpoint-m)-1) {
                            margin-top: $navigation-ms-nav-list-item-link-mobile-margin-top;
                            width: 100%;
                            padding: $navigation-ms-nav-list-item-link-mobile-padding;
                        }
                        background-color: var(--msv-nav-bg);
                        border: none;
                        padding: 0;

                        &::after {
                            display: none;
                        }

                        &:focus,
                        &:active {
                            @include font-heading-h3-s();
                            outline: none;
                            background-color: $msv-gray-70;
                        }
                    }

                    .ms-nav__list {
                        .ms-nav__list__item__button {
                            justify-content: space-between;

                            @media screen and (max-width: ($msv-breakpoint-m)-1) {
                                @include add-icon($msv-ChevronRight, after);
                                float: right;
                            }
                        }

                        .ms-nav__list__item__button,
                        .ms-nav__list__item__link {
                            @include font-heading-h4-s();
                            color: var(--msv-nav-font-color);

                            &:focus,
                            &:active {
                                @include font-heading-h3-s();
                                color: var(--msv-nav-font-color);
                                outline: none;
                                background-color: $msv-gray-70;
                            }
                        }
                    }

                    .ms-nav__list.level-3 {
                        .ms-nav__list__item__button {
                            justify-content: space-between;

                            @media screen and (max-width: ($msv-breakpoint-m)-1) {
                                @include add-icon($msv-ChevronRight, after);
                                float: right;
                            }
                        }

                        .ms-nav__list__item__button,
                        .ms-nav__list__item__link {
                            @include font-heading-h4-s();

                            &:focus,
                            &:active {
                                @include font-heading-h3-s();
                                outline: none;
                                background-color: $msv-gray-70;
                            }
                        }
                    }
                }
            }
        }

        > .ms-nav__list {
            width: $msv-width-full;
            outline: none;

            .ms-nav__list {
                width: $msv-width-full;
            }

            .havecateImage {
                float: left;
                position: relative;
                width: $navigation-ms-nav-area-width;
            }

            > .ms-nav__list__item {
                .ms-nav__feature {
                    padding-bottom: $navigation-ms-nav-list-feature-mobile-padding-bottom;
                    width: $msv-width-full;
                }

                > .ms-nav__list__item__button {
                    color: var(--msv-nav-font-color);
                    justify-content: space-between;

                    @media screen and (max-width: ($msv-breakpoint-m)-1) {
                        @include add-icon($msv-ChevronRight, after);
                        float: right;
                    }
                }
            }

            .ms-nav__list__item:first-child {
                @media screen and (max-width: ($msv-breakpoint-m)-1) {
                    padding-top: $navigation-ms-nav-list-item-link-mobile-padding-top;
                }
            }
        }
    }

    .ms-nav.child {
        position: absolute;
        top: 0;
        padding-top: $navigation-ms-nav-child-mobile-padding-top;
    }
}

@media screen and (min-width: $msv-breakpoint-l) {
    .ms-nav {
        display: flex;
        position: relative;
        border-top: 1px solid var(--msv-header-font-color);

        &__list {
            position: absolute;

            &.level-2 {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                left: 0;
                background: var(--msv-nav-bg);
                width: $msv-width-threequarter;

                .ms-nav__list {
                    position: relative;
                    margin-left: $navigation-ms-nav-list-tablet-margin-left;

                    .ms-nav__list__item {
                        font-weight: normal;
                        margin-left: $navigation-ms-nav-list-item-tablet-margin-left;

                        .ms-nav__list {
                            margin-left: $navigation-ms-nav-list-level3-tablet-margin-left;
                        }
                    }
                }

                .level-2 {
                    display: block;
                }
            }

            &__mobile__container {
                display: none;
            }
        }

        .category-image-container {
            width: $msv-width-onequarter;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        > .ms-nav__list {
            display: flex;
            flex-wrap: nowrap;
            outline: none;
            position: relative;
            width: $navigation-ms-nav-list-width;
            padding-left: $navigation-ms-nav-ms-nav-list-tablet-padding-left;
            overflow-x: auto;
            // height: 70vh;

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .ms-nav__list {
                height: auto;

                .ms-nav__list__item__link {
                    @include font-body-regular-m();
                    color: var(--msv-nav-font-color);
                    margin-bottom: $navigation-ms-nav-list-item-link-level2-tablet-margin-bottom;
                    display: inline-block;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 3px;
                        bottom: -8px;
                        left: 0;
                        background-color: $msv-blue;
                        visibility: visible;
                        transform: scaleX(1);
                        transition: all 0.2s ease-in-out;
                    }

                    &:hover::before {
                        visibility: visible;
                        transform: scaleX(1);
                        bottom: -4px;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .ms-nav__list {
                    .ms-nav__list__item__link {
                        @include font-body-regular-m();
                        color: var(--msv-nav-font-color);
                        margin-bottom: $navigation-ms-nav-list-item-link-level3-tablet-margin-bottom;
                        border-bottom: none;

                        &::before {
                            visibility: hidden;
                        }

                        &:hover::before {
                            visibility: hidden;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            > .ms-nav__list__item:first-child {
                margin-left: $navigation-ms-nav-list-item-tablet-first-child-margin-left;
            }

            .ms-nav-close-button {
                width: 100%;
                text-align: right;
                right: 0;
                padding-right: $navigation-ms-nav-close-button-tablet-padding-right;
                padding-top: $navigation-ms-nav-close-button-tablet-padding-top;

                .nav-menu-close {
                    color: var(--msv-nav-font-color);
                    background-color: transparent;
                    border: 1px solid transparent;

                    @include vfi();

                    &::before {
                        @include msv-icon();
                        content: $msv-Cancel;
                        font-size: var(--msv-nav-close-icon-font-size);
                        position: unset;
                        text-align: center;
                        vertical-align: text-bottom;
                    }
                }
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            > .ms-nav__list__item {
                margin: {
                    top: $navigation-parent-menu-margin-top;
                    left: $navigation-parent-menu-margin-left;
                    right: $navigation-parent-menu-margin-right;
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__button, > .ms-nav__list__item__link {
                    @include vfi();
                    @include font-body-regular-m();
                    align-items: flex-end;
                    // background-color: var(--msv-nav-bg);
                    border: none;
                    color: var(--msv-nav-font-color);
                    display: flex;
                    justify-content: center;
                    position: relative;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin-bottom: $navigation-ms-nav-list-item-link-button-tablet-margin-bottom;
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &::before {
                        right: 0;
                        left: 0;
                        content: "";
                        outline-offset: 0;
                        width: 0%;
                        visibility: hidden;
                    }
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover, &:focus {
                        background: transparent;
                        border: none;
                        outline: none;
                    }
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover {
                        @include font-heading-h3-m();
                        cursor: pointer;
                    }
                }

                > .ms-nav__list__item__button[aria-expanded="true"],
                > .ms-nav__list__item__link[aria-expanded="true"] {
                    @include font-heading-h3-m();
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__button {
                    &::after {
                        @include msv-icon();
                        color: var(--msv-nav-carot-color);
                        content: $msv-ChevronDown;
                        font-size: var(--msv-nav-font-size);
                        margin-left: $navigation-menu-button-icon-margin-left;
                        line-height: $navigation-menu-icon-line-height;
                        display: none;
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .ms-nav__list__item__link {
                    color: var(--msv-nav-font-color);
                    justify-content: flex-start;
                    width: auto;
                    display: inline-block;
                }

                > .ms-nav__feature,
                > .ms-nav__deafult {
                    > .ms-nav__list {
                        width: $msv-width-threequarter;
                        display: flex;
                        justify-content: flex-start;
                        left: 0;
                        background: var(--msv-nav-bg);

                        > .ms-nav__list__item {
                            width: 33.3%;
                            display: inline-block;

                            > .ms-nav__list__item__link {
                                @include feature-link-dark-m();
                            }
                        }
                    }
                }

                > .ms-nav__deafult {
                    > .ms-nav__list {
                        width: $msv-width-full;
                        padding-left: $navigation-ms-nav-list-item-nav-feature-tablet-padding-left;

                        >.ms-nav__list__item {
                            padding-bottom: 25px;
                        }
                    }
                }

                > .ms-nav__feature {
                    display: inline-flex;
                    padding-left: $navigation-ms-nav-list-item-nav-feature-tablet-padding-left;
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__link {
                    display: flex;
                    padding-left: $navigation-ms-nav-list-item-link-tablet-padding-zero;
                    padding-right: $navigation-ms-nav-list-item-link-tablet-padding-zero;
                    justify-content: center;
                    line-height: $navigation-menu-item-line-height;
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover, &:focus {
                        background: transparent;
                        text-decoration: none;
                        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                        &::before {
                            width: $msv-width-full;
                        }
                    }
                }

                > .ms-nav__list__item__button:first-child {
                    margin-left: $navigation-ms-nav-list-item-button-first-child-tablet-margin-left;
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list {
                    border: 1px solid var(--msv-nav-dropdown-border);
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    .ms-nav__list__item__link {
                        @include font-body-regular-m();
                        color: var(--msv-nav-font-color);
                    }
                }
            }
        }

        &.ms-nav__disable-top-menu {
            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            > .ms-nav__list > .ms-nav__list__item {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__button, > .ms-nav__list__item__link {
                    @include vfi();
                    @include font-heading-h4-m();
                    align-items: flex-end;
                    background-color: var(--msv-nav-bg);
                    border: none;
                    color: var(--msv-nav-font-color);
                    display: flex;
                    justify-content: center;
                    position: relative;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin-bottom: $navigation-ms-nav-list-item-link-button-tablet-margin-bottom;
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &::before {
                        right: 0;
                        left: 0;
                        content: "";
                        outline-offset: 0;
                        width: 0%;
                        visibility: hidden;
                    }
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover, &:focus {
                        background: transparent;
                    }
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover {
                        @include font-heading-h3-m();
                        cursor: pointer;
                    }
                }

                > .ms-nav__list__item__button[aria-expanded="true"],
                > .ms-nav__list__item__link[aria-expanded="true"] {
                    @include font-heading-h3-m();
                }

                > .ms-nav__feature {
                    > .ms-nav__list > .ms-nav__list__item {
                        width: auto;
                        padding-right: $navigation-ms-nav-list-item-link-disable-top-menu-padding-right;
                        padding-bottom: $navigation-ms-nav-list-item-link-disable-top-menu-padding-bottom;

                        @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                            width: 33.3%;
                        }

                        .ms-nav__list__item__link {
                            @include feature-link-m();
                            height: auto;
                            letter-spacing: normal;

                            &::before {
                                border-radius: 10px;
                            }
                        }

                        &.havesubmenu .ms-nav__deafult {
                            .ms-nav__list {
                                .ms-nav__list__item__link {
                                    @include font-body-regular-m();
                                    color: var(--msv-nav-font-color);
                                    margin-bottom: $navigation-ms-nav-list-item-link-level2-desktop-margin-bottom;
                                    border-bottom: none;

                                    @include vfi();

                                    &::before {
                                        visibility: hidden;
                                    }

                                    &:hover {
                                        text-decoration: underline;
                                    }

                                    &:hover::before {
                                        visibility: hidden;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ms-nav__drawer-open {
        @include add-icon($msv-ChevronUp, after);
        color: var(--msv-nav-font-color);
        padding-right: $navigation-ms-nav-drawer-open-close-tablet-padding;

        &::after {
            font-size: $msv-icon-size-m;
            vertical-align: middle;
        }
    }

    .ms-nav__drawer-close {
        @include add-icon($msv-ChevronDown, after);
        color: var(--msv-nav-font-color);
        padding-right: $navigation-ms-nav-drawer-open-close-tablet-padding;

        &::after {
            font-size: $msv-icon-size-m;
            vertical-align: middle;
        }
    }

    .drawer__glyph:focus {
        outline: none;
    }
}

@media screen and (min-width: $msv-breakpoint-l) {
    .ms-nav {
        display: flex;

        &__list {
            position: absolute;

            &.level-2 {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                left: 0;
                background: var(--msv-nav-bg);
                width: 80%;

                .ms-nav__list {
                    position: relative;
                    margin-left: $navigation-ms-nav-list-desktop-margin-left;

                    .ms-nav__list__item {
                        @include font-body-regular-m();
                        color: var(--msv-nav-font-color);
                        margin-left: $navigation-ms-nav-list-item-desktop-margin-left;

                        .ms-nav__list {
                            margin-left: $navigation-ms-nav-list-level2-desktop-margin-left;
                        }
                    }
                }

                .level-2 {
                    display: block;
                }
            }

            &__mobile__container {
                display: none;
            }
        }

        .category-image-container {
            width: 20%;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        > .ms-nav__list {
            display: flex;
            flex-wrap: nowrap;
            outline: none;
            position: relative;
            width: $navigation-ms-nav-list-width;
            padding-left: $navigation-ms-nav-list-desktop-padding-left;

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .ms-nav__list {
                height: auto;

                .ms-nav__list__item__link {
                    @include font-body-regular-m();
                    color: var(--msv-nav-font-color);
                    margin-bottom: $navigation-ms-nav-list-item-link-desktop-margin-bottom;
                    display: inline-block;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 3px;
                        bottom: -8px;
                        left: 0;
                        background-color: $msv-blue;
                        visibility: visible;
                        transform: scaleX(1);
                        transition: all 0.2s ease-in-out;
                    }

                    &:hover::before {
                        visibility: visible;
                        transform: scaleX(1);
                        bottom: -4px;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .ms-nav__list {
                    .ms-nav__list__item__link {
                        @include font-body-regular-m();
                        color: var(--msv-nav-font-color);
                        margin-bottom: $navigation-ms-nav-list-item-link-level2-desktop-margin-bottom;
                        border-bottom: none;

                        @include vfi();

                        &::before {
                            visibility: hidden;
                        }

                        &:hover::before {
                            visibility: hidden;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            > .ms-nav__list__item:first-child {
                margin-left: $navigation-ms-nav-list-item-first-child-desktop-margin-left;
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            > .ms-nav__list__item {
                margin: {
                    top: $navigation-parent-menu-margin-top;
                    left: $navigation-parent-menu-margin-left;
                    right: $navigation-parent-menu-margin-right;
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__button, > .ms-nav__list__item__link {
                    @include vfi();
                    @include font-heading-h4-l();
                    align-items: flex-end;
                    background-color: var(--msv-nav-bg);
                    border: none;
                    color: var(--msv-nav-font-color);
                    display: flex;
                    justify-content: center;
                    position: relative;
                    line-height: $navigation-menu-item-line-height;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin-bottom: $navigation-ms-nav-list-item-button-link-desktop-margin-bottom;
                    padding: 0;
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &::before {
                        right: 0;
                        left: 0;
                        content: "";
                        outline-offset: 0;
                        width: 0%;
                        visibility: hidden;
                    }
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover, &:focus {
                        background: transparent;
                        border: none;
                        outline: none;
                    }
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover {
                        cursor: pointer;
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__button {
                    &::after {
                        @include msv-icon();
                        color: var(--msv-nav-carot-color);
                        content: $msv-ChevronDown;
                        font-size: var(--msv-nav-font-size);
                        margin-left: $navigation-menu-button-icon-margin-left;
                        line-height: $navigation-menu-icon-line-height;
                        display: none;
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .ms-nav__list__item__link {
                    color: var(--msv-nav-font-color);
                    justify-content: flex-start;
                    width: auto;
                    display: inline-block;
                }

                > .ms-nav__deafult {
                    > .ms-nav__list {
                        padding-left: $navigation-ms-nav-default-nav-list-desktop-padding-left;

                        > .ms-nav__list__item {
                            width: 20%;
                        }
                    }
                }

                > .ms-nav__feature {
                    padding-left: $navigation-ms-nav-list-item-nav-feature-desktop-padding-left;
                    display: inline-flex;
                    padding-right: $navigation-ms-nav-list-item-nav-feature-desktop-padding-right;
                }

                > .ms-nav__feature,
                > .ms-nav__deafult {
                    > .ms-nav__list {
                        > .ms-nav__list__item {
                            width: 20%;
                            display: inline-block;
                            padding-right: $navigation-ms-nav-list-item-levle2-desktop-padding-right;

                            > .ms-nav__list__item__link {
                                @include feature-link-dark-m();
                            }
                        }
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__link {
                    display: flex;
                    padding-left: $navigation-ms-nav-list-item-link-desktop-padding-left;
                    padding-right: $navigation-ms-nav-list-item-link-desktop-padding-right;
                    justify-content: center;
                    line-height: $navigation-menu-item-line-height;
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover, &:focus {
                        background: transparent;
                        text-decoration: none;
                        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                        &::before {
                            width: $msv-width-full;
                        }
                    }
                }

                > .ms-nav__list__item__button:first-child {
                    margin-left: $navigation-ms-nav-list-item-button-first-child-desktop-margin-left;
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list {
                    border: 1px solid var(--msv-nav-dropdown-border);
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    .ms-nav__list__item__link {
                        @include font-body-regular-m();
                        color: var(--msv-nav-font-color);
                    }
                }
            }
        }
    }

    .ms-nav__drawer-open {
        @include add-icon($msv-ChevronUp, after);
        color: var(--msv-nav-font-color);
        padding-right: $navigation-ms-nav-drawer-open-desktop-padding-right;
    }

    .ms-nav__drawer-close {
        @include add-icon($msv-ChevronDown, after);
        color: var(--msv-nav-font-color);
        padding-right: $navigation-ms-nav-drawer-close-desktop-padding-right;
    }

    .drawer__glyph:focus {
        outline: none;
    }
}

@media (max-width: $msv-breakpoint-m) {
    .ms-nav {
        display: flex;
    }
}
