.msc-add-to-cart__dialog {
    max-width: 90% !important;
    margin: 0 auto !important;
    height: auto;

    /* drop shadow */
    box-shadow: 0px 4px 12px 0px rgba(163, 163, 163, 0.25);
    border-radius: 8px;
    background: #fff;
    @include breakpoint(md) {
        max-width: 600px !important;
    }
    .msc-modal__content {
        border-radius: 8px;
        max-width: 100% !important;
        width: 100% !important;
        padding: 4rem !important;
        .msc-modal__header {
            border-bottom: 1px solid #d0cfcd;
            padding-bottom: 2rem;
            margin-bottom: 3rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .msc-modal__close-button {
                position: relative;
                right: auto;
                // margin-bottom: -1.2rem;
                background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB46Wt');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 2.8rem;

                &:before {
                    font-size: 2.6rem;
                    content: '';
                }
            }
            .msc-modal__title {
                color: #1e1e1e;
                font-family: $font-family-primary;
                font-size: 2.2rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 1.28px;
                @include breakpoint(sm) {
                    font-size: 2.6rem;
                }
                @include breakpoint(md) {
                    font-size: 3.2rem;
                }
            }
        }
        .msc-modal__body {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            border-bottom: 1px solid #d0cfcd;
            padding-bottom: 3rem;
            margin-bottom: 3rem;
            > div {
                color: #1e1e1e;
                font-family: $font-family-primary;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.64px;
                text-align: left;
                // margin-bottom: 1rem;
                &:nth-child(odd) {
                    margin-bottom: 1.5rem;
                    font-weight: 400;
                }
                // &:last-child {
                //     font-size: 1.8rem;
                //     font-weight: 600;
                // }
            }

            .msc-add-to-cart__dialog__product-title {
                color: #1e1e1e;
                font-family: $font-family-primary;
                font-size: 2rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.8px;
                margin-bottom: 0;
                padding: 0;
            }
        }
        .msc-add-to-cart-total-amount{
            font-size: 1.8rem;
            font-weight: 600;
            margin-top: -15px;
            margin-bottom: 10px;
        }
        .msc-add-to-cart-festival-event{
            font-size: 2.4rem;
            font-weight: 600;
        }

        .msc-add-to-cart__dialog__footer.msc-modal__footer {
            gap: 2rem;
            button {
                &:first-child,
                &:last-child {
                    @include no-outline;
                    @include cta_styling($primary-yellow);
                    font-size: 1.6rem;
                    text-transform: capitalize;
                    border: none;
                    margin: 0;
                    padding: 10px 20px;
                    @include breakpoint(sm) {
                        width: auto !important;
                    }
                }
                &:last-child {
                    background: $primary-blue;
                    color: #fff;
                    &:hover {
                        background: $primary-blue;
                    }
                }
            }
            a {
                @include no-outline;
                @include cta_styling($primary-yellow);
                font-size: 1.6rem;
                text-transform: capitalize;
                border: none;
                margin: 0;
                padding: 10px 20px;
                // @include breakpoint(sm) {
                //     width: auto !important;
                // }
            }
        }
    }
}
//-----------------------------Remove extra Entry tickets headings--------------------------------//
// .msc-add-to-cart__dialog .msc-modal__content .msc-modal__body>div:not(:first-child) h3 {
//     background: yellow;
//     display: none;
// }




//-------------------------------------------------------------//
// .msc-flyout.show.ms-cart-icon__flyout-container {
//     box-shadow: 0px 4px 12px 0px rgba(163,163,163,0.25);
//     border-radius: 8px;
//     background: #fff;
//     width: 300px;
//     max-height: 100vh;
//     height: 86vh;
//     overflow-y: auto;
//     padding: 2rem;
//     margin-top: 190px;
//     margin-left: -70px;
//     z-index: 0;

// }

// .msc-flyout.show.ms-cart-icon__flyout-container.msc-bs-tooltip-top a {
//     color: red;
// }

// .msc-flyout.show.ms-cart-icon__flyout-container.msc-bs-tooltip-top button {
//     background: yellow;
// }

// .msc-cart-line__product-variants {
//     color: black;
// }

// .msc-cartline__product-unit-of-measure {
//     color: green;
// }

// .msc-cart-line__quantity.single-quantity {
//     color: blue;
// }

// .msc-cart-line__product-price {
//     color: brown;
// }
