.arabic {
    * {
        font-family: $arabic-font-family !important;
        --webkit-font-family: 'Cairo', sans-serif !important;
        --moz-font-family: 'Cairo', sans-serif !important;
        letter-spacing: 0 !important;
    }
    // Promo banner
    .ms-promo-banner__text {
        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB3rjR?pubver=0');
        background-repeat: no-repeat;
        background-position: 99% center;
        padding-right: 5rem;
        padding-left: 1rem;
        background-size: 3.3rem;
        @include breakpoint(md1) {
            background-size: contain;
        }
    }
    // video gallery //
    .image-gallery {
        [class^='col-'] {
            .ms-video-player {
                .ms-video-player__details {
                    .ms-video-player__details__cta__link {
                        padding: 0 10px 0 5rem;
                        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2j4Z?pubver=1');
                        background-repeat: no-repeat;
                        background-position: 15% center;
                        background-size: 18px;
                    }
                }
            }
        }
        .msc-videoplayer__controls__muteunmutebtn__container {
            left: auto !important;
            right: 16px !important;
        }
    }

    .image-gallery h1:before {
        left: auto;
        // right: 35%;
        @include breakpoint(md1) {
            right: auto;
            left: auto;
        }
    }
    //   // ---------------------menu dropdown icon------------------------------------------------------------

    .ms-nav__list__item__button {
        margin-right: 2px;
        @include breakpoint(xxl) {
            margin-left: 20px;
        }
    }
    .ms-nav__list__item__button::before {
        right: auto !important;
        // top: 5px !important;
        left: -4px !important;

        @include breakpoint(md1) {
            right: auto !important;
            left: -1.7rem !important;
        }
        @include breakpoint(lg) {
            right: auto !important;
            left: -13px !important;
        }
    }
    header .ms-header__container > .ms-nav > .ms-nav__list > .ms-nav__list__item:nth-child(2) {
        margin-right: 0;
        @include breakpoint(md1) {
            margin-right: 1rem !important;
        }
    }
    header .ms-header__container > .ms-nav > .ms-nav__list > .ms-nav__list__item {
        &:last-child,
        &:nth-last-child(2),
        &:nth-last-child(3),
        &:nth-last-child(4) {
            a {
                font-weight: bold;
            }
        }
    }

    //   // ---------------------menu dropdown icon------------------------------------------------------------

    // photo gallery
    .photo-gallery-row .szf-photo-card-heading button {
        background-position: 13rem center;
        min-width: 17rem;
    }
    // -------------------- org sponsorship
    .org-sponsorship-content {
        * {
            text-align: right !important;
        }
        .ms-content-block__details {
            h2 {
                &:before {
                    right: 0;
                }
            }
        }
    }
    // ----------------- Join us and heritage
    .join-us-row {
        .heritage-container,
        .join-us-container {
            h2 {
                &:before {
                    right: 0;
                }
            }
            .ms-content-block__image {
                left: 0;
                right: auto;
            }
        }
    }
    // --------------------------Image Gallery
    .book-tickets-now-container {
        .book-tickets-now {
            .ms-content-block__details {
                .ms-content-block__title {
                    &:before {
                        left: auto;
                        right: 20%;
                    }
                }
            }
        }
    }
    .image-gallery [class^='col-'] .image-gallery-col1 > .row > .col-12:first-child {
        padding-left: 0;
    }

    .image-gallery [class^='col-'] .image-gallery-col1-row2 .row .col-12:nth-child(2) {
        padding-right: 0;
    }
    .image-gallery [class^='col-'] .image-gallery-col1-row2 .row {
        justify-content: flex-end;
    }
    // .image-gallery [class^='col-'] .image-gallery-col1-row2 .row .col-12:first-child {
    //     padding-right: 6px;
    //     padding-left: 0;
    // }
    // ------------------------------Header

    // ------------------------------Footer
    .footer_szf {
        .ms-footer__list {
            .ms-footer__list-item {
                .ms-subscribe form {
                    input {
                        margin-right: 0px;
                        margin-left: 0px;
                        @include breakpoint(md) {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
        ul.szf_footer_links {
            li {
                &:before {
                    left: auto;
                    right: -12px;
                }
            }
        }
        .ms-footer__list {
            text-align: right;
            .ms-footer__list-item {
                .szf_footer_links {
                    li {
                        &:first-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        > .row {
            > .col-12 {
                &:nth-child(1) {
                    @include breakpoint(md) {
                        border-right: 0;
                        border-left: 1px solid #fff;
                    }
                }
                &:nth-child(2) {
                    @include breakpoint(md) {
                        border-right: 0;
                        border-left: 0;
                        justify-content: start;
                        padding: 0 2rem;
                        @include breakpoint(md1) {
                            padding: 0 2rem;
                            border-left: 1px solid #fff;
                        }
                    }
                }
                &:nth-child(3) {
                    @include breakpoint(md) {
                        border-right: 0;
                        border-left: 1px solid #fff;
                    }
                }
                &:nth-child(4) {
                    @include breakpoint(md) {
                    }
                }
            }
        }
        .ms-footer__list .ms-footer__list-item .rights-text h5 {
            padding-right: 0;
            padding-left: 2rem;
        }
    }

    //-------------------------------- Plan your Visit
    .info-cards-list {
        > .row {
            > .col-12 {
                &:nth-child(4) {
                    .ms-content-block {
                        .ms-content-block__details {
                            * {
                                text-align: right !important;
                            }
                        }
                    }
                }
            }
        }
    }
    //Bus Timings
    .bus-main-container {
        .bus-header {
            .ms-content-block {
                .ms-content-block__cta {
                    left: 0;
                    right: auto;
                }
            }
        }
    }
    // Fees
    .fees-container {
        h2 {
            &:before {
                content: '';
            }
        }
    }
    // -----------------------------Floating labels
    .form-floating > label {
        right: 0;
        left: auto;
    }

    // ---------------------------------------Checkbox
    .checkmark {
        right: 0;
        left: auto;
    }
    .custom-checkbox {
        padding-right: 3.5rem;
        padding-left: 0;
    }
    // ---------------------------------Form fields icons
    .contact-left {
        form {
            input[type='file'] {
                background-position: 1% !important;
                padding-left: 30px;
                padding-right: 0.75rem;
            }
            select {
                padding-left: 23px !important;
                padding-right: 0.75rem !important;
            }
            .in-contact-page {
                .input-group {
                    label {
                        left: auto;
                        right: 0;
                    }
                }
            }
            .phone-group {
                label {
                    left: auto;
                    right: 0.7rem;
                }
            }
        }
    }
    .form-floating > .form-control {
        line-height: 35px !important;

        @include breakpoint(sm) {
            line-height: 41px !important;
        }

        @include breakpoint(xxl) {
            line-height: 48px !important;
        }
    }
    .form-select {
        background-position: left 0.75rem center;
    }
    // File
    .contact-us-file-formgroup,
    .business-op-tradeLicenseFile-formgroup {
        label {
            left: auto;
            right: 0.9rem;
            margin-right: 0;
            margin-left: 4rem !important;
        }
    }
    // Arabic Error Icons
    .form-control.is-invalid,
    .was-validated .form-control:invalid {
        background-position: left calc(0.375em + 0.1875rem) center;
    }

    .form-select.is-invalid:not([multiple]):not([size]),
    .form-select.is-invalid:not([multiple])[size='1'],
    .was-validated .form-select:invalid:not([multiple]):not([size]),
    .was-validated .form-select:invalid:not([multiple])[size='1'] {
        background-position: left 0.75rem center, center left 2.25rem;
    }

    // heading -contact us
    .form-with-details {
        .contact-right {
            > .row {
                h2 {
                    &:before {
                        right: 0 !important;
                        left: auto !important;
                    }
                }
            }
        }
    }

    .contact-us-file-formgroup,
    .business-op-tradeLicenseFile-formgroup {
        input {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0px !important;
        }
        button {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }
    }
    // Email
    input[type='email'] {
        direction: ltr;
        text-align: right;
    }
    // Phone Ui

    .phone-group,
    .form-floating {
        .react-tel-input {
            input {
                padding-right: 58px !important;
                direction: ltr;
                padding-left: 20px !important;
                text-align: right;
            }
            ~ label {
                margin-right: 0.7rem;
            }

            .flag-dropdown {
                border-radius: 0 3px 3px 0;
                .selected-flag {
                    padding: 0 7px 0 0;
                    .arrow {
                        right: 20px !important;
                        left: auto;
                    }
                }
                .country-list {
                    .search {
                        span {
                            right: 20px;
                            left: auto;
                        }
                    }
                    .country-name {
                        margin-left: 6px;
                    }
                }
            }
        }
    }
    .partner-card-container,
    .overview-container .overview-header {
        h4,
        p {
            text-align: right !important;
        }
    }

    // -----------------------Terms and Conditions
    .terms-container {
        direction: ltr;
    }
    // Detail page images
    .press-images {
        direction: ltr;
    }
    .szf-category-details__image-container {
        direction: ltr;
    }
    // Detail Page content
    .szf-category-details__main-container {
        .szf-category-details__content {
            h2 {
                text-align: right;
            }
        }
    }
    // Detail page schedule
    .szf-category-details__event-schedule-container {
        .szf-category-details__event-schedule {
            p {
                &:last-child {
                    direction: ltr;
                }
            }
        }
    }

    // Press release

    .press-release-list {
        .default-container {
            > .row {
                > .col-12 {
                    .ms-text-block {
                        h5 {
                            background-position: right 1px;
                            padding-right: 30px;
                            direction: ltr;
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
    // ---------------------------header
    header {
        .ms-header__container {
            .ms-header__topbar {
                @include breakpoint(xxxl) {
                    width: 12%;
                }
            }
            > .ms-nav {
                @include breakpoint(xxxl) {
                    width: 88%;
                }
                > .ms-nav__list {
                    > .ms-nav__list__item {
                        > .ms-nav__list__item__button,
                        > .ms-nav__list__item__link,
                        > .ms-nav__list__item__span {
                            @media screen and (max-width: 1550px) {
                                font-size: 1.3rem;
                                // font-weight: bold;
                            }
                            @media screen and (max-width: 991px) {
                                font-size: 1.45rem;
                            }
                            @media screen and (min-width: 1340px) and (max-width: 1476px) {
                                font-size: 1.2rem;
                            }
                            @media screen and (min-width: 1130px) and (max-width: 1250px) {
                                font-size: 1.15rem;
                            }
                        }
                        &:nth-last-child(4) {
                            margin-left: 8px;
                            @include breakpoint(md1) {
                                margin-right: auto !important;
                            }

                            @media screen and (min-width: 991px) and (max-width: 1129px) {
                                margin-right: 24rem !important;
                            }
                            // @media screen and (min-width: 767px) and (max-width: 901px) {
                            //     margin-right: 10px !important;
                            // }
                        }
                    }
                }
            }
        }
    }

    header {
        .ms-header__container {
            > .ms-nav {
                > .ms-nav__list {
                    > .ms-nav__list__item {
                        &:last-child {
                            @include breakpoint(md1) {
                                margin-right: 10px !important;
                                margin-left: 0;
                            }
                        }
                        &:nth-last-child(2) {
                            margin-left: 0 !important;
                        }
                    }
                }
            }
        }
    }

    // Mobile Header
    .ms-nav__list__mobile__container__button {
        &::before {
            transform: rotate(180deg);
        }
    }
    .ms-nav {
        > .ms-nav__list {
            > .ms-nav__list__item {
                > .ms-nav__list__item__button {
                    &:after {
                        content: url(https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBsCG?pubver=1) !important;
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }
    .ms-nav__list__item {
        .ms-nav__list {
            .ms-nav__list__item__button {
                &:after {
                    content: url(https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBsCG?pubver=1) !important;
                    transform: rotate(90deg);
                }
            }
        }
    }

    // ------------------- BO landing page
    .bo-stats {
        > .row {
            > .col-12 {
                .ms-content-block {
                    .ms-content-block__details {
                        h2 {
                            padding-left: 0;
                            padding-right: 20px;
                        }
                    }
                }
            }
        }
    }
    // -------------------Bo form
    // multiselect

    .multiSelect-container-div {
        background-position: left 0.75rem center;
        .multiSelectContainer {
            .searchWrapper {
                text-align: right;
                .closeIcon {
                    margin-left: 0;
                    margin-right: 5px;
                }
            }
        }
        label {
            right: -1rem;
            left: auto;
            transform: scale(0.85) translateY(-2rem) translateX(0.75rem) !important;
        }
    }
    .govt-category {
        label {
            right: 0.4rem;
        }
    }
    .sponsor-category {
        label {
            right: 1rem;
        }
    }
    .optionListContainer {
        ul {
            li {
                text-align: right;
            }
        }
    }
    // Banner overlay

    .secondary-banner,
    .main-promotion,
    .szf-plp-banner__content {
        background: $arabic-banner-overlay !important;
    }

    // ------------
    // .arabic.has-main-carousel {
    //     .main-promotion {
    //         .ms-content-block {
    //             background: $banner-overlay;
    //         }
    //     }
    // }
    //  --------------------------------Social Links
    .social_links_container {
        direction: ltr;
    }

    // ----------------------------------site picker
    .ms-site-picker {
        direction: ltr;

        button {
            background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2tGl?pubver=0');
            background-repeat: no-repeat;
            background-size: 3.2rem;
            background-position: center;
        }

        // ----------------------------------
        .ms-site-picker__list li {
            a {
                font-family: $font-family-primary !important;
                span {
                    font-family: $font-family-primary !important;
                }
            }
        }
    }
    // ----------------------------Al wathba nights
    .product-list-page__festivals__al-wathba-nights,
    .product-list-page__festivals__layali-alwathba {
        .festival-activities-item__date {
            p {
                direction: ltr;
                text-align: left;
            }
        }
    }
    //------------------Festival activities--------------------------//
    .product-list-page__festivals {
        &__festival-activities,
        &__events-and-shows,
        &__daily-events,
        &__festival-attractions,
        &__competitions,
        &__tefan-lawl,
        &__the-emarati-civilization-and-culture-zone {
            .festival-activities-item {
                .msc-product {
                    h2 {
                        text-align: right;
                    }
                }
                .msc-product__desc {
                    text-align: right;
                }
            }
        }
    }

    .festival-activities {
        .festival-activities-content {
            .festival-activities-item {
                .msc-product {
                    text-align: right;
                    direction: rtl;
                    h2 {
                        text-align: right;
                    }
                }
                .festival-activities-item__desc {
                    * {
                        text-align: right;
                    }
                }
            }
        }
    }
    // Book tickets now page//
    .ms-buybox {
        .ms-buybox__content {
            .szf-title-with-price {
                right: 2.4rem;
                left: auto;
                @include breakpoint(md) {
                    right: 5rem;
                    left: auto;
                }

                .ms-buybox__product-title {
                    text-align: right;
                    margin: 0 0 0 10px;
                }
            }
            .ms-buybox__add-to-cart-container {
                .msc-add-to-cart {
                    @include breakpoint(sm) {
                        width: auto;
                    }
                }
            }
            .ms-buybox__quantity {
                label {
                    left: auto;
                    right: 10px;
                }
            }
        }
    }
    .photo-video-gallery {
        .gallery-btns-div {
            button {
                span {
                    text-align: right;
                }
                .btn-img {
                    background-position: right center;
                }
                p {
                    text-align: left;
                    @include breakpoint(md) {
                        text-align: right;
                    }
                }
            }
        }
    }

    // ---------------------------Mini Cart ------------------------//
    .msc-add-to-cart__dialog {
        .msc-modal__header {
            .msc-modal__close-button {
                right: auto;
                left: 20px;
            }
        }
    }
    // --------------------------- Cart Icon ------------------------//

    .msc-cart-icon {
        .msc-cart-icon__count {
            right: auto;
            left: 0;
        }
    }
    // --------------------------- Side Cart ------------------------//

    .ms-cart-icon__flyout-container.show {
        .msc-flyout__close-button {
            right: auto;
            left: 20px;
        }
        .msc-flyout-inner {
            .ms-cart-icon__cart-lines {
                .msc-cart-line__quantity {
                    label {
                        margin-right: 0 !important;
                        margin-left: 4px !important;
                        margin-bottom: 0 !important;
                    }
                }
                .msc-price.discount-value {
                    .msc-price__strikethrough {
                        margin-right: 0 !important;
                        margin-left: 5px !important;
                    }
                }
                .msc-cart-line__remove-item {
                    margin-right: 120px !important;
                    margin-left: 0 !important;
                }
            }
        }
    }

    // -------------------------------Checkout -----------------------------//
    .ms-checkout__main {
        @include breakpoint(md) {
            padding-left: 64px;
            padding-right: 0;
        }
    }

    .ms-checkout__body {
        .ms-checkout__guided-form {
            .ms-checkout__guided-card-header {
                button {
                    margin-right: auto;
                    margin-left: 0;
                }
            }
            .ms-checkout__guided-card-body {
                .ms-checkout__guided-card-content {
                    .form-floating > input[type='date'] {
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: space-between;
                        &::-webkit-datetime-edit {
                            /* max-width: 100px !important; */
                            justify-content: space-around;
                            display: flex;
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }
    }

    .ms-checkout__guided-card:nth-child(3) {
        > .ms-checkout__guided-card-body {
            .ms-checkout-section-container__item {
                .ms-text-block {
                    h5 {
                        padding-right: 2.5rem;
                        text-align: right;
                        padding-left: 1rem;
                        background-position: 99.9% center;
                    }
                }
            }
        }
    }
    .was-validated .form-control:invalid,
    .form-control.is-invalid {
        padding-left: calc(1.5em + 0.75rem) !important;
        padding-right: 0.75rem !important;
    }
    .msc-order-summary__value {
        float: left;
    }

    .ms-order-confirmation__order-summary-price {
        float: left;
    }
    .msc-order-summary-wrapper {
        > .msc-order-summary__items {
            .msc-order-summary__label {
                float: right;
            }
        }
    }
    // ----------------------------tickets UI ---------------------------------//
    .ms-order-confirmation {
        .order-confirmation-header {
            .back-img-div {
                transform: rotate(180deg);
            }
        }
        .szf-ticket-QR-code-container {
            > p {
                text-align: right;
            }
        }
        &__order-summary-heading {
            text-align: right;
        }
        p,
        span {
            text-align: right;
        }
    }
    .ms-order-confirmation {
        .szf-ticket-QR-code-container {
            .szf-order-confirmation-ticket-card,
            .ticket-front-side {
                direction: ltr;
                // * {
                //     font-family: 'Roboto' !important;
                // }
            }
            .front-ticket-footer {
                p {
                    text-align: left !important;
                    font-family: $font-family-primary !important;
                }
            }
            .printed-tickets-container {
                .single-ticket {
                    .ticket-front-side {
                        .front-ticket-header {
                            .pass-day {
                                .english-pass-day {
                                    p {
                                        font-family: $font-family-primary !important;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .szf-order-confirmation-ticket-card.ticket-back-side {
                .ticket-right-upper-section-text {
                    .english-heading-text {
                        .p1,
                        .p2 {
                            font-family: $font-family-primary !important;
                        }
                    }
                }
            }
        }
    }
    //  -------------------------------------------------------- Cart Page --------------------------------------------------------//

    .ms-cart {
        .msc-cart-lines-group {
            @include breakpoint(md1) {
                margin-left: 21px;
                margin-right: 0;
            }

            .msc-cart-lines-group-wraper {
                .msc-cart-lines-item {
                    .msc-cart-line {
                        .msc-cart-line__content {
                            .msc-cart-line__product {
                                .msc-cart-line__product-variants {
                                    .msc-cart-line__product-variant-item {
                                        text-align: right;
                                        .msc-cart-line__product-variant-size {
                                            span {
                                                margin-right: 4px;
                                                margin-left: 0px;
                                            }
                                        }
                                    }
                                }
                            }
                            .msc-cart-line__product-price {
                                text-align: left;
                            }
                            .msc-cart-line__quantity {
                                .quantity-value {
                                    margin-left: 0;
                                    margin-right: 4px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .msc-order-summary-wrapper .msc-order-summary__heading {
            padding: 31px 31px 20px 0;
        }
        .msc-cart-line-item-product-discount {
            text-align: right !important;
        }
        .msc-order-summary-wrapper .msc-cart__btn-checkout,
        .msc-order-summary-wrapper .msc-cart__btn-guestcheckout,
        .msc-order-summary-wrapper .msc-cart__btn-backtoshopping {
            margin-left: 10px;
            margin-right: 0;
        }
    }

    //  -------------------------------------------------------- Checkout Page --------------------------------------------------------//
    .ms-checkout__body
        .ms-checkout__guided-form
        .ms-checkout__guided-card-body
        .ms-checkout__guided-card-content
        .ms-checkout-delivery-options
        .ms-checkout-delivery-options__list
        .ms-checkout-delivery-options__option
        .ms-checkout-delivery-options__description {
        margin-left: 0;
        margin-right: 15px;
    }
    .ms-checkout__side {
        .ms-checkout__line-items.multiple-pickup-enabled {
            .ms-checkout__line-items-delivery-group.ship {
                .ms-checkout__line-items-group-title {
                    text-align: right;
                }
                .ms-checkout__line-items-list {
                    .ms-checkout__line-item {
                        .msc-cart-line {
                            .msc-cart-line__content {
                                .msc-cart-line__product {
                                    @media screen and (min-width: 767px) and (max-width: 991px) {
                                        margin-left: 10px;
                                        margin-right: 0 !important;
                                    }

                                    .msc-cart-line__product-variants {
                                        text-align: right;
                                        .msc-cart-line__product-variant-size {
                                            .name {
                                                margin-left: 0px;
                                                margin-right: 5px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ms-checkout__line-items-delivery-group .msc-cart-line__content {
        margin-left: 0;
        margin-right: 20px;
    }
    .ms-checkout__main-control .ms-checkout__btn-place-order {
        margin-left: 0px;
        margin-right: 10px;
        @media screen and (min-width: 767px) and (max-width: 991px) {
            margin-right: 0;
        }
    }
    .ms-checkout__side .ms-checkout__side-control-second .ms-checkout__btn-place-order {
        margin-right: 0 !important;
        margin-left: 5px;
    }
    .ms-checkout__error-message {
        background-position: 98% center;
        padding-left: 1rem !important;
        padding-right: 3rem !important;
        padding-right: 1rem;
        text-align: right;
    }

    .ms-checkout__guided-card:nth-child(3) {
        .ms-checkout-payment-instrument__error-message {
            background-position: 99.9% center;
            text-align: right;
            padding: 0 4rem 0 1rem;
        }
        .payment-method-retrieval-error {
            p {
                background-position: 99% center;
                padding: 0 2.5rem 0 1rem;
                text-align: right;
            }
        }
    }

    .ms-checkout__side
        .ms-checkout__line-items.multiple-pickup-enabled
        .ms-checkout__line-items-delivery-group.ship
        .ms-checkout__line-items-list
        .ms-checkout__line-item
        .msc-cart-line
        .msc-cart-line__content
        .msc-cart-line__product
        .msc-cart-line__product-title {
        text-align: right;
    }
    .ms-checkout__line-items-delivery-group .msc-cart-line__content .msc-cart-line__product-price .msc-price__strikethrough {
        margin-left: 10px;
        margin-right: 0px;
    }
    .ms-checkout__line-items-delivery-group .msc-cart-line__content .msc-cart-line__quantity .quantity-label {
        margin-left: 5px;
        margin-right: 0px;
    }
    .ms-order-confirmation .order-confirmation-header h3 {
        text-align: right;
    }

    .map-block {
        .ms-content-block__details {
            .ms-content-block__cta {
                a {
                    &:first-child,
                    &:last-child {
                        padding: 10px 6rem 10px 20px;
                        background-position: 92% center;
                    }
                }
            }
        }
    }

    .food-container {
        .ms-video-player {
            .ms-video-player__details {
                .ms-video-player__details__title {
                    background-position: right;
                    padding-right: 3rem;
                    text-align: right !important;
                }
                .ms-video-player__details__text p {
                    text-align: right !important;
                }
            }
        }
    }
}

// ---------------------------All partners------------------------------//
.arabic.all-partners,
.arabic.dining-corner {
    //-------------------------Has only 3 child-----------------------------//
    .slick-track > div:nth-child(1):nth-last-child(3) .ms-content-block {
        @media screen and (max-width: 1400px) {
            margin: 60px auto 30px;
        }
        @media screen and (max-width: 1199px) {
            width: 30rem;
        }
        @media screen and (max-width: 991px) {
            width: 22rem;
        }
    }
    .slick-track > div:nth-child(2):nth-last-child(2) .ms-content-block {
        width: 100%;
        margin: 60px -24% 30px 0;
        @media screen and (max-width: 1700px) {
            margin: 60px -16% 30px 0;
        }
        @media screen and (max-width: 1500px) {
            margin: 60px -7% 30px 0;
        }
        @media screen and (max-width: 1400px) {
            margin: 60px auto 30px;
        }
        @media screen and (max-width: 1199px) {
            width: 30rem;
        }
        @media screen and (max-width: 991px) {
            width: 22rem;
        }
    }
    .slick-track > div:nth-child(3):nth-last-child(1) .ms-content-block {
        width: 100%;
        margin: 60px -52% 30px 0;
        @media screen and (max-width: 1700px) {
            margin: 60px -37% 30px 0;
        }
        @media screen and (max-width: 1500px) {
            margin: 60px -20% 30px 0;
        }
        @media screen and (max-width: 1400px) {
            margin: 60px auto 30px;
        }
        @media screen and (max-width: 1199px) {
            width: 30rem;
        }
        @media screen and (max-width: 991px) {
            width: 22rem;
        }
    }
    //-------------------------Has only 2 child-----------------------------//
    .slick-track > div:nth-child(1):nth-last-child(2) .ms-content-block {
        //  margin: 60px auto 30px;
        @media screen and (max-width: 1400px) {
        }
        @media screen and (max-width: 1199px) {
            width: 30rem;
        }
        @media screen and (max-width: 991px) {
            width: 25rem;
        }
        @media screen and (max-width: 767px) {
            margin: 60px auto 30px;
            width: 26rem;
        }
    }
    .slick-track > div:nth-child(2):nth-last-child(1) .ms-content-block {
        width: 100%;
        margin: 60px -50% 30px 0;
        @media screen and (max-width: 1700px) {
            margin: 60px -44% 30px 0;
        }
        @media screen and (max-width: 1500px) {
            margin: 60px -40% 30px 0;
        }
        @media screen and (max-width: 1400px) {
            margin: 60px -24% 30px 0;
        }
        @media screen and (max-width: 1199px) {
            width: 30rem;
        }
        @media screen and (max-width: 991px) {
            width: 25rem;
        }
        @media screen and (max-width: 767px) {
            margin: 60px auto 30px;
            width: 26rem;
        }
    }
}

.arabic.has-main-carousel {
    .main-promotion {
        background: transparent !important;
        .ms-content-block {
            background: $arabic-banner-overlay !important;
        }
    }
}

// Hide cart
// .ms-cart-icon,
// a.msc-cart-icon.msc-btn {
//     display: none !important;
//     width: 0;
//     height: 0;
//     opacity: 0;
// }
