.arabic {
    .competition-register-modal {
        .msc-modal__content {
            .msc-modal__body {
                form {
                    .phone-group {
                        label {
                            left: auto;
                            right: 0.7rem;
                        }
                    }
                }
            }
        }
    }
    .competition-register-modal {
        .msc-modal__content {
            .msc-modal__body {
                form {
                    .multiSelect-container-div {
                        label {
                            left: auto !important;
                            right: -1.8rem !important;
                        }
                    }
                    .multiSelectContainer {
                        input {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}
