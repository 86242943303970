// .ms-search-result-container__product-section {
.ms-search-result-wrapper-title-choice-summary {
    .ms-search-result-container__title {
        h2 {
            .ms-search-result__collection-title-prefix,
            .ms-search-result__collection-title-text {
                font-family: $font-family-primary !important;
                font-size: 3.2rem !important;
                line-height: 140% !important;
            }
            .ms-search-result__collection-title-count {
                font-family: $font-family-primary !important;
                font-size: 1.8rem !important;
                line-height: 140% !important;
            }
        }
    }
}
.ms-search-result-container__title {
    h2 {
        color: #1e1e1e !important;
        font-family: $font-family-primary;
        font-size: 2rem !important;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.28px;
        margin-bottom: 12px;
        @include breakpoint(md) {
            font-size: 2.2rem;
        }
    }
}
.ms-search-result-wrapper-sort-by-category {
    .ms-search-result-container__Sort-by-category {
        label {
            font-family: $font-family-primary;
            font-size: 1.8rem;
        }
        select {
            -moz-appearance: none; /* Firefox */
            -webkit-appearance: none; /* Safari and Chrome */
            appearance: none;
            background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB2bhN');
            content: '' !important;
            font-family: $font-family-primary;
            font-size: 1.6rem;
            background-repeat: no-repeat;
            background-position: 96%;
            background-size: 1.5rem;
            width: 100%;
            transition: all 0.3s ease-in-out;
            border: 1px solid #d1d1d1;
            border-radius: 4px;
            padding: 5px;
            &:focus {
                outline: none !important;
                // border: 0;
            }
           
        }
    }
}
// }

// Choice summary

.msc-choice-summary {
    margin-top:0;
    .msc-choice-summary__item {
        background: #0d2552;
        color: #fff;
        text-decoration: none;
        border-radius: 4px;
        padding: 3px 6px 5px 13px;
        font-family: $font-family-primary;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.28px;
        align-items: center;
        .msi-close-btn.msc-choice-summary__glyph {
            background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2m2E?pubver=0');
            height: 13px;
            width: 15px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            display: flex;
            justify-content: center;
            align-items: center;
            &:after{
                content:'';
            }
        }
    }
    > a {
        background-color: transparent !important;
        margin-top: 10px;
        font-family: $font-family-primary;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.28px;
        min-width: 9rem;
    }
}
// Choice summary
// .ms-search-result-wrapper-title-choice-summary {
//     .msc-choice-summary {
//         align-items: baseline;
//     }
// }
// Sort and Filter
#search-result-modal {
    color: #1e1e1e;
    font-family: $font-family-primary;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.28px;
}
.msc-modal__dialog.msc-search-result-modal {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 90vh;
    max-width: 90vw;
    .msc-modal__content {
        height: 100%;
        width: 100%;
        .msc-modal__header {
            .msc-modal__title {
                font-family: $font-family-primary;
                font-size: 3.2rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 1.28px;
            }
            .msc-modal__close-button {
                background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB46Wt');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                height: 2.5rem;
                width: 2.5rem;
                top:4rem;
                &:before {
                    content: '';
                }
            }
        }
    }
    .msc-review-modal-body {
        height: 100%;
        .ms-search-result-container__Sort-by-category {
            padding-bottom: 10px;
            border-bottom: 0;
            @include breakpoint(md1) {
                padding-bottom: 0px;
            }
            label {
                font-family: $font-family-primary;
                font-size: 1.8rem;
                margin-bottom: 0;
                font-weight: 400;
            }
            select {
                -moz-appearance: none; /* Firefox */
                -webkit-appearance: none; /* Safari and Chrome */
                appearance: none;
                background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB2bhN');
                content: '' !important;
                font-family: $font-family-primary;
                font-size: 1.6rem;
                background-repeat: no-repeat;
                background-position: 96%;
                background-size: 1.5rem;
                transition: all 0.3s ease-in-out;
                padding: 0 15px 0 5px;
                border: 1px solid #d1d1d1;
                border-radius: 4px;
                padding: 0px 5px;
                &:focus {
                    outline: none !important;
                    border: 0;
                }
            }
        }
    }
    .msc-modal__footer {
        .ms-sort-and-filter-modal-close.msc-btn {
            background-color: $primary-blue;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            width: 100%;
            height: 4.8rem;
            background: $primary-blue;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 24px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.4px;
            color: $white;
            transition: all 0.3s ease-in-out;

            &:hover {
                box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
            }
            // @include breakpoint(sm) {
            //     width: 200px;
            // }
        }
    }
}
// ========================== below 991px==========================
.ms-search-result-container__title .ms-search-result__collection-title-prefix,
.ms-search-result-container__title .ms-search-result__collection-title-text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 3rem;
    line-height: 140%;
}
