.product-list-page__festivals {
    &__festival-activities,
    &__events-and-shows,
    &__daily-events,
    &__festival-attractions,
    &__competitions,
    &__tefan-lawl,
    &__festivals-list,
    &__fun-fair-city,
    &__the-emarati-civilization-and-culture-zone {
        display: flex;
        flex-wrap: wrap;
        gap: 4rem;
        padding: 4rem 0;
        justify-content: center;
        .festival-activities-item {
            position: relative;
            display: flex !important;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 10px 10px 50px 10px;
            // height: 441px;
            background: #ffffff;
            box-shadow: 0px 4px 10px rgba(161, 159, 181, 0.25);
            border-radius: 20px;
            width: 88%;
            min-height: 45rem;
            // margin: 0 auto;
            @include breakpoint(sm) {
                width: 46%;
            }
            @include breakpoint(md1) {
                width: 30%;
            }
            @include breakpoint(xl) {
                width: 22%;
            }

            &:hover {
                .festival-activities-item__cta {
                    height: 45px;
                    color: #fff;
                    a {
                        color: $white;
                        visibility: visible;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            &__img {
                width: 100%;
                height: 292px;
                border-radius: 20px;
                overflow: hidden;
                transition: all 0.3s ease-in-out;
                &:hover {
                    img {
                        transform: scale(1.5);
                    }
                }
                img {
                    @include img-fit;
                    border-radius: 20px;
                    transition: all 0.3s ease-in-out;
                }
            }

            &__date {
                display: none;
            }
            .msc-product {
                display: flex !important;
                justify-content: center;
                align-items: flex-start;
                margin: 15px 0 10px;
                gap: 10px;
                width: 100%;
                .msc-product__img {
                    width: 2.6rem;
                    height: 2.6rem;
                    svg {
                        @include img-fit;
                    }
                }

                h2 {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.8rem;
                    line-height: 150%;
                    /* identical to box height, or 27px */

                    color: #122553;
                    text-align: left;
                    margin: 0;
                    text-transform: capitalize;
                    width: 90%;
                    @include text-ellipsis(2);
                }
            }
            &__desc,
            p {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 500;
                font-size: 1.2rem;
                line-height: 150%;
                color: #242d35;
                text-align: left;
                @include text-ellipsis(2);
            }
            &__cta {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                color: #fff;
                background: #fbad36;
                position: absolute;
                bottom: 0;
                left: 0;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                height: 45px;

                transition: height 0.3s ease-in-out;
                @include breakpoint(md1) {
                    height: 16px;
                }
                a {
                    visibility: hidden;
                    width: 100%;
                    text-decoration: none;
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 100%;
                    /* identical to box height, or 14px */

                    color: #ffffff;
                    display: flex;
                    justify-content: center;
                    visibility:visible ;
                    @include breakpoint(md1) {
                        visibility: hidden;
                    }
                }
                border: none;
            }
        }
    }
    &__competitions{
        .festival-activities-item {
            &__cta {
                display: none;
            }
            padding-bottom: 10px;
        }
    }
}
