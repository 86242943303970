.ms-search-result-container {
    margin-top: 5rem;
    &__category-nav-section {
    }
    &__category-hierarchy {
        a {
            color: #1e1e1e;
            font-family: $font-family-primary;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 1.28px;
            text-decoration: none;
        }
    }
    &__title result-count-category {
        h5 {
            color: #1e1e1e;
            font-family: $font-family-primary;
            font-size: 2rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 1.28px;
            margin-bottom: 12px;
        }
    }
}
