.raffles-content-container {
    padding:0 5rem !important;
    @include breakpoint(sm){
        padding:0;
    }
    .ms-content-block {
        .ms-content-block__title {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 3rem;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-transform: uppercase;
            color: $primary-blue;
            margin:3rem 0px 2rem;
            @include breakpoint(md){
            margin:6rem 0 3rem;
            font-size: 4rem;

            }
        }
        .ms-content-block__text {
            p {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 400;
                font-size: 2rem;
                line-height: 140%;
                /* or 28px */

                letter-spacing: -0.3px;

                color: $primary-blue;
            }
            ul {
                li {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 2rem;
                    line-height: 140%;
                    /* or 28px */

                    letter-spacing: -0.3px;

                    color: #122553;
                }
            }
        }
    }
}
