.ms-cart {
    margin-top: 8rem !important;
    margin-bottom: 4rem;
    .msc-order-summary-wrapper {
        .msc-order-summary__heading {
            margin-bottom: 0;
            border: 0.5px solid #c4c4c4;
            border-radius: 10px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            text-transform: capitalize;
            font-family: $font-family-primary;
            font-weight: bold;
        }
        .msc-order-summary__items {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: 0;
            .msc-promo-code-heading {
                font-family: $font-family-primary;
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0.04em;
                text-align: left;
                padding: 20px 31px 0;
            }
            .msc-promo-code__form-container {
                font-family: $font-family-primary;
                font-size: 1.8rem;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0.04em;
                text-align: left;
                padding: 4px 31px 0;
                .msc-promo-code__group {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    .msc-promo-code__input-box {
                        padding: 2px 10px;
                        width: 70%;
                        height: 3.5rem;
                    }
                    .msc-promo-code__apply-btn {
                        border: 1px solid $primary-blue;
                        height: 3.5rem;
                        background: $primary-blue;
                        font-size: 1.6rem;
                        padding: 2px 11px;
                        color: #fff;
                        width: 30%;
                        border: 1px solid $primary-blue;
                        opacity: 1;
                    }
                    .msc-promo-code__apply-btn.disabled {
                        opacity: 0.2;
                    }
                }
            }

            > p {
                font-family: $font-family-primary;
                font-size: 1.6rem;
                font-weight: bold;
                line-height: 140%;
                letter-spacing: 0.04em;
                text-align: left;
                padding: 25px 31px 0;
                margin-bottom: 0;
            }
            .msc-order-summary__line-sub-total {
                margin-bottom: 0;
         
            }
            .msc-order-summary__line-shipping {
                padding: 20px 31px 0;
                display: flex;
                justify-content: space-between;
                margin: 0;
                .msc-order-summary__value {
                    text-align: right;
                }
            }
        }
        .msc-order-summary__line-total {
            padding: 2rem 0 !important;
            font-weight: bold !important;
            margin-top: 10px;
        }

        .msc-cart__btn-checkout {
            @include cta_styling($primary-blue);
            // margin-top: 3rem;
            // @include breakpoint(sm){
            //     margin-top: 12px;
            // }
        }
        .msc-cart__btn-guestcheckout {
            @include cta_styling($primary-blue);
        }
        .msc-cart__btn-backtoshopping {
            @include cta_styling($primary-yellow);
            // background: #fff;
            // border: 1px solid $primary-blue;
            text-transform: capitalize;
        }

        .msc-cart__btn-checkout,
        .msc-cart__btn-guestcheckout,
        .msc-cart__btn-backtoshopping {
            display: inline-flex !important;
            margin-top: 12px;
            width: 100%;
            padding: 2px 4rem;
            margin-right: 10px;

            @include breakpoint(sm) {
                width: auto;
            }
            @include breakpoint(md1) {
                width: 100%;
            }
        }
    }

    // ------------------ hide guest-checkout and promo code ------------------------------//
    // .msc-cart__btn-guestcheckout,
    .msc-promo-code-heading,
    .msc-promo-code__form-container,
    .msc-promo-code__form-container ~ p {
        display: none;
    }
}
