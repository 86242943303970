html,
body {
    overflow-x: hidden;
}
html {
    font-size: 45%;
    @include breakpoint(sm) {
        font-size: 52.5%;
    }
    @include breakpoint(xxl) {
        /* 1rem = 10px */
        font-size: 62.5%;
    }
}
#main {
    // padding-top: $header-height;
}
.has-sec-banner {
    #main {
        // > div:nth-child(2) {
        //     background: $banner-overlay;
        //     display: flex;
        //     align-items: center;
        //     > div {
        //         min-height: 300px !important;
        //         height: 300px;
        //         // padding-top: $header-height;
        //         margin-top: -$header-height;
        //         > .row {
        //             height: 100%;
        //         }
        //     }
        // }
        > .is-sec-banner {
            background: $banner-overlay;
            display: flex;
            align-items: center;
            > div {
                min-height: 300px !important;
                height: 300px;
                // padding-top: $header-height;
                margin-top: -$header-height;
                > .row {
                    height: 100%;
                }
            }
        }
    }
}
// Arabic bg color
.arabic.has-sec-banner { 
        #main {
            // > div:nth-child(2) {
            //     background: $arabic-banner-overlay !important;
            // }
            > .is-sec-banner {
                background: $arabic-banner-overlay !important;
            }
        }
    }

option:checked {
    font-weight: bold;
}
option:nth-child(1) {
    font-weight: normal !important;
}
.msc-carousel__indicators {
    margin-bottom: 20px;
}
