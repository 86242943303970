.arabic {
    header {
        .ms-header__container {
            > .ms-nav {
                > .ms-nav__list {
                    > .ms-nav__list__item {
                        @media screen and (min-width: 1370px) {
                            margin-left: 2.4rem;
                        }

                        .ms-nav__deafult {
                            right: 200px;
                            left: auto;
                            li {
                                padding: 10px 0 0 10px;
                                margin: 0;
                                background: $primary_blue;
                                color: $white;

                                width: auto;
                                background-image: none;
                                padding: 0;
                                padding: 0;
                                @include breakpoint(md) {
                                    // background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2j4Z?pubver=1') !important;
                                    // background-repeat: no-repeat;
                                    // background-position: 95% 63% !important;
                                    width: 100% !important;
                                    padding: 10px 20px 0px 14px !important;
                                    // padding-right: 50px !important;
                                }
                                a,
                                button {
                                    padding-top: 0 !important;
                                    font-family: $arabic-font-family !important;
                                    text-align: right;
                                    padding-right: 0 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ms-header-open {
        background: $secondary_blue !important;
        overflow: auto;
        height: 100%;
        .ms-nav {
            background: transparent !important;
            display: flex !important;
            background: #08215c !important;

            > .ms-nav__list {
                padding: 0 20px;
            }
            .ms-nav__list {
                background: $secondary_blue;
                display: flex;
                flex-direction: column;
                height: 100vh;
                > .ms-nav__list__item {
                    margin: 0;
                    background: transparent !important;

                    // width: 100% !important;
                    // margin-left: 0 !important;
                    // margin-bottom: 0 !important;
                    // border-radius: 0 !important;
                    // --------------------
                    //   .ms-nav__list__item__link{
                    //         background-color: transparent !important;
                    //         padding:0;
                    //         margin:0;

                    //     }
                    //     .ms-nav__list__item__button{
                    //         margin-top: 0;
                    //     }
                    //     .ms-nav__list__item__span{

                    //     }
                    //     .ms-nav__deafult {
                    //         margin-bottom:20px;
                    //         ul{
                    //             background: transparent !important;
                    //             li {
                    //                 background: transparent !important;
                    //             a{
                    //                 background-color: transparent !important;
                    //                 padding:0;
                    //                 margin:0;

                    //             }
                    //             button{
                    //                 margin-top: 0;
                    //             }
                    //         }
                    //         }

                    //     }
                    // -------------------------
                    .ms-nav__list__item__button,
                    .ms-nav__list__item__link,
                    .ms-nav__list__item__span {
                        background-color: transparent;
                        background: transparent;
                        padding: 0;
                        margin: 0;
                        margin-bottom: 30px;
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 500 !important;
                        font-size: 14px;
                        line-height: 140%;
                        letter-spacing: -0.3px;
                        color: #ffffff;
                        text-decoration: none;
                    }

                    .ms-nav__list__item__button {
                        &:before {
                            background: none !important;
                        }
                    }
                }
            }
        }
        .ms-nav.parent {
            .ms-nav__list {
                > .ms-nav__list__item {
                    &:last-child,
                    &:nth-last-child(2),
                    &:nth-last-child(3) {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 14px !important;
                        background: $brand_dark_blue !important;
                        color: $white;
                        border-radius: 24px;
                        margin-bottom: 10px;
                        margin-top: 10px;
                        width: 170px;
                        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.35);
                        text-align: center;
                        span {
                            padding: 0;
                            margin-top: 0;
                            display: flex;
                            justify-content: center;
                            margin-bottom: 0;
                            font-weight: bold !important;
                        }
                        a {
                            font-weight: bold !important;
                            margin-bottom: 0;
                        }
                    }
                    &:nth-last-child(3) {
                        background: $primary_yellow !important;
                        margin-top: 10px;
                        span,
                        a {
                            color: $primary_blue;
                        }
                    }
                    &:nth-last-child(4) {
                        margin-top: 50px;
                       
                        a {
                            font-weight: bold !important;
                        }
                        
                    }
                    &:last-child {
                        background: $brand_beige !important;
                    }
                    &:first-child {
                        padding-top: 0;
                    }
                    &:nth-child(2) {
                        order: -1;
                        padding-top: 16px;
                    }

                    .ms-nav__deafult {
                        margin-bottom: 20px;
                        ul {
                            background: #1e1e1e !important;
                            li {
                                a {
                                    background-color: transparent;
                                    padding: 0;
                                    margin: 0;
                                    margin-left: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .ms-header__container {
            > .ms-nav {
                > .ms-nav__list {
                    > .ms-nav__list__item {
                        > .ms-nav__list__item__button.active,
                        > .ms-nav__list__item__link.active,
                        > .ms-nav__list__item__span.active {
                            position: relative;
                            &:after {
                                left: auto !important;
                                right: -10px;

                                border-top-left-radius: 0;
                                border-top-right-radius: 4px;
                                border-bottom-left-radius: 0;
                                border-bottom-right-radius: 4px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 1340px) and (max-width: 1476px) {
        header .ms-header__container > .ms-nav {
            width: 90% !important;
        }
        header .ms-header__topbar {
            width: 10% !important;
        }
        header .ms-header__container {
            padding: 0 1.7rem;
        }
    }
    
    // @media screen and (min-width: 1130px) and (max-width: 1142px) {

    // }
    @media screen and (min-width: 1130px) and (max-width: 1250px) {
        header .ms-header__container > .ms-nav > .ms-nav__list > .ms-nav__list__item:nth-last-child(3) {
            margin-right: 0 !important;
        }
        header .ms-header__container {
            padding: 0 1rem !important;
        }
        header .ms-header__container > .ms-nav {
            width: 90% !important;
        }
        header .ms-header__topbar {
            width: 10% !important;
        }
    }
}
.arabic {
    .ms-header-open {
        .ms-header__container {
            > .ms-nav {
                > .ms-nav__list {
                    > .ms-nav__list__item {
                        > .ms-nav__list__item__button.active,
                        > .ms-nav__list__item__link.active,
                        > .ms-nav__list__item__span.active {
                            position: relative;
                            &:before {
                                left: auto !important;
                                right: -11px !important;

                                border-top-left-radius: 0;
                                border-top-right-radius: 4px;
                                border-bottom-left-radius: 0;
                                border-bottom-right-radius: 4px;
                            }
                        }
                    }
                }
            }
        }
    }
}
