.szf-category-details__main-container {
    @include custom-container;
    .szf-category-details__image-container {
    }
    .szf-category-details__content {
        margin: 2rem auto;

        @include breakpoint(md1) {
            margin: 6rem auto;
        }
        h2 {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 3rem;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-transform: uppercase;
            color: $primary-blue;
            margin-bottom: 1rem;
            @include breakpoint(sm) {
                margin-bottom: 3rem;
                font-size: 4rem;
            }
        }
        .szf-category-details__description {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 400;
            font-size: 2rem;
            line-height: 140%;
            /* or 28px */
            text-align: justify;
            letter-spacing: -0.3px;
            color: #122553;
        }
        .szf-category-details__button-container {
            &:empty{
                margin: 0;
            }
            display: flex;
            gap: 3rem;
            margin-top: 5rem;
            flex-direction: column;
            @include breakpoint(sm) {
                flex-direction: row;
            }
            button {
                &:first-child {
                    border-radius: 24px;
                    border: none;
                    padding: 0;
                    a {
                        @include cta_styling($primary-yellow);
                        width: 100%;
                        border-radius: 24px;
                    }
                }
                &:last-child {
                    border-radius: 24px;
                    border: none;
                    padding: 0;
                    a {
                        @include cta_styling($primary-blue);
                        width: 100%;
                        border-radius: 24px;
                    }
                }
            }
        }
    }
}
