.competitions-list {
    @include custom-container;
    margin: 6rem auto;
    // @include breakpoint(md1) {
    //     margin-top: 4rem;
    // }
    > .row {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        row-gap: 4rem;
        @include breakpoint(sm) {
            column-gap: 1rem;
        }
        @include breakpoint(xl) {
            column-gap: 4rem;
        }
        justify-content: space-between;
        > .col-12 {
            width: 100%;
            @include breakpoint(sm) {
                width: 49%;
            }
            @include breakpoint(md1) {
                width: 31%;
            }

            .ms-content-block {
                background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%), url(.jpg), #ffffff;
                border-radius: 12px;
                position: relative;
                width: 100%;

                height: 30rem;
                overflow: hidden;
                margin: 0 auto; background: linear-gradient(360deg, #000 0%, rgba(0, 0, 0, 0) 100%);
                @include breakpoint(sm) {
                    height: 25rem;
                }

                .ms-content-block__image {
                    height: 100%;
                    img {
                        @include img-fit;
                    }
                }

               
                
                .ms-content-block__details {
                    position: absolute;
                    bottom: 0;
                    background: linear-gradient(360deg, #000 0%, rgba(0, 0, 0, 0) 100%);
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    padding: 24px;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        transform: scale(1.4);
                        background: linear-gradient(360deg, #000 0%, rgba(0, 0, 0, 0) 100%);
                        padding: 6rem 9rem 8rem 9rem;
                      
                        .ms-content-block__cta {
                            height: 4.5rem;
                        }
                    }

                    .ms-content-block__title {
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 2rem;
                        line-height: 150%;
                        color: #ffffff;

                        @include text-ellipsis(1);
                    }
                    .ms-content-block__text {
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 1.4rem;
                        line-height: 150%;
                        color: #ffffff;
                        @include text-ellipsis(1);
                    }
                }
                .ms-content-block__cta {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 0;
                    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB265a?pubver=1');
                    background-repeat: no-repeat;
                    background-position: right;
                    height: 3.8rem;
                    background-size: contain;
                    @include breakpoint(md){
                         height: 0;
                    }
                    a {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
}
