.org-sponsorship {
    background: $primary_blue;
    min-height: 340px;

    @media screen and (max-width: 991px) {
        padding: 20px 20px;
    }
    > .row {
        width: 90%;
        margin: 0 auto;
        @include breakpoint(sm) {
            width: 75%;
        }
        @include breakpoint(md1) {
            width: 90%;
        }
    }
    .col-12 {
        @media screen and (min-width: 991px) and (max-width: 1320px) {
            display: flex;
            align-items: center;
        }
        @media screen and (max-width: 991px) {
            display: flex;
            justify-content: center;
        }
    }
    .org-sponsorship-image {
        height: 100%;
        .ms-content-block__image {
            // width: 360px;
            // height: 340px;
            height: 100%;

            img {
                width: auto;
                object-fit: contain;
                object-position: center;
                height: 100%;
            }
        }
    }
    .org-sponsorship-content {
        padding: 30px 0 30px 0;

        @media screen and (max-width: 991px) {
            padding: 30px 0 0 0;
        }

        @media screen and (min-width: 1200px) and (max-width: 1450px) {
            margin-left: 30px;
        }
        @media screen and (min-width: 991px) and (max-width: 1199px) {
            margin-left: 30px;
        }
        .ms-content-block__image {
            display: none;
        }
        .ms-content-block__details {
            h2 {
                position: relative;
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 400;
                font-size: 3.4rem;
                line-height: 140%;
                letter-spacing: -0.3px;
                text-transform: uppercase;
                color: #ffffff;
                margin-bottom: 30px;
                &:before {
                    @include bottom-line;

                    left: 36%;
                    @include breakpoint(md) {
                        left: 0;
                    }
                }
                text-align: center;
                @include breakpoint(md) {
                    text-align: left;
                }
                @include breakpoint(xxl) {
                    font-size: 4rem;
                }
            }
            p {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                text-align: justify;
                letter-spacing: -0.3px;
                color: #ffffff;
            }
        }
    }
}
