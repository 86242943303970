.contact_us {
    .form-with-details {
        padding: 30px;
        background: #ffffff;
        @include breakpoint(md) {
            padding: 5rem 7rem;
        }
        > .row {
            > div {
                margin-bottom: 30px;
                @include breakpoint(md1) {
                    margin-bottom: 0;
                }
                > .default-container {
                    background: #ffffff;
                    padding: 30px 20px;
                    /* drop shadow */

                    box-shadow: 0px 4px 12px rgba(163, 163, 163, 0.25);
                    border-radius: 8px;
                    height: 100%;
                    @include breakpoint(sm) {
                        padding: 40px 45px;
                    }
                }
            }
        }
        .contact-right {
            > .row {
                display: flex;
                flex-direction: column;
                height: 100%;
                > .col-12 {
                    &:first-child {
                        margin-bottom: auto;
                    }
                }

                h2 {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 3rem;
                    line-height: 140%;
                    /* or 50px */

                    letter-spacing: -0.3px;
                    text-transform: uppercase;

                    color: $primary-blue;
                    margin-bottom: 32px;
                    position: relative;
                    &:before {
                        @include bottom-line($primary-yellow);
                    }

                    @include breakpoint(xxl) {
                        font-size: 36px;
                    }
                }
                p {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 140%;
                    /* or 20px */

                    text-align: justify;
                    letter-spacing: -0.3px;

                    color: #122553;
                }
                .ms-content-block__cta {
                    a {
                        @include cta_styling($primary-yellow);
                        border-radius: 24px;
                        margin-bottom: 10px;
                    }
                }
                .contact-img {
                    img {
                        @include img-fit;
                    }
                }
                .info-card {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    background: #ffffff;
                    border: 1px solid #eaecee;
                    border-radius: 12px;
                    margin-top: 20px;
                    padding: 18px;
                    gap: 15px;

                    @include breakpoint(md) {
                        padding: 20px 24px;
                        gap: 20px;
                    }
                    .ms-content-block__image {
                        height: 45px;
                        width: 50px;
                        img {
                            @include img-fit;
                            object-fit: contain;
                        }
                    }
                    h6 {
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 150%;
                        word-break: break-word;

                        color: #373f47;
                        @include breakpoint(sm) {
                            font-size: 16px;
                        }
                        @include breakpoint(sm) {
                            font-size: 18px;
                        }
                        @include breakpoint(xxl) {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .contact-left {
            p {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 600;
                font-size: 1.5rem;
                line-height: 140%;
                /* or 20px */

                text-align: justify;
                letter-spacing: -0.3px;

                color: #122553;
            }
            > p {
                margin-bottom: 30px;
            }
            .ms-content-block__cta {
                a {
                    @include cta_styling($primary-yellow);
                    border-radius: 4px;
                    margin-bottom: 40px;
                }
            }
            .info-card {
                display: flex;
                align-items: center;
                padding: 24px;
                gap: 20px;
                width: 100%;
                /* height: 110px; */
                background: #ffffff;
                border: 1px solid #eaecee;
                border-radius: 12px;
                margin-top: 20px;
                .ms-content-block__image {
                    height: 45px;
                    width: 50px;
                    img {
                        @include img-fit;
                    }
                }
                h6 {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 150%;
                    /* identical to box height, or 30px */

                    /* Gray/700 */

                    color: #373f47;
                }
            }
        }
    }
    .contact-left {
        form {
            .form-floating,
            .phone-group {
                margin-bottom: 3rem !important;

                @include breakpoint(xxxl) {
                    margin-bottom: 3.5rem !important;
                }
            }
            .phone-group {
                .invalid-feedback {
                    display: block;
                }
            }
            .form-control {
                // margin-bottom: 3rem;
                border: 1px solid #79747e;
                border-radius: 4px;
                &:focus {
                    color: #212529;
                    background-color: #fff;
                    border-color: $primary-blue;
                    outline: 0;
                    box-shadow: 0 0 0 0.05rem $primary-blue;
                }
            }
            .form-control.is-invalid {
                &:focus {
                    box-shadow: none;
                }
            }
            .form-control.invalidphone {
                &:focus {
                    border-color: #dc3545 !important;
                    box-shadow: none;
                }
            }

            .form-control.invalidphone + .flag-dropdown {
                border-color: #dc3545 !important;
                box-shadow: none;
            }
            .form-check {
                padding-left: 0 !important;
            }
            label {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 400;
                font-size: 1.6rem;
                // line-height: 24px;
                letter-spacing: 0.5px;
                color: #49454f;
                margin-top: 3px;
                height: auto;
                // @include breakpoint(xs) {
                //     font-size: 15px;
                // }
                @include breakpoint(sm) {
                    font-size: 1.4rem;
                }
            }
            .contact-us-textarea {
                textarea {
                    height: 120px !important;
                    padding-top: 7px !important;
                }
            }
            select {
                padding-right: 23px !important;
            }
            input[type='email'] {
                cursor: auto !important;
            }
            .file-size-info {
                display: flex;
                align-items: center;
                margin-top: 4px;
                .info-icon {
                    height: 1.7rem;
                    width: 1.7rem;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
                p {
                    margin-bottom: 0;
                    margin-left: 6px;
                    margin-right: 6px;
                    font-size: 1.3rem;
                }
            }
            .in-contact-page {
                .input-group {
                    label {
                        left: -2rem;
                    }
                }
            }

            > button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 20px;
                gap: 1rem;

                background: #13294b;
                border-radius: 4px;
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                /* or 22px */
                letter-spacing: -0.3px;
                margin-top: 15px;
                width: 100%;
                border-radius: 24px;
                @include breakpoint(xxxl) {
                    margin-top: 40px;
                }
                @include breakpoint(sm) {
                    width: 200px;
                }
                @include breakpoint(xs) {
                    font-size: 14px;
                    color: #ffffff;
                    // margin-top: 40px;
                    &:hover {
                        background: $blue-hover;
                    }
                }

                select {
                    padding: 1rem 0.75rem !important;
                }
            }

            input[type='file']::-webkit-file-upload-button {
                background: transparent;
            }
            input[type='file'] {
                background: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB132h?pubver=1');
                background: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB10r2');
                background-repeat: no-repeat;
                background-position: 99%;
                background-size: 2.2rem;
                padding-right: 30px;
            }
            .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
                background-color: transparent;
            }
        }
    }

    iframe {
        height: 400px;
        @include breakpoint(xxl) {
            height: 540px;
        }
    }
    h5 {
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        /* or 22px */

        letter-spacing: -0.3px;
        // text-transform: lowercase;

        color: $primary-blue;
        margin-bottom: 30px;
    }
}

// Floating Labels
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
    opacity: 1 !important;
    transform: scale(0.85) translateY(-2rem) translateX(0.75rem) !important;
    background: #ffffff;
    padding-right: 4px;
    padding-left: 4px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0.4rem;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 0.225rem !important;
    padding-bottom: 0.325rem !important;
    cursor: pointer;
}
// height of form fields
.form-floating > .form-control,
.form-floating > .form-select {
    height: calc(4.8rem + 0px) !important;
    font-size: 1.6rem !important;
}


// font-size
.form-floating > .form-control,
.form-floating > .form-select {
    font-size: 1.6rem !important;
}

.szf-loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    z-index: 1;
}

.szf-spinner {
    width: 200px;
    height: 200px;
    background: rgba(0, 0, 0, 0.834) url('https://zayedfestival.ae/wp-content/themes/zayedfestivaltheme/imgs/loading-new-min.gif') center
        no-repeat;
}
.szf-display-none {
    display: none;
}

#overlay {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    transition: 1s 0.4s;
}
#progress {
    height: 1px;
    background: #fff;
    position: absolute;
    width: 0;
    top: 50%;
}
#progstat {
    font-size: 0.7em;
    letter-spacing: 3px;
    position: absolute;
    top: 50%;
    margin-top: -40px;
    width: 100%;
    text-align: center;
    color: #fff;
}
.invalid-feedback {
    font-size: 1.2rem !important;
}
// Chrome
input#phone::-webkit-outer-spin-button,
input#phone::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input#phone[type='number'] {
    -moz-appearance: textfield;
}

// multi select

.multiSelectContainer {
    .searchWrapper {
        border: 1px solid #79747e !important;
        font-size: 1.6rem !important;
        color: #13294b;
        min-height: calc(4.8rem + 0px) !important;
        padding: 0.5rem 0.75rem !important;
        .chip {
            background: $primary-blue;
            border-radius: 4px;
            &:first-child {
                margin-top: 5px;
            }
        }
        input {
            @include placeholder {
                color: #13294b;
            }
        }
    }
}
.optionListContainer ul li {
    font-family: $font-family-primary;
    font-size: 1.6rem !important;
}
.optionListContainer ul li:hover {
    background: $primary-blue !important;
}

.highlightOption {
    background: $primary-blue !important;
}

.multiSelect-container-div {
    position: relative;
    width: 100% !important;
    margin-bottom: 3rem;
    background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB2bhN');
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    cursor: pointer !important;
    label {
        font-size: 1.4rem !important;
        opacity: 1 !important;
        transform: scale(0.85) translateY(-2rem) translateX(-4rem) !important;
        background: #ffffff;
        padding-right: 4px;
        padding-left: 4px;
        height: 10px !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;

        z-index: 1;
        top: 1px;
        left: 1.1rem;
        @include breakpoint(sm) {
            top: 8px;
            left: 0.2rem;
        }
    }
    .invalid-feedback {
        display: block !important;
    }
}
.govt-category {
    label {
        left: 2.8rem;
        @include breakpoint(sm) {
            left: 3rem;
        }
    }
}
.sponsor-category {
    label {
        left: 3.5rem;
    }
}
.contact-us-textarea {
    width: 100% !important;
}
.notFound {
    font-family: $font-family-primary;
    font-size: 1.6rem !important;
    color: $primary-blue !important;
}

// Phone
// #countryCode {
//     border: 1px solid #79747e;
//     height: calc(4.8rem + 0px) !important;
//     font-size: 1.6rem;
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
// }
// // #countryCode ~ div{width: 75%; margin-bottom: 0 !important;}
// .phone-group {
//     .form-floating {
//         &:first-child {
//             width: 25%;
//         }
//         &:last-child {
//             width: 75%;
//             input{
//                 border-top-left-radius: 0;
//                 border-bottom-left-radius: 0;
//             }
//         }
//     }
// }
.phone-group {
    position: relative;
    label {
        position: absolute;
        top: -24px;
        opacity: 1 !important;
        transform: scale(0.85) translateY(-2rem) translateX(0.75rem) !important;
        background: #ffffff;
        padding-right: 4px;
        padding-left: 4px;
        height: 10px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0.9rem;
        z-index: 4;
        left: -0.7rem;
    }
    .react-tel-input {
        z-index: 3;
        input {
            width: 100% !important;
            height: calc(4.8rem + 0px) !important;
            padding-left: 58px !important;
        }

        .flag-dropdown {
            background-color: #fff !important;
            border: 1px solid #79747e !important;
            .selected-flag {
                width: 46px;
                padding: 0 0 0 12px;
            }
            // Phone search icon
            ul.country-list {
                .search {
                    span {
                        position: absolute;
                        top: 24px;
                        left: 30px;
                    }
                }
            }
        }
    }
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545 !important;
}
.invalid-feedback {
    p {
        color: #dc3545 !important;
    }
}
// -----------------------------
.contact-us-file-formgroup,
.business-op-tradeLicenseFile-formgroup {
    input {
        padding: 1rem 0.75rem;
        line-height: 1.25;
        font-size: 1.6rem !important;
        height: calc(4.8rem + 0px) !important;
        width: calc(100% - 40px);
        z-index: 2 !important;
    }
    button {
        padding: 0.5rem 1.5rem;
        background: #13294b;
        border: transparent;
        font-size: 14px;
    }
    label {
        opacity: 1 !important;
        transform: scale(0.85) translateY(-2rem) translateX(0.75rem) !important;
        background: #ffffff;
        padding-right: 4px;
        padding-left: 4px;
        height: 10px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0.6rem;
        position: absolute;
        left: -0.8rem;
        z-index: 2;
        margin-right: 4rem;
    }

    .file-size-info {
        width: 100%;
    }
}
