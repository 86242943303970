.all-partners,
.dining-corner {
    .partners-container {
        &:nth-child(even) {
            background: #faf7f4;
            // background-image: url(https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB20RX?pubver=1);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            h3 {
                color: $primary_blue;
            }
        }

        &:nth-child(odd) {
            background: #ffffff;
            background-image: url(https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB20S0?pubver=1);
            background-attachment: fixed;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            h3 {
                color: $primary_maroon;
            }
        }
        > .row {
            .ms-content-block {
                max-width: 300px;
                margin: 60px auto 30px;
                padding: 10px;
                @include breakpoint(sm) {
                    margin: 60px 20px 30px;
                }
                @media screen and (min-width: 480px) and (max-width: 576px) {
                    max-width: 25rem !important;
                }
            }
        }
        h3 {
            max-width: 82vw;
            margin: 0 auto;

            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 4rem;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-transform: uppercase;
        }
    }
}

// /* Exactly 2 children */
// .slick-track:has(> :nth-child(2):last-child) .slick-slide {
//     max-width: 400px !important;
// }
// /* Exactly 3 children */
// .slick-track:has(> :nth-child(3):last-child) .slick-slide {
//     /* 	border: 5px solid blue; */
//     max-width: 400px !important;
// }

.all-partners,
.dining-corner {
    //-------------------------Has only 3 child-----------------------------//
    .slick-track > div:nth-child(1):nth-last-child(3) .ms-content-block {
        @media screen and (max-width: 1400px) {
            margin: 60px auto 30px;
        }
        @media screen and (max-width: 1199px) {
            width: 30rem;
        }
        @media screen and (max-width: 991px) {
            width: 22rem;
        }
    }
    .slick-track > div:nth-child(2):nth-last-child(2) .ms-content-block {
        width: 100%;
        margin: 60px 0px 30px -24%;
        @media screen and (max-width: 1700px) {
            margin: 60px 0px 30px -16%;
        }
        @media screen and (max-width: 1500px) {
            margin: 60px 0px 30px -7%;
        }
        @media screen and (max-width: 1400px) {
            margin: 60px auto 30px;
        }
        @media screen and (max-width: 1199px) {
            width: 30rem;
        }
        @media screen and (max-width: 991px) {
            width: 22rem;
        }
    }
    .slick-track > div:nth-child(3):nth-last-child(1) .ms-content-block {
        width: 100%;
        margin: 60px 0px 30px -52%;
        @media screen and (max-width: 1700px) {
            margin: 60px 0px 30px -37%;
        }
        @media screen and (max-width: 1500px) {
            margin: 60px 0px 30px -20%;
        }
        @media screen and (max-width: 1400px) {
            margin: 60px auto 30px;
        }
        @media screen and (max-width: 1199px) {
            width: 30rem;
        }
        @media screen and (max-width: 991px) {
            width: 22rem;
        }
    }
    //-------------------------Has only 2 child-----------------------------//
    .slick-track > div:nth-child(1):nth-last-child(2) .ms-content-block {
        //  margin: 60px auto 30px;
        @media screen and (max-width: 1400px) {
        }
        @media screen and (max-width: 1199px) {
            width: 30rem;
        }
        @media screen and (max-width: 991px) {
            width: 25rem;
        }
        @media screen and (max-width: 767px) {
            margin: 60px auto 30px;
            width: 26rem;
        }
    }
    .slick-track > div:nth-child(2):nth-last-child(1) .ms-content-block {
        width: 100%;
        margin: 60px 0px 30px -50%;
        @media screen and (max-width: 1700px) {
            margin: 60px 0px 30px -44%;
        }
        @media screen and (max-width: 1500px) {
            margin: 60px 0px 30px -40%;
        }
        @media screen and (max-width: 1400px) {
            margin: 60px 0px 30px -24%;
        }
        @media screen and (max-width: 1199px) {
            width: 30rem;
        }
        @media screen and (max-width: 991px) {
            width: 25rem;
        }
        @media screen and (max-width: 767px) {
            margin: 60px auto 30px;
            width: 26rem;
        }
    }
}
