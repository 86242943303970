.food-container {
    > .row {
        display: flex;
        flex-wrap: wrap;
        gap: 4rem;
        padding: 4rem 0;
        justify-content: center;
        > .col-12 {
            box-shadow: 0px 4px 10px rgba(161, 159, 181, 0.25);
            border-radius: 20px;
            padding: 10px 10px 10px 10px;

            width: calc(100% - 40px);
            @include breakpoint(md) {
                width: 47%;
                padding: 10px 10px 40px 10px;
            }
            @include breakpoint(md1) {
                width: 31%;
            }
            .ms-video-player {
                border-radius: 20px;

                // =============================//
                .msc-videoplayer__wrapper {
                    position: relative;

                    .msc-videoplayer__playpausetrigger__container {
                        display: block;
                        position: absolute;
                        bottom: 10px;
                        left: 10px;
                        .msc-videoplayer__playpausetrigger {
                            display: block;

                            button {
                                &:first-child {
                                    &[aria-hidden='false'] {
                                        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/pmtjhhhpmz/imageFileData/MB52b8?pubver=1');
                                        background-repeat: no-repeat;
                                        background-color: transparent;
                                        background-size: 2.6rem !important;
                                        height: 2.6rem;
                                        width: 2.6rem;
                                        outline: none;
                                        border: none;
                                    }
                                    &[aria-hidden='true'] {
                                        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/pmtjhhhpmz/imageFileData/MB52bb?pubver=1') !important;
                                        background-repeat: no-repeat;
                                        background-color: transparent;
                                        background-size: 2.6rem !important;
                                        height: 2.6rem;
                                        width: 2.6rem;
                                        outline: none;
                                        border: none;
                                    }
                                }
                                &:nth-child(2) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .msc-videoplayer__video {
                    width: 100%;
                    height: 292px;
                    // height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
                .ms-video-player__details {
                    position: relative;
                    top: auto;
                    margin: 0;
                    height: auto;
                    background: transparent;
                    padding: 0;

                    &__title {
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 1.8rem;
                        line-height: 150%;
                        color: #122553;
                        text-align: left;
                        margin: 4px 0 0;
                        text-transform: capitalize;
                        width: 100%;
                        background-image: url("https://images-ap-prod.cms.commerce.dynamics.com/cms/api/pmtjhhhpmz/imageFileData/MB54Nz?pubver=0");
                        background-repeat: no-repeat;
                        background-position: left;
                        padding-left: 3rem;
                        background-size: 2.4rem;
                        margin:15px 0 10px;

                        @include text-ellipsis(2);
                    }
                    &__text {
                        font-family: $font-family-primary;
                        font-style: normal;
                        color: #242d35;
                        margin-top: 0;
                        width: 100%;
                        p {
                            @include text-ellipsis(4);
                            font-weight: 500;
                            font-size: 1.2rem;
                            line-height: 150%;
                            text-align: left;
                            margin-bottom: 0;
                            padding-bottom: 1rem;
                        }
                    }
                    &__additional-content {
                        display: none;
                    }

                    &__additional-content {
                        + div:empty {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
