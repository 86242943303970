.video-gallery-div {
    @include custom-container;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    .szf_pandv__video-gallery {
        width: 92%;
        @include breakpoint(lg) {
            width: 32% !important;
        }
        @include breakpoint(md1) {
            width: 49%;
        }
        @include breakpoint(md) {
            width: 44%;
        }
        iframe {
            height: 30rem;
            width: 100%;
        }
    }
}
