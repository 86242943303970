.ms-search-result-container__Products {
    ul {
        .ms-product-search-result__item {
            transition: all 0.3s ease-in-out;
            padding: 1rem 1rem 2rem;
            border-radius: 4px;
            border: 1px solid #eaecee;
            margin-bottom: 10px;
            box-shadow: 0px 4px 12px 0px rgba(163, 163, 163, 0.25);
            margin-bottom: 15px;
            &:hover {
                box-shadow: 0px 4px 12px 0px rgba(163, 163, 163, 0.25);
            }
            @include breakpoint(md1) {
                // border: none;
                // margin-bottom: 0;
                box-shadow: none;
            }
            a {
                text-decoration: none;
                &:hover {
                    .msc-product__title {
                        color: #1e1e1e;
                        text-decoration: underline;
                    }
                }
                &:focus,
                &:focus-visible {
                    outline: none;
                    border-color: transparent;
                }
                .msc-product__image {
                    img {
                        object-fit: cover;
                        object-position: center;
                        width: 300px !important;
                        height: 200px !important;
                        @include breakpoint(md1, max-width) {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }

                .msc-product__title {
                    color: #1e1e1e;
                    font-family: $font-family-primary;
                    font-size: 1.8rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 1.28px;
                    text-decoration: none;
                }
            }
            .msc-product__details {
                .msc-price {
                    .msc-price__actual {
                        color: #1e1e1e;
                        font-family: $font-family-primary;
                        font-size: 1.6rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 1.28px;
                    }
                }
            }
        }
    }
}
