//ImageGallery
.image-gallery {
    background: $white;
    padding: 30px 0 10px;
    h1 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 600;
        font-size: 4rem;
        line-height: 140%;
        /* identical to box height, or 56px */

        letter-spacing: -0.3px;
        text-transform: uppercase;

        color: $home_maroon;
        position: relative;
        margin: 0 auto 5rem auto;

        @include breakpoint(sm) {
            margin: 0 auto 6rem auto;
        }
        @include breakpoint(md1) {
            margin: 0 auto 8rem auto;
        }
        &:before {
            @include bottom-line;
            width: 30%;
            bottom: -10px;
            left: auto;
            right: auto;
            bottom: -10px;
        }
    }
    [class^='col-'] {
        // padding-left: 0;
        // padding-right: 0;
        // .ms-content-block {
        //               &__image {
        //                 img {
        //                     @include img-fit;
        //                 }
        //             }
        //         }
        .ms-content-block__image {
            overflow: hidden;
            border: 6px solid #fff;

            @media screen and (max-width: 576px) {
                height: 30vh !important;
                border: 0;
            }
            @media screen and (max-width: 991px) {
                height: 50vh !important;
            }
            @media screen and (max-width: 768px) {
                height: 30vh !important;
            }
            img {
                transition: all 0.3s ease-in-out;
                @include img-fit;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        .image-gallery-col1 {
            > .row {
                > .col-12:nth-child(1) {
                    padding-left: 0;
                    .ms-content-block {
                        .ms-content-block__image {
                            height: 450px;
                        }
                    }
                }
                .col-12 {
                    padding-right: 0;
                }
            }

            &-row2 {
                .row {
                    display: flex;
                    justify-content: flex-start;
                    .col-12 {
                        max-width: 100%;
                        @include breakpoint(sm) {
                            max-width: calc(50% - 3px);
                        }
                        &:first-child {
                            padding-right: 0;
                            padding-left: 0;
                        }

                        &:nth-child(2) {
                            padding-left: 0;
                            padding-right: 0px;
                        }
                    }
                }
            }
        }

        .image-gallery-col3 {
            height: 100%;
        }
    }
    .image-gallery-second-half {
        height: 100%;
        > .row {
            height: 100%;

            .image-gallery-second-half-a {
                height: 100%;
                > .row {
                    height: 100%;
                    .col-lg-6:nth-child(2) {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    .image-gallery-col2 {
                        height: 100%;
                        > .row {
                            height: 100%;
                            > .col-12 {
                                padding-right: 0;
                                padding-left: 0;
                            }
                        }
                        .ms-content-block {
                            height: 100%;
                            .ms-content-block__image {
                                height: 100%;
                            }
                        }
                    }
                    .image-gallery-col3 {
                        .ms-content-block__image {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}

// .image-gallery-col1 > .row > .col-12 {
//     padding-right: 0;
// }

// .image-gallery-col2 > .row > .col-12 {
//     padding-right: 0;
//     padding-left: 0;
// }
