.ms-order-confirmation {
    .szf-order-confirmation-ticket-qr {
        height: 280px;
        margin: 1rem 0;
        svg {
            height: 100% !important;
            width: 100% !important;
            object-fit: contain;
            object-position: center;
        }
    }

    .szf-ticket-QR-code-container {
        .szf-ticket-QR-code-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #e8d6c0;
            margin: 2rem 0 3rem;
            padding-top: 4rem;
            //Smartphone
            @media screen and (max-width: 576px) {
                flex-direction: column;
            }
            > p {
                display: block;
                width: 100%;
                font-family: $font-family-primary;
                font-size: 2.4rem;
                font-weight: bold;
                line-height: 140%;
                text-align: left;
                margin-bottom: 0;
                width: auto;
            }
        }
        .printed-tickets-container {
            display: flex;
            flex-wrap: wrap;
            gap: 3rem;
            justify-content: flex-start;
            max-height: 93rem;
            overflow: auto;
            padding-left: 10px;
            padding-bottom: 20px;

            @include breakpoint(md1) {
                justify-content: flex-start;
                gap: 5rem;
            }
        }

        .szf-order-confirmation-ticket-card {
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
            min-width: 1050px;
            width: 1050px;
            max-height: 610px;
            height: 610px;
            // border-radius: 12px;
            // padding: 1rem 3rem 1rem 3rem;
            display: flex;
            justify-content: space-between;
            // flex-direction: column;
            // gap: 2rem;
            align-items: center;
            position: relative;
            // border: 1px solid rgba(0, 0, 0, 0.25);
            // background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB4p9v?pubver=0');
            background-repeat: no-repeat;
            // background-position: 50% 130px;
            overflow: hidden;
            background-size: contain;
            background-position: bottom;
            border: 1px solid #000;
            border-radius: 0;
            @include breakpoint(md1) {
                // width: 100rem;
                // padding: 3rem 4rem 2rem 4rem;
            }
            // &:before {
            //     background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2j57?ver=9105&m=6&q=80');
            //     content: '';
            //     position: absolute;
            //     bottom: -23px;
            //     height: 100px;
            //     width: 100%;
            // }

            // New ticket============================================== //
            // .bg-pattern {
            //     // background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2j57?ver=9105&m=6&q=80');
            //     background-repeat: no-repeat;
            //     background-position: center;
            //     position: absolute;
            //     bottom: -100px;
            //     height: 36rem !important;
            //     width: 100%;
            // }
            .ticket-left {
                width: 35%;
                position: relative;
                border-right: 3px dashed black;
                padding: 2rem 5rem 2rem 3.5rem;
                height: 100%;
                .qr-section {
                    .pass-day {
                        // writing-mode: vertical-rl;
                        // text-orientation: sideways-right;
                        // position: absolute;
                        // top: 0;
                        // bottom: auto;
                        // height: 100%;
                        // transform: rotate(180deg);
                        // left: 9px;
                        // background: rgba(0, 128, 0, 0.233);
                        margin: 15px 0 5px;
                        p {
                            margin-bottom: 0;
                        }
                    }
                    .ticket-number {
                        margin-top: -40px;
                        // writing-mode: vertical-rl;
                        // text-orientation: sideways-right;
                        // position: absolute;
                        // top: 0;
                        // height: 100%;
                        // transform: rotate(180deg);
                        // right: 15px;
                        // background: rgba(255, 0, 0, 0.246);
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .ticket-right {
                // width: 65%;
                // height: 100%;
                // padding: 2rem 3rem;
                &-upper-section {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    &-left {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        width: 50%;
                        p {
                            width: 100%;
                            text-align: left;
                            letter-spacing: 0;
                        }
                    }
                    &-right {
                        width: 50%;
                    }
                }
                &-middle-section {
                    // width: 100%;
                    // height: 18rem;
                    // display: flex;
                    // justify-content: center;
                    // align-items: center;
                    .ticket-logo-container {
                        width: 24rem;
                        height: auto;

                        img {
                            @include img-fit;
                            object-fit: contain;
                        }
                    }
                }
                &-lower-section {
                    z-index: 1;
                    position: relative;
                    p {
                        text-align: left !important;
                    }
                }
            }
            .dates-row {
                border: 1px solid black;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;
                flex-direction: column;
                gap: 2px;
                .ar-dates,
                .en-dates {
                    display: flex;
                    justify-content: space-around;
                    width: 100%;
                    .ticket-date {
                        display: flex;
                        font-size: 11px;
                        font-weight: 600;
                        gap: 4px;
                        // font-size: 10px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 140%; /* 14px */
                        text-transform: capitalize;
                    }
                }
            }
            // New ticket ============================================== //
            .szf-order-confirmation-ticket-price {
                position: absolute;
                top: 62%;
                width: calc(19rem - 6rem);
                border-bottom: 2px dashed black;
                display: flex;
                justify-content: center;
                z-index: 1;
                @include breakpoint(md1) {
                    width: calc(25rem - 6rem);
                }
            }
            p {
                font-family: $font-family-primary;
                font-size: 14px;
                font-weight: 700;
                line-height: 17px;
                text-align: center;
            }
        }
        .szf-order-confirmation-ticket-card.ticket-back-side {
            .ticket-left {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 2rem 5rem;
            }
            .ticket-right {
                display: flex;
                flex-direction: column;
                // justify-content: space-between;
                gap: 5px;
                z-index: 1;
                width: 100%;
                &-upper-section {
                    // height: 60px;
                    // align-items: center;
                    &-logo {
                        height: 80px;
                        width: 160px;
                        img {
                            @include img-fit;
                            object-fit: contain;
                        }
                    }
                    .hayakum-logo {
                        width: 120px;
                    }
                    &-text {
                        .arabic-heading-text {
                            .p1 {
                                font-size: 12px !important;
                                margin: 0px;
                                font-family: $arabic-font-family;
                                line-height: normal;
                                letter-spacing: 0 !important;
                                transform: scale(1.6);
                                font-weight: normal;
                                margin-bottom: 10px;
                            }
                            .p2 {
                                font-size: 10px !important;
                                margin: 0px;
                                font-family: $arabic-font-family;
                                line-height: normal;
                                letter-spacing: 0 !important;
                                font-weight: 700;
                                transform: scale(1.8);
                                padding-bottom: 10px;
                                margin-bottom: 10px;
                                position: relative;
                                &::before {
                                    position: absolute;
                                    content: '';
                                    width: 78%;
                                    left: auto;
                                    right: auto;
                                    background: #000;
                                    height: 1px;
                                    bottom: 1px;
                                }
                            }
                        }
                        .english-heading-text {
                            .p1 {
                                font-size: 14px !important;
                                margin: 0px;
                                font-family: $font-family-primary;
                                line-height: normal;
                                letter-spacing: 0 !important;
                                transform: scale(1.5);
                                font-weight: normal;
                                margin-bottom: 10px;
                                margin-top: 20px;
                            }
                            .p2 {
                                font-size: 10px !important;
                                margin: 0px;
                                font-family: $font-family-primary;
                                line-height: normal;
                                letter-spacing: 0 !important;
                                font-weight: 700;
                                transform: scale(1.8);
                            }
                        }
                    }
                }
                &-middle-section {
                    height: auto;
                    margin: 3px 0px 20px;

                    // ul {

                    //     h3 {
                    //         font-size: 9pt;
                    //         font-weight: bold;
                    //         font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
                    //             'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
                    //         line-height: normal;
                    //     }
                    //     li {
                    //         font-size: 6.6pt;
                    //         font-weight: bold;
                    //         font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
                    //             'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
                    //         line-height: normal;
                    //     }
                    // }
                    .arabic-ul,
                    .english-ul {
                        h3 {
                            font-size: 16px;
                            font-weight: bold;
                            line-height: normal;
                        }
                        ul {
                            list-style: none;
                            li {
                                font-size: 12px;
                                position: relative;
                                margin-bottom: 1px;
                                &:before {
                                    position: absolute;
                                    content: '';
                                    background: $primary_blue;
                                    height: 4px;
                                    width: 4px;
                                    top: 11px;
                                }
                            }
                        }
                    }
                    .arabic-ul {
                        direction: rtl;
                        h3 {
                            font-family: $arabic-font-family;
                        }
                        ul {
                            padding-right: 15px;

                            li {
                                text-align: right !important;
                                font-family: $arabic-font-family;

                                &:before {
                                    right: -17px;
                                }
                            }
                        }
                    }
                    .english-ul {
                        h3 {
                            font-family: $font-family-primary;
                        }
                        ul {
                            li {
                                font-family: $font-family-primary;
                                &:before {
                                    left: -17px;
                                }
                            }
                        }
                    }
                }
                &-lower-section {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    /* position: absolute; */
                    bottom: 0;
                    background: #e8d6c0;
                    padding: 10px 15px 15px;
                    // margin: -40px auto -50px;
                    position: absolute;
                    left: 405px;
                    p {
                        font-family: $font-family-primary-for-ticket;
                        margin-bottom: 0;
                        // color: red !important;
                        letter-spacing: 0 !important;
                        margin-top: 1.5px;
                    }
                    p.site-name {
                        transform: scale(1.5);
                        margin-left: 36px;
                        font-size: 14px;
                    }
                }
            }
        }
        .szf-order-confirmation-ticket {
            p {
                text-align: center;
            }
        }
        // ===========================Redesign for go live=========================== //
        .printed-tickets-container {
            .single-ticket {
                // width: 1050px;
                .ticket-front-side {
                    padding: 30px 35px;
                    max-height: 610px;
                    height: 610px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    border: 1px solid #000;
                    position: relative;
                    gap: 2.5rem;
                    > div {
                        z-index: 1;
                    }
                    .ticket-bg-img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/pmtjhhhpmz/imageFileData/MB1IGd?pubver=1');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        box-sizing: -3;
                        opacity: 0.1;
                        left: 0;
                        top: 0;
                        z-index: 0;
                    }
                    .front-ticket-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .ticket-logo-container {
                            height: 80px;
                            width: 160px;
                            img {
                                @include img-fit;
                                object-fit: contain;
                            }
                        }
                        .pass-day {
                            .arabic-pass-day {
                                p {
                                    font-family: $arabic-font-family;
                                }
                            }
                            .english-pass-day {
                                p {
                                    font-family: $font-family-primary;
                                }
                            }
                            p {
                                font-size: 1.6rem;
                                letter-spacing: 0;
                            }
                        }
                    }
                    .front-ticket-footer {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        > div:nth-child(1) {
                            width: 30%;
                        }
                        > div:nth-child(2) {
                            width: 40%;
                            display: flex;
                            justify-content: center;
                        }
                        > div:nth-child(3) {
                            width: 30%;
                            display: flex;
                            justify-content: flex-end;
                        }
                    }
                    .ticket-type {
                        p {
                            margin: 0;
                        }
                    }
                }
                .ticket-back-side {
                    padding: 30px 35px;
                    border: 1px solid #000;
                }
            }
        }
    }
}
