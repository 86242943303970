.bo-landing-page {
    .bo-main-banner {
        padding-bottom: 0;
        > .ms-container-background-image {
            margin-top: -$header-height;
            padding-top: $header-height;
            height: 70vh;

            min-height: auto !important;
            @include breakpoint(md1) {
                height: 760px;
                height: 100vh;
            }
            // @include breakpoint(md) {
            //     height: 70vh;
            // }
            // @include breakpoint(xxl) {
            //     height: 100vh;
            // }
        }
        .ms-content-block__details {
            min-height: calc(70vh - 90px);
            @include breakpoint(md1) {
                // min-height: calc(760px - 120px);
                // min-height: 100vh;
                min-height: calc(100vh - 90px);
            }

            // @include breakpoint(md) {
            //     min-height: calc(70vh - 120px);
            // }
            // @include breakpoint(xxl) {
            //     min-height: calc(100vh - 120px);
            // }
        }
    }
    .bo-stats {
        padding: 30px 42px;
        background: #ffffff;
        box-shadow: 0px 4px 12px rgba(163, 163, 163, 0.25);
        border-radius: 8px;
        margin-top: -70px;
        position: relative;

        @include breakpoint(md1, 'max-width') {
            max-width: 95%;
            margin-top: 5rem;
        }

        > .row {
            > .col-12 {
                .ms-content-block {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-bottom: 40px;
                    padding-top: 20px;
                    @include breakpoint(md) {
                        padding-bottom: 60px;
                        padding-top: 0;
                    }
                    .ms-content-block__image {
                        height: 40px;
                        width: 40px;
                        img {
                            @include img-fit;
                            object-fit: contain;
                        }
                    }
                    .ms-content-block__details {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        h2 {
                            font-family: $font-family-primary;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 4rem;
                            line-height: 140%;
                            /* identical to box height, or 70px */
                            letter-spacing: -0.3px;
                            text-transform: uppercase;
                            color: $primary_blue;
                            margin-bottom: 0px;
                            padding-left: 20px;
                            color: #f49b50;
                            @include breakpoint(xxl) {
                                font-size: 5rem;
                            }
                        }
                        .ms-content-block__text {
                            font-family: $font-family-primary;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 2.4rem;
                            line-height: 140%;
                            letter-spacing: -0.3px;
                            text-transform: capitalize;
                            color: $primary_blue;
                            text-align: center;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0px;
                        }
                    }
                }
            }
        }
        .bo-stats-desc {
            > .row {
                > div {
                    @include breakpoint(md, 'max-width') {
                        width: 100%;
                    }
                }
                > div:nth-child(2) {
                    border-left: 2px solid #d9d9d9;
                    border-right: 2px solid #d9d9d9;
                    @include breakpoint(md, 'max-width') {
                        border-top: 2px solid #d9d9d9;
                        border-bottom: 2px solid #d9d9d9;
                        border-left: none;
                        border-right: none;
                    }
                }
            }
        }
    }
    .bo-stats-para {
        h5 {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 140%;
            /* or 34px */

            text-align: center;
            letter-spacing: -0.3px;
            text-transform: capitalize;
            color: $primary_blue;
            margin-top: 5px;
            padding-top: 15px;
            border-top: 2px solid #d9d9d9;

            @include breakpoint(md) {
                padding-top: 35px;
                margin-top: 35px;
            }
        }
    }
}

// .bo-stats .row .col-12 {
//     max-width: 25%;
//     // border-top: 2px solid #d9d9d9;
//     .ms-content-block {
//         position: relative;
//         .ms-content-block__image {
//             position: absolute;
//         }
//     }
// }
// .container.default-container.bo-stats {
// }

// .bo-stats .col-12:nth-child(2) .ms-content-block,
// .bo-stats .col-12:nth-child(3) .ms-content-block,
// .bo-stats .col-12:nth-child(4) .ms-content-block,
// .bo-stats .col-12:nth-child(6) .ms-content-block,
// .bo-stats .col-12:nth-child(8) .ms-content-block,
// .bo-stats .col-12:nth-child(7) .ms-content-block {
//     border-left: 2px solid #d9d9d9;
//     // margin: 20px 0px;
// }

// .bo-stats .col-12:nth-child(1),
// .bo-stats .col-12:nth-child(2),
// .bo-stats .col-12:nth-child(3),
// .bo-stats .col-12:nth-child(4) {
//     border-top: 0;
//     margin-bottom: 60px;
// }

// ----------------------------Smaller Banner
.smaller-banner {
    .bo-main-banner {
        > .ms-container-background-image {
            @include breakpoint(md1) {
                height: 760px;
            }
        }
        .ms-content-block__details {
            min-height: calc(70vh - 90px);
            @include breakpoint(md1) {
                min-height: calc(760px - 90px);
            }
        }
    }
}
