.msc-modal__dialog.single-image-modal {
    max-width: 100vw !important;
    max-height: 100%;
    height: 100vh;
    margin: 0 auto;

    .msc-modal__content {
        padding: 5rem 6rem;
        height: 100%;
        background: rgba(0, 0, 0, 0.9);
        box-shadow: none;
        border: none;
        justify-content: center;
        .msc-modal__header {
            display: flex;
            justify-content: flex-end;
            .msc-modal__title {
                button {
                    outline: none;
                    background: transparent;
                    border: none;
                    width: 3.5rem;
                    height: 3.5rem;
                    img {
                        @include img-fit;
                        object-fit: contain;
                    }
                }
            }
        }
        .msc-modal__body {
            overflow-x: hidden;
            max-height: 100%;
            overflow: hidden;
            .szf-category-details__gallery-img {
                width: 90%;
                margin: 0 auto;
                @include breakpoint(sm) {
                    width: 70%;
                }
                img {
                    @include img-fit;
                }
            }
            div {
                width: 100%;
                height: 100%;
            }
        }
    }
    // Arrows
    .slick-arrow.slick-next,
    .slick-arrow.slick-prev {
        width: 2.5rem;
        height: 2.5rem;
        top: 50%;
        z-index: 2;

        // @include breakpoint(xxl) {
        //     top: 40%;
        // }
        @include breakpoint(md) {
            width: 3.5rem;
            height: 3.5rem;
        }
    }
    .slick-arrow.slick-next {
        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBkOS?pubver=1');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        right: -0.8%;
        left: auto;
        @include breakpoint(xxl) {
            // top:40%;
            right: 0%;
        }
    }

    .slick-arrow.slick-next:before,
    .slick-arrow.slick-prev:before {
        content: '';
    }
    .slick-arrow.slick-prev {
        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBkOY?pubver=0');
        background-repeat: no-repeat;
        background-position: center;
        // width: 3.5rem;
        // height: 3.5rem;
        background-size: contain;
        // top: 50%;
        left: -0.8%;
        @include breakpoint(xxl) {
            // top:40%;
            left: 0%;
        }
    }
}
.single-image-modal + .msc-modal__backdrop.show {
    background: rgba(0, 0, 0, 0.9);
    opacity: 1;
}
