.has-main-carousel .main-promotion {
    position: relative;
}

.has-main-carousel .main-promotion {
    background: transparent;
    .ms-content-block {
        background: $banner-overlay;
    }
    .ms-content-block__image {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        img {
            @include img-fit;
        }
    }
}

.has-main-carousel .main-promotion {
    padding-top: 0;
    margin-top: -90px;
    padding-bottom: 0;

    * {
        text-decoration: none !important;
    }
}
