.szf-plp-banner__container {
    position: relative;
    // padding-bottom: 30px;
    // margin-top: -90px;
    // padding-top: 90px;

    .szf-plp-banner__image {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        > img {
            @include img-fit;
        }
    }
    .szf-plp-banner__content {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 8%;
        padding-right: 8%;
        margin-top:-50px;
        padding-top: 90px;
        background: linear-gradient(90deg, #010b23 14.74%, rgba(0, 0, 0, 0) 101%);
        width: auto;
        min-height: calc(70vh - 120px);
        padding-bottom: 10rem;
        @include breakpoint(md) {
            // width: 70%;
            width: 100%;
            padding-right: 30%;
            margin-top: -90px;
          
        }
        @include breakpoint(md1) {
            // padding-right: 0%;
            min-height: 760px;
            min-height: 100vh;
            padding-bottom: 0;
        }
        @include breakpoint(xxl) {
            // width: 55%;
            width: 100%;
            padding-right: 35%;
        }

        

        .szf-plp-banner__heading {
            h2 {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 600;
                font-size: 3rem;
                line-height: 140%;
                letter-spacing: -0.3px;
                text-transform: uppercase;
                color: #ffffff;
                text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.6);
                @include breakpoint(sm) {
                    font-size: 4rem;
                }
                @include breakpoint(xxl) {
                    font-size: 4rem;
                }
            }
        }
        .szf-plp-banner__description {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 140%;
            text-align: justify;
            letter-spacing: -0.3px;
            color: #ffffff;
            margin: 1.4rem 0 4rem;
        }

        .szf-plp-banner__book-now-link {
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                width: 200px;
                height: 48px;
                background: $primary-yellow;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                border-radius: 24px;
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 600;
                font-size: 2rem;
                line-height: 140%;
                text-align: center;
                letter-spacing: -0.4px;
                color: $primary_blue;
                text-transform: capitalize;
            }
        }
    }
}
