.festival_guide {
    .main-promotion {
        .ms-content-block__details {
            h2 {
                font-size: 5rem;
            }
        }
    }
    .map-block {
        background-color: #fff;
        .ms-content-block__image {
            img {
                @include img-fit;
            }
        }
        .ms-content-block__details {
            .ms-content-block__cta {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                row-gap: 2rem;
                padding: 2rem 4.5rem 5rem;
                @include breakpoint(md) {
                }

                a {
                    &:first-child,
                    &:last-child {
                        background: $primary-blue;
                        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2gu9?pubver=3');
                        position: relative;
                        color: #fff;
                        font-size: 1.8rem;
                        padding: 10px 20px 10px 6rem;
                        background-repeat: no-repeat;
                        background-position: 1.6rem center;
                        background-size: 2rem;
                        border-radius: 24px;
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                        letter-spacing: -0.3px;
                        text-decoration: none;
                        width: 100%;
                        justify-content: center;
                        transition: all 0.3s ease-in-out;
                        &:hover {
                            box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
                        }
                        @include breakpoint(sm) {
                            width: auto;
                            justify-content: flex-start;
                        }
                    }
                    &:first-child {
                    }
                    &:last-child {
                    }
                }
            }
        }
    }
}
