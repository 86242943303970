$breakpoints: (
    xs: 320px,
    sm: 576px,
    md: 768px,
    md1: 991px,
    lg: 1025px,
    xl: 1200px,
    xxl: 1340px,
    xxxl: 1500px,
);

//rule: min-width or max-width
@mixin breakpoint($bp, $rule: min-width) {
    @media (#{$rule}: map-get($breakpoints, $bp)) {
        @content;
    }
}
