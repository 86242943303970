.join-us-row {
    background: $white;
    // padding-top: 100px;
    > .row > div {
        padding: 0;
    }
    .join-us-container {
        background: $primary_blue;
        // padding: 60px 70px 120px 70px;
        padding: 12% 9% 12%;
        position: relative;
        height: auto;

        @include breakpoint(md) {
            height: 590px;
        }
        .ms-content-block__image {
            display: none;
        }
        h5 {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 3rem;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-transform: uppercase;
            color: #ffffff;
            background: $dark-yellow;
            display: flex;
            width: 140px;
            justify-content: center;
            @include breakpoint(xxl) {
                font-size: 3.2rem;
            }
        }
        h4 {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 400;
            font-size: 3rem;
            line-height: 140%;
            letter-spacing: -0.3px;
            color: #ffffff;
            @include breakpoint(xxl) {
                font-size: 3.2rem;
            }
        }
        h2 {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-transform: uppercase;
            color: #ffffff;
            margin-bottom: 40px;
            position: relative;
            &:before {
                @include bottom-line($primary-yellow);
                bottom: -20px;
            }
        }
        p {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            /* or 20px */

            letter-spacing: -0.3px;

            color: #ffffff;
            margin-bottom: 40px;
        }
        .ms-content-block__cta {
            a {
                @include cta_styling($primary_yellow);
            }
        }
    }
    .heritage-container {
        // background: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBnqm?pubver=1');
        // background-repeat: no-repeat;
        // background-position: center;
        // background-size: cover;
        // background-attachment: fixed;
        padding: 12% 9% 12%;
        background:#945042;
        position: relative;
        height: 590px;
        position: relative;
        z-index: 1;
        // > div{
        //     background-size: cover;
        //     background-attachment: fixed;
        //     padding: 12% 9% 12%;
        //     position: relative;
        //     min-height: 100% !important;
        //     position: relative;
        //     z-index: 1;
        // }

        .ms-content-block__image {
            position: absolute;
            bottom: -13rem;
            right: 0;
            display: none;
            z-index: -1;
            @include breakpoint(md) {
                display: block;
            }
            @media screen and (min-width: 991px) and (max-width: 1199px) {
                width: 45%;
            }
            img {
                @include img-fit;
            }
        }
        h5 {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 3rem;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-transform: uppercase;
            color: #ffffff;
            background: $home_maroon;
            display: flex;
            max-width: 250px;
            justify-content: center;
            @include breakpoint(xxl) {
                font-size: 3.2rem;
            }
        }

        h2 {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 400;
            font-size: 3rem;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-transform: uppercase;
            color: #ffffff;
            margin-bottom: 40px;
            position: relative;
            &:before {
                @include bottom-line($home_maroon);
                bottom: -20px;
            }
            @include breakpoint(xxl) {
                font-size: 3.2rem;
            }
        }
        p {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            /* or 20px */

            letter-spacing: -0.3px;

            color: #ffffff;

            margin-bottom: 122px;
            @include breakpoint(lg) {
                margin-bottom: 83px;
            }
            @include breakpoint(xxl) {
                margin-bottom: 99px;
            }
        }
        .ms-content-block__cta {
            a {
                @include cta_styling($home_maroon);
                position: relative;
                z-index: 1;
                display: none;
            }
        }
    }
}
