.festival-activities {
    padding: 70px 6%;
    background: #13294b94;
    h2 {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 3.4rem;
        line-height: 140%;
        letter-spacing: -0.3px;
        text-transform: uppercase;
        color: #ffffff;
        text-align: center;
        &:before {
            @include bottom-line($home_maroon);
            // left: 46%;
            left: auto;
            right: auto;
        }

        @include breakpoint(xxl) {
            font-size: 4rem;
        }
    }
    .festival-activities-content {
        padding-top: 6rem;
        @include breakpoint(sm){
            padding-top: 90px;
        }
        .festival-activities-item {
            position: relative;
            display: flex !important;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 10px 10px 50px 10px;
            // height: 441px;
            background: #ffffff;
            box-shadow: 0px 4px 10px rgba(161, 159, 181, 0.25);
            border-radius: 20px;
            width: 90% !important;
            margin: 0 auto;
            min-height: 49rem;
            @include breakpoint(xxxl) {
                min-height: 47rem;
            }
            &:hover {
                .festival-activities-item__cta {
                    height: 45px;
                    color: #fff;
                    a {
                        color: $white;
                        visibility: visible;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            &__img {
                width: 100%;
                height: 29rem;
                border-radius: 20px;
                img {
                    @include img-fit;
                    border-radius: 20px;
                }
            }
            .msc-product {
                display: flex !important;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 15px 0 10px;
                gap: 10px;
                width: 100%;

                .msc-product__img {
                    width: 2.5rem;
                    height: 2.5rem;
                    svg {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
                h2 {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.8rem;
                    line-height: 150%;
                    /* identical to box height, or 27px */

                    color: #122553;
                    text-align: left;
                    margin: 0;
                    text-transform: capitalize;
                    @include text-ellipsis(2);
                    width:90%;
                }
            }
            &__desc {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 500;
                font-size: 1.2rem;
                line-height: 150%;
                color: #242d35;
                text-align: left;
                width: 100%;
                @include text-ellipsis(2);
            }
            &__cta {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                color: #fff;
                background: #fbad36;
                position: absolute;
                bottom: 0;
                left: 0;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                height: 45px;

                transition: height 0.3s ease-in-out;
                @include breakpoint(md1) {
                    height: 16px;
                }
                a {
                    width: 100%;
                    text-decoration: none;
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 100%;
                    /* identical to box height, or 14px */

                    color: #ffffff;
                    display: flex;
                    justify-content: center;
                    visibility: visible;
                    @include breakpoint(md1) {
                        visibility: hidden;
                    }
                }
                border: none;
            }
        }
    }
    // slick dots
    .slick-dots {
        margin-bottom: -20px;
        li {
            margin: 0;
            button {
                &:before {
                    height: 1.6rem;
                    width: 1.6rem;
                    border: none;
                    background: #ffffff;
                    mix-blend-mode: normal;
                    opacity: 0.2;
                    border-radius: 12px;
                    color: transparent;
                }
            }
        }
        li.slick-active {
            button {
                &:before {
                    color: #919191;
                    opacity: 1;
                    content: '';
                }
            }
        }
    }
}

.festival-activities-content {
    .slick-slide {
        float: left;
        [dir="rtl"] & {
            float: left !important;
        }
    }
}