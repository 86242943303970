.book-tickets-now-container {
    background: $white;
    padding: 60px 0 80px;
    .book-tickets-now {
        .ms-content-block__details {
            display: none;
        }
        .ms-content-block__details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 85%;
            margin: 0 auto;
            .ms-content-block__title {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 400;
                font-size: 3.4rem;
                line-height: 140%;
                letter-spacing: -0.3px;
                text-transform: uppercase;
                color: $home_maroon;
                margin-bottom: 40px;
                position: relative;
                text-align: center;
                &:before {
                    @include bottom-line;
                    bottom: -10px;
                    left: 30%;
                    @include breakpoint(md1) {
                        left: 40%;
                    }
                }

                @include breakpoint(xxl) {
                    font-size: 4rem;
                }
            }
            .ms-content-block__text {
                width: 100%;
                display: flex;
                justify-content: center;
                text-align: center;
                @include breakpoint(md1) {
                    width: 50%;
                }
                p {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 140%;
                    /* or 20px */

                    text-align: center;
                    letter-spacing: -0.3px;

                    color: $primary_blue;
                    margin-bottom: 20px;
                }
            }
            .ms-content-block__cta {
                @include cta_styling($primary-yellow);
                color: $white;
                a {
                    color: $primary-blue;
                    text-decoration: none;
                }
            }
        }
    }
}
