.press-release-list {
    padding-top: 5rem;
    width: 92%;
    margin: 0 auto;
    > .row {
        gap: 2%;
        justify-content: center;
        > .col-12 {
            width: auto;
            width: 100%;
            @include breakpoint(sm) {
                width: 49%;
            }
            @include breakpoint(md1) {
                width: 32%;
            }
        }
    }
    .default-container {
        position: relative;
        > .row {
            > .col-12 {
                &:first-child {
                    position: absolute !important;
                    top: 35rem;
                    z-index: 1;
                    left: 20px;
                    width: 92%;
                    // background: red;
                }

                .ms-text-block {
                    h5 {
                        padding-left: 30px;
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 1.8rem;
                        line-height: 150%;
                        color: #373f47;
                        margin-bottom: 0;
                        background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB1Dsl');
                        background-repeat: no-repeat;
                        @include breakpoint(md1) {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }
    .ms-content-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        gap: 20px;
        margin-bottom: 5rem;
        // width: 410px;
        // height: 665px;

        /* Base/background white */

        background: #ffffff;
        /* Gray/200 */

        border: 1px solid #eaecee;
        border-radius: 12px;

        .ms-content-block__image {
            height: 30rem;
            width: 100%;
            border-radius: 12px;
            img {
                border-radius: 12px;
                @include img-fit;
            }
        }
        h3 {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 2.4rem;
            line-height: 150%;
            margin-top: 4rem;
            margin-bottom: 2rem;
            color: #373f47;
            height: auto;
            @include text-ellipsis(3);

            @include breakpoint(sm) {
                height: 11rem;
            }
            @include breakpoint(xxxl) {
                @include text-ellipsis(2);
                height: 7rem;
            }
        }
        p {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 150%;
            color: #373f47;
            margin-bottom: 2rem;
            @include text-ellipsis(3);
            min-height: 7.5rem;
            // @include breakpoint(xxxl) {
            //     @include text-ellipsis(3);
            // }
        }
        .ms-content-block__cta {
            a {
                @include cta_styling($primary-blue);
                border-radius: 24px;
                font-size: 1.6rem;
                padding: 10px 10px;
                width: 115px;
            }
        }
    }
}
