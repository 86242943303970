.entry-attractions {
    .entry-attractions-btns-div {
        align-items: flex-end;
        .newyear-bookticketnow {
            color: $primary-blue;
            background-color: #ffd367;
        }
        button {
            &:active,
            &:hover,
            &:focus {
                .btn-img {
                    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB3M8c?pubver=4');
                }
            }
            .btn-img {
                background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB3JwR?pubver=3');
                background-repeat: no-repeat;
                background-position: left center;
                background-size: contain;
                height: 80%;
                width: 20%;

                @include breakpoint(md) {
                    margin-bottom: 20px;
                    height: 100%;
                    width: 30%;
                }
            }
        }
        .img-gallery-btn.active {
            .btn-img {
                background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB3M8c?pubver=4');
            }
        }
        .video-gallery-btn {
            .btn-img {
                background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB3M89?pubver=1');
            }
            &:active,
            &:hover,
            &:focus {
                .btn-img {
                    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB3OJr?pubver=1');
                }
            }
        }
        .video-gallery-btn.active {
            .btn-img {
                background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB3OJr?pubver=1') !important;
            }
        }
    }
}
