// Hide double scroll bar
// body.modal-open {
//     position: fixed;
// }
.msc-modal.show {
    @keyframes slideDown {
        from {
            top: -100%;
        }
        to {
            top: 0;
        }
    }
    animation: slideDown 0.5s ease-in-out !important;
}
.msc-modal__dialog.images-modal {
    max-width: 100vw !important;
    max-height: 100%;
    height: 100vh;
    margin: 0 auto;

    .msc-modal__content {
        padding: 4rem 6rem;
        height: 100%;
        background: rgba(255, 255, 255, 0.9);
        box-shadow: none;
        border: none;
        .msc-modal__header {
            border: none;
            margin-bottom: 0;
            .msc-modal__title {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding-bottom: 30px;
                align-items: center;
                h3 {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 4rem;
                    line-height: 140%;
                    /* identical to box height, or 56px */

                    letter-spacing: -0.3px;
                    text-transform: uppercase;

                    /* Primary/color-primary-blue */

                    color: #111f48;
                }
                button {
                    outline: none;
                    border: none;
                    background: transparent;
                    width: 3.5rem;
                    height: 3.5rem;
                    img {
                        @include img-fit;
                        object-fit: contain;
                    }
                }
            }
        }
    }
    .msc-modal__body {
        max-height: 100%;
        .szf-category-details__gallery-container {
            display: flex;
            flex-wrap: wrap;
            column-gap: 4rem;
            row-gap: 4rem;
            justify-content: center;
            .szf-category-details__gallery-img {
                border-radius: 4px;
                height: 22rem;
                width: 100%;
                cursor: pointer;
                overflow: hidden;
                @include breakpoint(sm) {
                    width: calc(51% - 3rem);
                }
                @include breakpoint(md) {
                    width: calc(33% - 3rem);
                }
                @include breakpoint(md1) {
                    width: calc(25% - 3rem);
                }
                &:hover img {
                    transform: scale(1.3);
                }
                img {
                    @include img-fit;
                    border-radius: 4px;
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }
}

.images-modal + .msc-modal__backdrop.show {
    background: rgba(255, 255, 255, 0.9);
    opacity: 1;
}
