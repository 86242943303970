//---------------------------------- Video Galllery----------------------------------//
.video-gallery {
    padding-bottom: 4rem;
    padding-top: 5rem;
    @include breakpoint(sm) {
        padding-top: 7rem;
    }
    @include breakpoint(md1) {
        padding-top: 9rem;
    }
}
.image-gallery {
    [class^='col-'] {
        .ms-video-player {
            position: relative;
            height: 100%;

            // ===============show details on hover ===========================//
            &:hover {
                .ms-video-player__details {
                    width: 100%;
                    height: 12rem;
                    border-left: 6px solid #fff;
                    border-right: 6px solid #fff;
                    border-bottom: 6px solid #fff;
                    visibility: visible;
                    padding: 0 16px 16px;
                }
            }
            * {
                transition: height 0.3s ease-in-out;
            }
            > div {
                height: 100%;
                .msc-videoplayer {
                    height: 100%;

                    .msc-videoplayer__wrapper {
                        overflow: hidden;
                        border: 6px solid #fff;
                        height: 100%;
                        video {
                            @include img-fit;
                            position: relative;
                        }
                        .msc-videoplayer__controls {
                            .msc-videoplayer__controls__muteunmutebtn__container {
                                position: absolute;
                                top: 16px;
                                left: 16px;
                                .msc-videoplayer__controls__mutebtn {
                                    width: 30px;
                                    height: 30px;
                                    width: 30px;
                                    height: 30px;

                                    border: 0;
                                }
                                .msc-videoplayer__controls__mutebtn[aria-hidden='true'] {
                                    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB3tVJ?pubver=1');
                                    background-repeat: no-repeat;
                                    background-color: transparent;
                                    background-size: 2.6rem !important;
                                }
                                .msc-videoplayer__controls__mutebtn[aria-hidden='false'] {
                                    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB3rk1?pubver=1');
                                    background-repeat: no-repeat;
                                    background-color: transparent;
                                    background-size: 2.6rem !important;
                                }
                                button.msc-videoplayer__controls__unmutebtn {
                                    width: 0px;
                                    height: 0px;
                                    visibility: hidden;
                                }
                            }
                        }
                    }
                }
            }
            .ms-video-player__details {
                margin: 0;

                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;

                position: absolute;
                bottom: 0;
                top: auto;

                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
                // Show details on mobile
                width: 100%;
                height: 12rem;
                border-left: 6px solid #fff;
                border-right: 6px solid #fff;
                border-bottom: 6px solid #fff;
                visibility: visible;
                padding: 0 16px 16px;

                // ============================Hide details on > 991px screens ================================//
                @include breakpoint(md1) {
                    width: 0;
                    height: 0;
                    border-color: transparent;
                    padding: 0;
                    visibility: hidden;
                    transition: height 0.3s ease-in-out;
                }

                .ms-video-player__details__title {
                    font-family: $font-family-primary;
                    font-size: 1.8rem;
                    line-height: 140%;
                    width: 100%;

                    color: #fff;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 600;

                    @include breakpoint(md1) {
                        margin-bottom: 0.8rem;
                    }
                }
                .ms-video-player__details__cta__link {
                    background: #808080ba;
                    border: none;
                    text-decoration: none;
                    margin-top: 0 !important;
                    height: 4rem;
                    color: #fff;
                    padding: 0 5rem 0 10px;
                    font-size: 1.3rem;
                    font-weight: 400;
                    margin-bottom: 1rem;
                    font-style: normal;
                    font-weight: 300;
                    font-family: $font-family-primary;
                    text-transform: capitalize;

                    border-radius: 100px;
                    background: rgba(255, 255, 255, 0.2);
                    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
                    -webkit-backdrop-filter: blur(5px);
                    backdrop-filter: blur(15px);
                    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBNqA?pubver=1');
                    background-repeat: no-repeat;
                    background-position: 85% center;
                    background-size: 18px;
                    &:focus {
                        &:before {
                            border: none;
                            outline: none;
                        }
                    }
                    &:hover {
                        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
                    }
                }
                .ms-video-player__details__additional-content {
                    margin: 0 !important;
                }
            }
        }
        .image-gallery-col1 {
            > .row {
                > .col-12 {
                    &:nth-child(1) {
                        .msc-videoplayer__wrapper {
                            height: 100%;
                            @include breakpoint(md1) {
                                height: 360px;
                            }
                            .ms-video-player {
                                .ms-video-player__details {
                                    flex-direction: column !important;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    align-items: center;
                                }
                            }
                        }
                    }
                }
            }
        }

        .image-gallery-col1-row2 {
            .row {
                height: 220px;
                .col-12 {
                    &:first-child {
                        height: 220px;
                    }
                    &:nth-child(2) {
                        height: 220px;
                    }
                }
            }
        }
    }
    .image-gallery-col2 {
        .row {
            .col-12 {
                &:nth-child(1) {
                    height: auto;
                    @include breakpoint(md1) {
                        height: 220px;
                    }
                }
                &:nth-child(2) {
                    height: 70rem;
                    @include breakpoint(md1) {
                        height: 360px;
                    }
                }
            }
        }
    }

    .image-gallery-col2 {
        .ms-video-player {
            height: 100%;
        }
    }
}

.msc-videoplayer__playpausetrigger {
    display: none;
}

.msc-videoplayer__screenreader,
.msc-videoplayer__controls__time__screenreader {
    visibility: hidden;
    width: 0;
    height: 0;
}

.msc-videoplayer__errormessage,
.msc-videoplayer__spinner,
// .ms-video-player__details__additional-content,
// .msc-videoplayer__controls__fullscreenbtn__container,
.msc-videoplayer__controls__volumeslider__container,
.msc-videoplayer__controls__closedcaption__container,
.msc-videoplayer__controls__playpausebtn__container {
    display: none;
}

.image-gallery {
    [class^='col-'] {
        .image-gallery-col1-row2 {
            .row {
                height: 220px;
                .col-12 {
                    &:first-child {
                        height: 220px;
                    }
                    &:nth-child(2) {
                        height: 220px;
                    }
                }
            }
        }
        .image-gallery-col2 {
            .row {
                .col-12 {
                    &:nth-child(1) {
                        height: auto;
                        @include breakpoint(md1) {
                            height: 220px;
                        }
                    }
                    &:nth-child(2) {
                        height: 70rem;
                        @include breakpoint(md1) {
                            height: 360px;
                        }
                    }
                }
            }
        }
    }
}
