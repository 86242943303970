.circular-blue{
    @include cta_styling($primary-blue)
}
.circular-yellow{
    @include cta_styling($primary-yellow)
}
.square-blue{
    @include cta_styling($primary-blue);
    border-radius: 4px;
}
.square-yellow{
    @include cta_styling($primary-yellow);
    border-radius: 4px;
}