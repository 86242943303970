.ms-checkout {
    &__error-message {
        font-family: $font-family-primary !important;
        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/pmtjhhhpmz/imageFileData/MB2HF?pubver=1');
        background-repeat: no-repeat;
        background-position: 3rem center;
        padding:2.2rem 1rem 2.2rem 4rem !important;
        background-size: 2.5rem;
        font-size: 1.6rem !important;
        color: $primary-blue !important;
        text-align: left;
        width: 100% !important;
        margin: 0px 0 20px !important;
        &::before{
            content: '' !important;
        }
    }
    @include custom-smaller-container;
    &__body {
        .ms-checkout__guided-form {
            .ms-checkout__guided-card {
                border-radius: 8px;
                background: #fff;

                /* drop shadow */
                box-shadow: 0px 4px 12px 0px rgba(163, 163, 163, 0.25);
                padding: 4rem;
                margin-bottom: 4rem;
                border: 0;

                &-header {
                    align-items: center;
                    .ms-checkout__guided-card-title-text {
                        font-family: $font-family-primary;
                        font-size: 2.4rem;
                        font-weight: 600;
                        line-height: 140%;
                        letter-spacing: 0.04em;
                        text-align: left;
                        color: #1e1e1e;
                        margin-right: 5px;
                    }
                    button {
                        font-family: $font-family-primary;
                        font-size: 1.8rem;
                        font-weight: 500;
                        line-height: 140%;
                        letter-spacing: 0.04em;
                        text-align: left;
                        color: $primary-blue;
                    }
                }
                &-body {
                    margin-top: 10px;
                    .ms-checkout__guided-card-content {
                        .ms-checkout-delivery-options {
                            .ms-checkout-delivery-options__list {
                                .ms-checkout-delivery-options__option {
                                    input[type='radio'],
                                    input[type='checkbox'] {
                                        accent-color: $primary-blue;
                                        width: 2rem;
                                        height: 2rem;
                                    }
                                    .ms-checkout-delivery-options__description {
                                        max-width: 100%;
                                        width: 100%;
                                        flex: 1;
                                    }
                                    .msc-price.ms-checkout-delivery-options__price {
                                        display: none;
                                    }
                                }
                            }
                        }
                        // ------------------------- Hide billing address -------------------------//
                        .ms-checkout-billing-address {
                            display: none;
                        }
                    }

                    .ms-checkout__guided-card-content {
                        .form-floating,
                        .react-tel-input {
                            margin-bottom: 3rem;
                            > .form-control {
                                height: calc(5rem + 0px);
                                border: 1px solid #79747e;
                                border-radius: 4px;
                                &:focus {
                                    color: #212529;
                                    background-color: #fff;
                                    border-color: $primary-blue;
                                    outline: 0;
                                    box-shadow: 0 0 0 0.05rem $primary-blue;
                                }
                            }
                            .react-tel-input {
                                margin-bottom: 0;
                                > .form-control {
                                    width: 100%;
                                    padding-left: 55px;
                                }
                                .invalidphone-input{
                                    border-color: #dc3545;
                                    ~ .flag-dropdown{
                                        border-color: #dc3545;
                                    }
                                }
                                .flag-dropdown {
                                    background-color: white;
                                    border: 1px solid #79747e;
                                }
                                ~ .invalidphone-label{
                                    color: #dc3545;
                                    font-size: 12px;
                                }
                                ~ label{
                                    margin-top: -12px;
                                    margin-left: 0.7rem;
                                    height: 20px;
                                    background-color: white;
                                    padding: 0;
                                    font-size: 1.15rem;
                                }
                            }
                            .form-control.is-invalid {
                                &:focus {
                                    box-shadow: none;
                                }
                            }
                            label {
                                font-family: $font-family-primary;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 1.6rem;
                                // line-height: 24px;
                                letter-spacing: 0.5px;
                                color: #49454f;

                                // margin-top: -4px;

                                // height: auto;
                                // @include breakpoint(xs) {
                                //     font-size: 15px;
                                // }
                                @include breakpoint(sm) {
                                    font-size: 1.4rem;
                                }
                            }
                        }
                        .form-floating > .form-control:focus ~ label,
                        .form-floating .react-tel-input > .form-control:focus ~ label,
                        .form-floating > .form-control:not(:placeholder-shown) ~ label,
                        .form-floating > .form-select ~ label {
                            transform: scale(0.85) translateY(-0.4rem) translateX(0.75rem) !important;
                            margin-top: -7px;
                        }
                    }
                    .ms-checkout__guided-card-footer {
                        button {
                            @include cta_styling($primary-yellow);
                            text-transform: capitalize;
                            border: none;
                            opacity: 1;
                            @include breakpoint(sm) {
                                width: auto;
                            }
                            &:focus {
                                &:before {
                                    border-color: transparent;
                                }
                            }
                        }
                    }

                    .ms-checkout-section-container__item .ms-text-block {
                        background: $brand_beige;
                        h5 {
                            margin-bottom: 0px;
                            color: #1e1e1e;
                            font-family: $font-family-primary;
                            font-size: 1.6rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }
            .ms-checkout__guided-card.ready {
                .ms-checkout-shipping-address {
                    .msc-address-detail {
                        span {
                            margin-bottom: 5px;
                            color: #1e1e1e;
                            font-family: $font-family-primary;
                            font-size: 1.8rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.96px;
                        }
                        > span {
                            display: flex;
                            flex-direction: column;
                            word-wrap: break-word;
                            > span {
                                // margin-right: 4px;
                                margin-right: 0px;
                            }
                        }
                    }
                }

                .ms-checkout-delivery-options {
                    .ms-checkout-delivery-options__option-selected {
                        .ms-checkout-delivery-options__description {
                            color: #1e1e1e;
                            font-family: $font-family-primary;
                            font-size: 1.8rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            // letter-spacing: 0.96px;
                            max-width: 100%;
                        }
                        .ms-checkout-delivery-options__price {
                            margin-bottom: 5px;
                            color: #1e1e1e;
                            font-family: $font-family-primary;
                            font-size: 1.6rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            display: none;
                            // letter-spacing: 0.96px;
                        }
                    }
                }
            }
            .ms-checkout__guided-card {
                .ms-checkout-shipping-address {
                    &__group-images {
                        display: none !important;
                    }
                }
            }
        }
    }
    &__main {
        padding-left: 0;
        max-width: 100%;
        padding-right: 0;
        min-width: auto !important;
        width: 100%;
        @include breakpoint(sm) {
            min-width: 290px;
        }
        @include breakpoint(md) {
            max-width: calc(100% - 35rem);
            padding-right: 4rem;
        }
        @include breakpoint(md1) {
            max-width: calc(100% - 50rem);
            padding-right: 6.4rem;
        }
        &-control {
            .ms-checkout__btn-place-order {
                @include cta_styling($primary-blue);
                opacity: 1;
                &:hover,
                &:focus,
                &:active {
                    background-color: $primary-blue !important;
                    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
                    border-color: transparent !important;
                    color: #fff !important;
                    &:before {
                        border-color: transparent;
                    }
                }
                @include breakpoint(sm) {
                    width: auto;
                    border: none;
                    text-transform: capitalize;
                    cursor: pointer;
                }
            }
            .btn-primary.disabled,
            .btn-primary:disabled {
                background-color: $primary-blue !important;
                opacity: 0.5;
            }
            .ms-checkout__btn-keep-shopping {
                @include cta_styling($primary-yellow);
                &:focus,
                &:hover,
                &:active {
                    background-color: $primary-yellow !important;
                    background: $primary-yellow;
                    border-color: transparent !important;
                    color: $primary-blue !important;
                    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
                    &::before {
                        border-color: transparent;
                    }
                }
                @include breakpoint(sm) {
                    width: auto;
                    border: none;
                    text-transform: capitalize;
                    cursor: pointer;
                }
            }
        }
    }
    &__side {
        // width: 100%;

        @include breakpoint(md) {
            width: 35rem !important;
        }
        @include breakpoint(md1) {
            width: 50rem !important;
        }
        .msc-order-summary-wrapper {
            border-radius: 8px;
            background: #fff;

            /* drop shadow */
            box-shadow: 0px 4px 12px 0px rgba(163, 163, 163, 0.25);
            padding: 4rem;
            border: none;
            @include breakpoint(md) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            .msc-order-summary__heading {
                padding: 0 0 2rem !important;
                border-bottom: 1px solid #e8d6c0;
                margin-bottom: 2rem;
                color: #1e1e1e;
                font-family: $font-family-primary;
                font-size: 2.4rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.96px;
            }
            .msc-order-summary__items {
                margin: 0 !important;
                p {
                    color: #1e1e1e;
                    font-family: $font-family-primary;
                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.64px;
                    margin-bottom: 2rem;
                    padding: 0;
                }
                .msc-order-summary__line-total {
                    border-top: 1px solid #e8d6c0;
                    margin: 0;
                    padding: 2rem 0 0 !important;
                }
            }
        }

        .ms-checkout__side-control-first {
            border-radius: 8px;
            background: #fff;
            box-shadow: 0px 4px 12px 0px rgba(163, 163, 163, 0.25);
            padding: 4rem;
            border: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            button {
                @include cta_styling($primary-blue);
                width: 100% !important;

                @include breakpoint(sm) {
                    // width: auto;
                    border: none;
                    text-transform: capitalize;
                    cursor: pointer;
                }
            }
            button.disabled {
                background-color: #13294b !important;
                opacity: 0.5 !important;
            }
            a {
                @include cta_styling($primary-yellow);
                width: 100% !important;
                @include breakpoint(sm) {
                    // width: auto;
                    border: none;
                    text-transform: capitalize;
                    cursor: pointer;
                }
            }
        }
        .ms-checkout__line-items.multiple-pickup-enabled {
            border-radius: 8px;
            background: #fff;
            box-shadow: 0px 4px 12px 0px rgba(163, 163, 163, 0.25);
            padding: 4rem;
            border: none;
            .ms-checkout__line-items-header {
                border-bottom: 1px solid #e8d6c0;
                margin: 0 0 2rem;
                padding: 0 0 2rem !important;
                .ms-checkout__line-items-heading {
                    font-family: $font-family-primary;
                    font-size: 2.4rem;
                    font-weight: 600;
                    line-height: 140%;
                    letter-spacing: 0.04em;
                    text-align: left;
                }
                .ms-checkout__line-items-edit-cart-link {
                    font-family: $font-family-primary;
                    color: $primary-blue;
                    font-size: 1.4rem;
                    font-weight: bold;
                    line-height: 16px;
                    letter-spacing: 0.04em;
                    text-align: left;
                }
            }
            .ms-checkout__line-items-delivery-group.ship {
                padding: 0;
                // @include breakpoint(sm) {
                //     padding-left: 2rem;
                //     padding-right: 2rem;
                // }
                .ms-checkout__line-items-group-title {
                    font-family: $font-family-primary;
                    font-size: 2rem;
                    font-weight: 500;
                    line-height: 140%;
                    letter-spacing: 0.04em;
                    text-align: left;
                    margin: 2.4rem 0;
                }

                .ms-checkout__line-items-list {
                    max-height: 450px;
                    overflow: auto;
                    .ms-checkout__line-item {
                        .msc-cart-line {
                            @media screen and (min-width: 767px) and (max-width: 991px) {
                                flex-direction: column;
                            }
                            .msc-cart-line__content {
                                @media screen and (min-width: 767px) and (max-width: 991px) {
                                    margin-left: 0;
                                    width: 100%;
                                }
                                .msc-cart-line__product {
                                    .msc-cart-line__product-title {
                                        font-family: $font-family-primary;
                                        font-size: 1.6rem;
                                        font-weight: 500;
                                        line-height: 140%;
                                        letter-spacing: 0.04em;
                                        // text-align: left;
                                    }
                                    .msc-cart-line__product-variants {
                                        font-family: $font-family-primary;
                                        font-size: 1.4rem;
                                        font-weight: 500;
                                        line-height: 140%;
                                        letter-spacing: 0.04em;
                                        text-align: left;
                                        .msc-cart-line__product-variant-item {
                                            .msc-cart-line__product-variant-size {
                                                .name {
                                                    margin-left: 5px;
                                                }
                                            }
                                        }
                                    }
                                    .msc-cart-line-item-product-discount {
                                        * {
                                            font-family: $font-family-primary;
                                            font-size: 1.4rem;
                                            font-weight: 500;
                                            line-height: 140%;
                                            letter-spacing: 0.04em;
                                            text-align: left;
                                            color: $primary_maroon;
                                        }
                                    }
                                }
                                .msc-cart-line__quantity {
                                    label,
                                    span {
                                        font-family: $font-family-primary;
                                        font-size: 1.4rem;
                                        font-weight: 500;
                                        line-height: 140%;
                                        letter-spacing: 0.04em;
                                        text-align: left;
                                    }
                                }
                                .msc-cart-line__product-price {
                                    font-family: $font-family-primary;
                                    font-size: 1.4rem;
                                    font-weight: 500;
                                    line-height: 140%;
                                    letter-spacing: 0.04em;
                                    text-align: left;
                                    .discount-value {
                                        span {
                                            font-family: $font-family-primary;
                                            font-size: 1.4rem;
                                            font-weight: 500;
                                            line-height: 140%;
                                            letter-spacing: 0.04em;
                                        }

                                        .msc-price__strikethrough {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .ms-checkout__side-control-second {
            margin-top: 1rem;
            background: transparent;
            .ms-checkout__btn-place-order {
                @include cta_styling($primary-blue);
                opacity: 1;
                border: 0;
                text-transform: capitalize;
                display: inline-flex;
                margin-right: 5px;
            }
            .ms-checkout__btn-place-order.disabled {
                background-color: $primary-blue;
                opacity: 0.5;
                margin-right: 5px;
            }
            .ms-checkout__btn-keep-shopping {
                @include cta_styling($primary-yellow);
                border: 0;
                text-transform: capitalize;
                display: inline-flex;
            }
        }
    }
}
.ms-checkout__guided-card:nth-of-type(2) {
    display: none;
}