.ms-buybox {
    .ms-buybox__content {
        .ms-buybox__quantity {
            padding: 10px 10px !important;
            border: 1px solid $primary-blue;
            margin: 10px 0 30px;
            position: relative;
            border-radius: 4px;
            height: 6rem;
            // justify-content: center;
            display: flex;
            align-items: center;
            button {
                border: 0;
                background: $primary-blue;
                border-radius: 50px;
                color: #fff;
                width: 3rem;
                height: 3rem;
                display: flex;
                justify-content: center;
                [disabled] {
                    background: #919191;
                }
                span {
                    font-size: 1.6rem;
                }
            }
            input {
                border: 0;
                text-align: center;
                font-size: 2rem;
                font-weight: 400;
                font-family: $font-family-primary;
                color: $primary-blue;
            }
            // -------------------Hide number arrows-------------------//
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type='number'] {
                -moz-appearance: textfield;
            }
            // -------------------Hide number arrows-------------------//

            label {
                position: absolute;
                top: -10px;
                left: 10px;
                background: #fff;
                padding: 0 4px;
                font-size: 1.2rem;
                z-index: 1;
                font-family: $font-family-primary;
                line-height: 140%;
                font-weight: 400;
                color: #000;
            }
            > div {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }
        .ms-buybox__add-to-cart-container {
            padding-top: 1rem;
            .ms-checkout-payment-instrument__error-message{
                font-family: $font-family-primary;
                margin-left: 0;
                font-size: 1.6rem;
                margin-bottom: 2rem;
            }
            .msc-add-to-cart {
                // background: $primary-yellow;
                // width: auto;
                text-transform: capitalize;
                // font-size: 1.6rem;
                // font-weight: 600;
                // color: $primary-blue;
                @include cta_styling($primary-yellow);
                border: 0;
                &:focus {
                    &:before {
                        border: 0;
                    }
                }
            }
        }
    }
    .buybox-total-count {
        h3 {
            color: #1e1e1e;
            font-family: $font-family-primary;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 0;
        }
    }
}
