.ms-promo-banner.top_banner {
    height: $header-height;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    // top: 0;
    z-index: 21;
    background: $brand_beige;
    width: 100%;
}


.ms-promo-banner__text{
    color:$primary_blue;
}
header.ms-header.header_szf {
    // top: 90px;
    // position: relative;
}
.header_szf {
    position: relative !important;
    // top: $header-height;

    
}

.szf-solid-header {
    position: fixed !important;
    top: 0 !important;
    @keyframes slideDown {
        from {
            top: -100%;
        }
        to {
            top: 0;
        }
    }
    animation: slideDown 0.5s ease-in-out !important;
}
.main-header-tag + #main {
    padding-top: $header-height;
}
.ms-promo-banner__content {
    display: flex;
    justify-content: center;
}

.ms-promo-banner__text {
    background-image: url("https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB3rjR?pubver=0");
    background-repeat: no-repeat;
    background-position: 0 center;
    padding-left: 5rem;
    padding-right: 1rem;
    background-size: 3.3rem;
    font-size: 1.6rem !important;
    text-align: center;
    color: $primary_blue !important;
    padding-right: 1rem;
    @include breakpoint(md1){
    background-size: contain;

    }
}
.ms-header-open{
    position: fixed !important;
}
.ms-promo-banner.top_banner * {
    text-decoration: none;
}