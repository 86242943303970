.invest-left {
    form {
        display: flex;
        flex-wrap: wrap;
        column-gap: 4%;
        > div {
            // margin: 0 auto;
            width: 100%;

            &:nth-last-child(4) {
                width: 100%;
            }

            @include breakpoint(sm) {
                width: 48%;
            }
        }
        .form-check {
            width: 10;
            width: 100%;
        }
    }
}
.invest-right {
    * {
        height: 100%;
        border-radius: 8px;
    }
    padding: 0 !important;
    .ms-content-block__image {
        height: 30rem;
        width: auto;
        @include breakpoint(md1) {
            height: 100%;
            width: 100%;
        }
        img {
            width: 100%;
            /* height: auto; */
            object-fit: cover;
            object-position: center;
        }
    }
}
.form-with-details .col-auto:has(.invest-right) {
    @include breakpoint(md1, 'max-width') {
        width: 100%;
    }
}
