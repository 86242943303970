.coming-soon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 470px);
    width: 50%;
    margin: 10px auto 7rem;
    @include breakpoint(xl) {
        min-height: calc(100vh - 390px);
    }
    .ms-content-block__details {
        text-align: center;
        h2 {
            font-size: 2.4rem;
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            color: rgba(30, 30, 30, 1);
            margin: 0;
        }
        .coming-soon-img {
            width: 33rem;
            height: 22rem;
            margin: 0 auto;
            img {
                @include img-fit;
            }
        }

        p {
            font-size: 2rem;
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            color: rgba(30, 30, 30, 1);
            margin: 10px 0;
            text-align: center;
        }
    }
}
