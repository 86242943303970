.terms-container {
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(163, 163, 163, 0.25);
    padding: 4.5rem 3rem !important;
    margin: 6rem auto;

    border-radius: 8px;

    .english-terms {
        padding-right: 25px;

        ul {
            li {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 400;
                font-size: 1.6rem;
                line-height: 19px;
                text-align: justify;
                letter-spacing: 0.04em;

                color: #1e1e1e;
                margin-bottom: 1rem;
                p{
                    margin-bottom: 0;
                }
            }
        }
    }
    .arabic-terms {
        direction: rtl;
        padding-left: 25px;
        ul {
            li {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 19px;
                text-align: justify;
                letter-spacing: 0.04em;

                color: #1e1e1e;
                margin-bottom: 1rem;
            }
        }
    }
}
