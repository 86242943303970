.bluish-container,
.yellowish-container {
    background: #111f48;
    border-radius: 0px;
    margin-top: 6rem;
    background-repeat: no-repeat;
    width: 100%;
    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2650?pubver=1');
    min-height: auto;
    background-position: right center;
    background-size: contain;
    padding: 4.5rem 5rem !important;
    @include breakpoint(sm){
    border-radius: 24px;

    }
    .ms-content-block {
        .ms-content-block__title {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 3rem;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-transform: capitalize;
            color: #ffffff;
            margin-bottom: 10px;
            @include breakpoint(md1){
                font-size: 3rem;
                }
        }
        .ms-content-block__text {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-transform: capitalize;
            color: $primary-yellow;
            @include breakpoint(md1){
            font-size: 2.4rem;
            }
        }
    }
}
.yellowish-container {
    background: $primary_yellow;
    margin-top: 0rem;
    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB28Gk?pubver=1');
    background-repeat: no-repeat;
    min-height: auto;
    background-position: 100% 0%;
    background-size: 70%;

    @include breakpoint(sm){
    margin-top: 5rem;
    }
    .ms-content-block {
        .ms-content-block__title {
            color: #945042;
        }
        .ms-content-block__text {
            color: #0d1020;

            ol,
            ul {
                padding-left: 4rem;
                margin-bottom: 6rem;
            }
        }
    }
    .coupons-container {
        > .row {
            gap: 4rem;
            justify-content:space-between ;
            @include breakpoint(md1){
               gap:6rem;
            }
            > [class^='col-'] {
                
                @include breakpoint(md1){
                    width: 46% !important;
                }
            }
        }
        .ms-content-block {
            display: flex !important;
            flex-direction: column-reverse;
            justify-content: space-between;
            height: 100%;
            .ms-content-block__image {
                max-height: 100%;
                width: 100%;
                @include breakpoint(md1){
                    max-height: 25rem;
                }
                img {
                    @include img-fit;
                    object-fit: contain;
                }
            }
            .ms-content-block__title {
                color: $primary-blue !important;
            }
            .ms-content-block__text {
                color: $primary-maroon !important;
            }
        }
    }
}
