.entry-attractions-btns-div {
    button {
        @include breakpoint(md) {
            width: 390px !important;
        }
    }
}
.book-tickets-container {
    @include custom-container;
    margin-bottom: 7rem;
    .entry-attractions-btns-div {
        width: 100%;
        button {
            @include breakpoint(md) {
                width: 380px !important;
            }
        }
    }
    .ms-tab-content-section {
        position: relative;
        padding: 5rem;
        border-radius: 8px;
        background: #fff;
        /* drop shadow */
        box-shadow: 0px 4px 12px 0px rgba(163, 163, 163, 0.25);
    }
}
.ms-buybox.ticket-buybox {
    @include custom-container;
    position: relative;
    padding: 3rem 2.4rem;
    border-radius: 8px;
    background: #fff;
    /* drop shadow */
    box-shadow: 0px 4px 12px 0px rgba(163, 163, 163, 0.25);
    gap: 4rem;
    margin-bottom: 4rem;
    flex-direction: column;
    @include breakpoint(md) {
        padding: 5rem;
        margin-bottom: 6rem;
    }
    @include breakpoint(md1) {
        margin-bottom: 6rem;
        flex-direction: row;
    }
    // --------------image of product and content-------------------------//
    .ms-buybox__media-gallery-div {
        width: 100%;
        @include breakpoint(md1) {
            width: 40%;
        }
        .ms-buybox__media-gallery {
            padding-right: 0;
            .ms-media-gallery {
                margin-bottom: 1.5rem;
                .ms-media-gallery__thumbnails-container {
                    .msc-ss-carousel-vert {
                        width: 100%;
                    }
                }
            }
            .ms-media-gallery.vertical {
                @include breakpoint(md1, max-width) {
                    padding-bottom: 10px;
                }
                @include breakpoint(md, max-width) {
                    padding-top: 42rem;
                }
                @include breakpoint(sm, max-width) {
                    padding-top: 38rem;
                }
                @include breakpoint(xs, max-width) {
                    padding-top: 21rem;
                }

                .ms-media-gallery__carousel {
                    @include breakpoint(md1, max-width) {
                        margin-top: 6rem;
                    }
                    @include breakpoint(md, max-width) {
                        width: calc(100% - 4rem);
                        left: 2rem;
                        top: 2rem;
                        height: 40rem;
                    }
                    @include breakpoint(sm, max-width) {
                        height: 36rem;
                    }
                    @include breakpoint(xs, max-width) {
                        height: 21rem;
                    }
                }
            }

            .msc-flipper {
                display: none;
            }
            ul.msc-ss-carousel-vert-slide {
                margin-top: 5rem;
                @include breakpoint(md) {
                    margin-top: 7rem;
                }
                li {
                    width: 100% !important;
                    height: 25rem !important;
                    @include breakpoint(xs) {
                        height: 30rem !important;
                    }
                    @include breakpoint(sm) {
                        height: 32rem !important;
                    }
                    @include breakpoint(md) {
                        height: 40rem !important;
                    }
                    @include breakpoint(md1) {
                        height: 42rem !important;
                    }
                    border-radius: 8px;
                    img {
                        @include img-fit;
                        border-radius: 8px;
                        // background:red;
                    }
                }
            }
        }
        .ms-buybox__product-description {
            font-family: $font-family-primary;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.3px;
            // text-align: left;
            margin: 5px 0;
            color: #122553;
            @include breakpoint(md1) {
                margin-bottom: 36px !important;
            }
        }
    }

    .ms-buybox__content {
        width: auto;
        @include breakpoint(md1) {
            padding-top: 6rem;
        }
        @include breakpoint(md1) {
            width: 60%;
            // padding-top: 0;
        }

        .szf-title-with-price {
            position: absolute;
            left: 2.4rem;
            top: 2.2rem;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            width: 100%;
            @include breakpoint(sm) {
                flex-direction: row;
                top: 3rem;
            }
            @include breakpoint(md) {
                left: 5rem;
                top: 5rem;
            }
            @include breakpoint(md1) {
                width: auto;
            }
            .ms-buybox__product-title {
                font-family: $font-family-primary;
                font-size: 2.6rem;
                font-weight: 500;
                line-height: 140%;
                letter-spacing: 0.04em;
                text-align: left;
                color: rgba(30, 30, 30, 1);
                padding-bottom: 0;
                margin: 0 10px 0 0;
                // min-width: 60%;
                @include breakpoint(md) {
                    font-size: 3.2rem;
                }
            }
            .msc-price {
                flex-direction: row;
                &__actual {
                    font-family: $font-family-primary;
                    font-size: 1.8rem;
                    font-weight: 500;
                    line-height: 140%;
                    letter-spacing: 0.04em;
                    text-align: left;
                    color: rgba(30, 30, 30, 1);
                    position: relative;
                    // padding: 0 5px;
                    @include breakpoint(md) {
                        font-size: 2rem;
                    }
                    // &:before {
                    //     position: absolute;
                    //     content: '(';
                    //     left: 0;
                    // }
                    // &:after {
                    //     position: absolute;
                    //     content: ')';
                    //     right: 0;
                    // }
                }
                &__strikethrough {
                    font-family: $font-family-primary;
                    font-size: 1.8rem;
                    font-weight: 500;
                    line-height: 140%;
                    letter-spacing: 0.04em;
                    text-align: left;
                    color: #9e9e9e;
                }
            }
        }
    }
    // ------------------- Remove Extra image of product / carousel -------------------//
    .ms-media-gallery__carousel.msc-carousel {
        @include breakpoint(md1) {
            display: none;
        }
    }
}
// ----------------------------Hide add to wishlist---------------------------------//
.msc-add-to-wishlist {
    display: none !important;
}

// ------------------ Temporarily Removed ------------------  //
// .ms-buybox__configure,
// .ms-buybox__product-unit-of-measure {
//     display: none;
// }
