.szf-category-details__event-schedule-container {
    display: flex;
    flex-direction: column;
    gap: 4.5rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
    @include breakpoint(xs){
        flex-direction: row;
    }
    .szf-category-details__event-schedule {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2.4rem;
        // gap: 2rem;
        width: 100%;
        height: 20rem;
        background: #ffffff;
        border: 1px solid #eaecee;
        border-radius: 12px;
        @include breakpoint(sm){
            width: 40rem;
        }
        img {
            @include img-fit;
            object-fit: contain;
            height: 6rem;
            @include breakpoint(sm){
                height: 8rem;
            }
        }
        p {
            margin: 0;
            &:nth-child(2) {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 600;
                font-size: 2rem;
                line-height: 150%;
                text-align: center;
                color: #373f47;
                margin-top: 2rem;
            }
            &:last-child {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 600;
                font-size: 1.4rem;
                line-height: 150%;
                text-align: center;
                color: #373f47;
            }
        }
    }
}
