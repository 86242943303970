;
@import 'bootstrap/scss/bootstrap';

$brandPrimaryColor: #ffffff;
$brandSecondaryColor: #cccccc;
$backgroundDefaultColor: #ffffff;
$textDefaultColor: #333333;
$bodyFont: Arial;
$displayFont: Impact;
$borderRadius: 4;
$iconStyle: Line;
$categoryColor: #000;
$expandedCategoryColor: #000;

:root {
    --brandPrimaryColor: #{brandPrimaryColor};
    --brandSecondaryColor: #{brandSecondaryColor};
    --backgroundDefaultColor: #{$backgroundDefaultColor};
    --textDefaultColor: #{textDefaultColor};
    --bodyFont: #{bodyFont};
    --displayFont: #{displayFont};
    --borderRadius: #{borderRadius};
    --iconStyle: #{iconStyle};
    --categoryColor: #{categoryColor};
    --expandedCategoryColor: #{expandedCategoryColor};
}

body {
    background-color: var(--backgroundDefaultColor);
}
// ------------------------------------- //

@import '00-abstract/index';
@import '01-base/index';
@import '02-generic/index';
@import '03-components/index';

@import '09-fabrikam/index.scss'; //----Fabrikam
@import '04-modules/index';

@import '07-carousel/checkout.scss';

@import '05-layout/index';
// @import "06-fonts/index";
@import '07-carousel/carousel';
@import '07-carousel/product-collection';
@import '07-carousel/slick-theme';
@import '07-carousel/slick';
@import '08-translation/all';
@import '07-carousel/phone-and-country-code-input';
@import '07-carousel/modal';
@import '07-carousel/tabs.scss';
@import '07-carousel/buybox.scss';
// @import '07-carousel/search-result-container.scss';
// @import '07-carousel/checkout.scss';
@import '07-carousel/checkout-apple-pay.scss';
@import '07-carousel/checkout-billing-address.scss';
@import '07-carousel/checkout-customer-account.scss';
@import '07-carousel/checkout-delivery-options.scss';
@import '07-carousel/checkout-express.scss';
@import '07-carousel/checkout-gift-card.scss';
@import '07-carousel/checkout-guest-profile.scss';
@import '07-carousel/checkout-loyalty.scss';
@import '07-carousel/checkout-payment-instrument.scss';
@import '07-carousel/checkout-pickup.scss';
@import '07-carousel/checkout-shipping-address.scss';
@import '07-carousel/checkout-terms-and-conditions.scss';
@import '07-carousel/mini-cart.scss';
@import '07-carousel/promotion-banner.scss';
@import '07-carousel/video-player.scss';
@import '07-carousel/cart.scss';
@import '07-carousel/order-summary.scss';
// @import '07-carousel/refine-menu';









