$szf-font-path: '../../../szf-fonts/fonts/';

@font-face {
    font-family: 'Cairo';
    src: url('#{$szf-font-path}Cairo-Bold.eot');
    src: url('#{$szf-font-path}Cairo-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$szf-font-path}Cairo-Bold.woff2') format('woff2'),
        url('#{$szf-font-path}Cairo-Bold.woff') format('woff'),
        url('#{$szf-font-path}Cairo-Bold.ttf') format('truetype'),
        url('#{$szf-font-path}Cairo-Bold.svg#Cairo-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('#{$szf-font-path}Cairo-Black.eot');
    src: url('#{$szf-font-path}Cairo-Black.eot?#iefix') format('embedded-opentype'),
        url('#{$szf-font-path}Cairo-Black.woff2') format('woff2'),
        url('#{$szf-font-path}Cairo-Black.woff') format('woff'),
        url('#{$szf-font-path}Cairo-Black.ttf') format('truetype'),
        url('#{$szf-font-path}Cairo-Black.svg#Cairo-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('#{$szf-font-path}Cairo-ExtraBold.eot');
    src: url('#{$szf-font-path}Cairo-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('#{$szf-font-path}Cairo-ExtraBold.woff2') format('woff2'),
        url('#{$szf-font-path}Cairo-ExtraBold.woff') format('woff'),
        url('#{$szf-font-path}Cairo-ExtraBold.ttf') format('truetype'),
        url('#{$szf-font-path}Cairo-ExtraBold.svg#Cairo-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('#{$szf-font-path}Cairo-ExtraLight.eot');
    src: url('#{$szf-font-path}Cairo-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('#{$szf-font-path}Cairo-ExtraLight.woff2') format('woff2'),
        url('#{$szf-font-path}Cairo-ExtraLight.woff') format('woff'),
        url('#{$szf-font-path}Cairo-ExtraLight.ttf') format('truetype'),
        url('#{$szf-font-path}Cairo-ExtraLight.svg#Cairo-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('#{$szf-font-path}Cairo-Light.eot');
    src: url('#{$szf-font-path}Cairo-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$szf-font-path}Cairo-Light.woff2') format('woff2'),
        url('#{$szf-font-path}Cairo-Light.woff') format('woff'),
        url('#{$szf-font-path}Cairo-Light.ttf') format('truetype'),
        url('#{$szf-font-path}Cairo-Light.svg#Cairo-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('#{$szf-font-path}Cairo-SemiBold.eot');
    src: url('#{$szf-font-path}Cairo-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('#{$szf-font-path}Cairo-SemiBold.woff2') format('woff2'),
        url('#{$szf-font-path}Cairo-SemiBold.woff') format('woff'),
        url('#{$szf-font-path}Cairo-SemiBold.ttf') format('truetype'),
        url('#{$szf-font-path}Cairo-SemiBold.svg#Cairo-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('#{$szf-font-path}Cairo-Medium.eot');
    src: url('#{$szf-font-path}Cairo-Medium.eot?#iefix') format('embedded-opentype'),
        url('#{$szf-font-path}Cairo-Medium.woff2') format('woff2'),
        url('#{$szf-font-path}Cairo-Medium.woff') format('woff'),
        url('#{$szf-font-path}Cairo-Medium.ttf') format('truetype'),
        url('#{$szf-font-path}Cairo-Medium.svg#Cairo-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('#{$szf-font-path}Cairo-Regular.eot');
    src: url('#{$szf-font-path}Cairo-Regular.eot?#iefix') format('embedded-opentype'),
        url('#{$szf-font-path}Cairo-Regular.woff2') format('woff2'),
        url('#{$szf-font-path}Cairo-Regular.woff') format('woff'),
        url('#{$szf-font-path}Cairo-Regular.ttf') format('truetype'),
        url('#{$szf-font-path}Cairo-Regular.svg#Cairo-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}







// /*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 4, 2023 */

// @font-face {
//     font-family: 'bukra';
//     src: url('#{$szf-font-path}29lt-bukra-bold.eot');
//     src: url('#{$szf-font-path}29lt-bukra-regular.eot') format('embedded-opentype'),
//         url('#{$szf-font-path}29lt-bukra-regular.woff2') format('woff2'), url('#{$szf-font-path}29lt-bukra-regular.woff') format('woff'),
//         url('#{$szf-font-path}29lt-bukra-regular.ttf') format('truetype'),
//         url('#{$szf-font-path}29lt-bukra-regular.svg') format('svg');
//     font-weight: 400;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'bukra';
//     src: url('#{$szf-font-path}29lt-bukra-bold.eot');
//     src: url('#{$szf-font-path}29lt-bukra-bold.eot') format('embedded-opentype'),
//         url('#{$szf-font-path}29lt-bukra-bold.woff2') format('woff2'), url('#{$szf-font-path}29lt-bukra-bold.woff') format('woff'),
//         url('#{$szf-font-path}29lt-bukra-bold.ttf') format('truetype'),
//         url('#{$szf-font-path}29lt-bukra-bold.svg') format('svg');
//     font-weight: 700;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'bukra';
//     src: url('#{$szf-font-path}29lt-bukra-light.eot');
//     src: url('#{$szf-font-path}29lt-bukra-light.eot') format('embedded-opentype'),
//         url('#{$szf-font-path}29lt-bukra-light.woff2') format('woff2'), url('#{$szf-font-path}29lt-bukra-light.woff') format('woff'),
//         url('#{$szf-font-path}29lt-bukra-light.ttf') format('truetype'),
//         url('#{$szf-font-path}29lt-bukra-light.svg') format('svg');
//     font-weight: 300;
//     font-style: normal;
// }
