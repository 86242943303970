.info-container {
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 26px;
    gap: 10px;
    background: rgba(255, 211, 103, 0.14);
    border-radius: 12px;
    h5 {
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 140%;
        letter-spacing: -0.3px;
        text-transform: capitalize;
        color: $primary_blue;
        margin-bottom: 0;
    }
}
.fees-container {
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    gap: 30px;

    background: #ffffff;

    border: 1px solid #eaecee;
    border-radius: 12px;
    .ms-content-block__image {
        height: auto;
        width: 6.5rem;
        img {
            @include img-fit;
            object-fit: contain;
        }
    }
    h2 {
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 700;
        font-size: 2rem;
        line-height: 150%;
        /* identical to box height, or 30px */

        /* Secondary-4 */

        color: $primary_blue;

        // &:before {
        //     @include pseudo;
        //     content: 'Entrance Fees: ';
        //     color: #945042;
        // }
         span {
            color: #945042;
        }
    }
    p {
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 150%;
        /* identical to box height, or 21px */

        /* Gray/700 */

        color: $primary_blue;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.info-cards-list {
    > .row {
        justify-content: center;
        > .col-12 {
            max-width: 100%;
            @include breakpoint(sm) {
                max-width: 50%;
            }
            @include breakpoint(md1) {
                max-width: 33.33%;
            }

            .ms-content-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 2.4rem;
                gap: 20px;
                background: #ffffff;
                border: 1px solid #eaecee;
                border-radius: 12px;
                margin-top: 4.5rem;
                padding-top: 13rem;
                justify-content: flex-end;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background: $primary_blue;
                    h2,
                    p {
                        color: #fff !important;
                    }
                }
                .ms-content-block__details {
                    text-align: center;
                    min-height: 90px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    h2 {
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 2rem;
                        line-height: 150%;
                        /* identical to box height, or 30px */

                        text-align: center;

                        /* Gray/700 */

                        color: #373f47;
                    }
                    p {
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 1.4rem;
                        line-height: 150%;
                        /* identical to box height, or 21px */

                        text-align: center;

                        /* Gray/700 */

                        color: #373f47;
                    }
                }
            }
        }
        > .col-12:nth-child(1) {
            .ms-content-block {
                background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB1NRv');
                background-repeat: no-repeat;
                background-position: 50% 40px;

                &:hover {
                    background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB1T48');
                }
            }
        }
        > .col-12:nth-child(2) {
            .ms-content-block {
                background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB1NRy?pubver=1');
                background-repeat: no-repeat;
                background-position: 50% 40px;

                &:hover {
                    background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB1T45');
                }
            }
        }
        > .col-12:nth-child(3) {
            .ms-content-block {
                background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB1QsN?pubver=1');
                background-repeat: no-repeat;
                background-position: 50% 40px;
                &:hover {
                    background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/binary/MB1Lgj');
                }
            }
        }

        > .col-12:nth-child(4) {
            max-width: 100%;
            .ms-content-block {
                flex-direction: row;
                background: #945042;
                height: auto;
                padding-top: 24px;
                .ms-content-block__details {
                    text-align: left;
                    width: calc(100% - 10rem);
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    // padding-top: 24px;
                    h2,
                    p {
                        color: #fff;
                        text-align: left;
                    }
                }
                .ms-content-block__image {
                    width: 10rem;
                    height: auto;
                    img {
                        @include img-fit;
                    }
                }
            }
        }
    }
}
