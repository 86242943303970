.footer_szf {
    > .row {
        > .col-12:nth-child(1) {
            max-width: 100%;

            display: flex;
            padding: 0 3rem;
            // justify-content: center;
            @include breakpoint(md) {
                max-width: 50%;
                padding: 0rem;
                border-right: 1px solid #fff;
            }
            @include breakpoint(md1) {
                max-width: 22%;
            }
            // @include breakpoint(xxl) {
            //     max-width: 22%;
            // }
            // @include breakpoint(xxxl) {
            //     max-width: 22%;
            // }
        }
        > .col-12:nth-child(2) {
            max-width: 100%;
            // border-right: 1px solid #fff;
            display: flex;
            padding: 0 3rem;

            @include breakpoint(md) {
                max-width: 50%;
                justify-content: center;
                padding: 0rem;
            }
            @include breakpoint(md1) {
                max-width: 22%;
                border-right: 1px solid #fff;
                padding: 0 2rem;
            }
            // @include breakpoint(xxl) {
            //     max-width: 22%;
            // }
            // @include breakpoint(xxxl) {
            //     max-width: 22%;
            // }
        }
        > .col-12:nth-child(3) {
            max-width: 100%;

            display: flex;

            padding: 0 3rem;
            @include breakpoint(md) {
                max-width: 50%;
                justify-content: center;
                padding: 0rem;
                border-right: 1px solid #fff;
            }
            @include breakpoint(md1) {
                padding: 0 2rem;
                max-width: 36%;
            }
            // @include breakpoint(xxl) {
            //     max-width: 36%;
            // }
            // @include breakpoint(xxxl) {
            //     max-width: 36%;
            // }

            ul {
                // height: 100%;
                width: 100%;
                // li {
                //     height: 100%;
                // }
            }
        }
        > .col-12:nth-child(4) {
            max-width: 100%;
            // border-right: 1px solid #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            @include breakpoint(md) {
                max-width: 50%;
            }
            @include breakpoint(md1) {
                max-width: 20%;
            }
            // @include breakpoint(xxl) {
            //     max-width: 20%;
            // }
            // @include breakpoint(xxxl) {
            //     max-width: 20%;
            // }
        }
    }
}

.footer_szf {
    // background-color: $primary_blue;
    padding: 1rem 25px 4rem;
    // height: 100%;
    width: 100%;
    @include breakpoint(md) {
        padding: 40px 60px 2rem;
    }

    .ms-footer__list {
        @include list-reset;

        .ms-footer__list-item {
            h2 {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 140%;
                letter-spacing: -0.3px;
                text-transform: capitalize;
                color: #ffffff;
                text-decoration: none;
            }
            h3 {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 140%;
                letter-spacing: -0.3px;
                text-transform: capitalize;
                color: #b7b7b7;
                margin-top: 2rem;
            }
            .rights-text {
                h5 {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.8rem;
                    line-height: 140%;
                    letter-spacing: -0.3px;
                    color: #b7b7b7;
                    margin-top: 2rem;
                    padding-right: 2rem;
                    @include breakpoint(md) {
                        font-size: 1.5rem;
                    }
                }
            }
            .footer_email {
                h6 {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.8rem;
                    line-height: 140%;
                    letter-spacing: -0.3px;
                    color: #b7b7b7;
                    margin-top: 1rem;

                    @include breakpoint(md) {
                        margin-top: 1rem;
                        font-size: 1.8rem;
                    }
                }
            }
            .connect-heading {
                h3 {
                    margin-top: 0;
                }
            }
            .ms-footer__link {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: -0.3px;
                text-transform: capitalize;
                color: #ffffff;
                text-decoration: none;
            }
            .szf_footer_logo {
                height: 50px;
                width: 150px;
                img {
                    @include img-fit;
                }
            }
            .szf_footer--description {
                h6 {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 140%;
                    color: $white;
                    margin-top: 40px;
                }
            }
            .szf_footer--copyright {
                h6 {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 140%;
                    color: $white;
                    margin-top: 20px;
                }
            }

            // -------------- 2nd column -------------------//

            .ms-subscribe {
                margin-bottom: 15px;
                @include breakpoint(md) {
                    margin-bottom: 3.5rem;
                }
                &__details {
                    &__heading {
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.7rem;
                        line-height: 140%;
                        /* or 22px */

                        letter-spacing: -0.3px;
                        text-transform: capitalize;

                        color: #ffffff;
                    }
                }
                form {
                    display: flex;
                    margin: 15px auto 0 0;
                    flex-wrap: wrap;
                    @include breakpoint(md) {
                        margin: 3.5rem auto 0 0;
                    }
                    input {
                        font-family: $font-family-primary;
                        display: flex;
                        align-items: center;
                        padding: 11px 16px;
                        background: $primary_blue;
                        border: 1px solid $primary_yellow;
                        border-radius: 4px;
                        margin-right: 0px;
                        margin-bottom: 10px;
                        color: $white;
                        width: 100%;
                        font-size: 1.8rem;
                        @include no-outline;
                        @include placeholder {
                            color: #fff;
                        }

                        @include breakpoint(md) {
                            width: 60%;
                            margin-right: 10px;
                            margin-bottom: 0px;
                            // font-size: 1.8rem;
                        }
                    }
                    button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 11px 16px;
                        width: 100%;
                        // height: 48px;
                        background: $primary_yellow;
                        border-radius: 4px;
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 1.6rem;
                        line-height: 100%;
                        text-align: center;
                        color: $primary_blue;
                        border: none;

                        @include breakpoint(md) {
                            width: 30%;
                        }
                    }
                }
                .newsletter-error {
                    color: $primary_yellow;
                    font-size: 1.4rem !important;
                    margin-top: 5px;
                }
            }

            // ---------------Footer Img --------------//
            .footer_img {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 155px;
                height: 100%;
                source {
                    display: none;
                }
                img {
                    @include img-fit;
                }
            }
        }
    }
    ul.footer-col2 {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        margin: 3rem 0 4rem;
        @include breakpoint(md1) {
            margin: 0;
            height: 100%;
        }
        > li {
            width: 100%;
            // margin: 0 auto;
            // @include breakpoint(md1) {
            //     width: 88%;
            // }
            // @include breakpoint(xxl) {
            //     width: 80%;
            // }
        }
        // &:before,
        // &:after {
        //     @include pseudo;
        //     background: $white;
        //     left: 0px;
        //     height: 1px;
        //     width: 100%;
        //     top: -20px;
        //     @include breakpoint(md1) {
        //         width: 1px;
        //         height: 80%;
        //         top: 20px;
        //     }
        // }
        // &:after {
        //     right: 30px;

        //     left: auto;
        //     right: auto;
        //     bottom: -20px;
        //     top: auto;

        //     @include breakpoint(md1) {
        //         left: auto;
        //         top: 20px;
        //         right: 20px;
        //     }
        //     @include breakpoint(xxl) {
        //         max-width: 60px;
        //     }
        // }

        ul {
            display: flex !important;
            align-items: center !important;
            flex-direction: row;
            gap: 20px;
            li {
                * {
                    height: 100%;
                }
                a {
                    height: 20px;
                    width: 20px;
                }
                img {
                    @include img-fit;
                    object-fit: contain;
                }
            }
        }
    }
    ul.szf_footer_links {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include breakpoint(sm) {
            flex-direction: row;
            flex-wrap: wrap;
        }
        li {
            position: relative;
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            /* identical to box height, or 20px */
            text-align: right;
            letter-spacing: -0.5px;
            margin-right: 20px;

            &:before {
                @include pseudo;
                background: $white;
                width: 5px;
                height: 5px;
                border-radius: 50px;
                top: 6px;
                left: -12px;
            }
            &:first-child:before {
                // @include pseudo;
                background: $white;
                // width: 5px;
                // height: 5px;
                // border-radius: 50px;
                // top: 6px;
                // left: -12px;
                @include breakpoint(sm) {
                    background: transparent;
                }
            }

            a {
                color: #ffffff;
                text-decoration: none;
            }
        }
    }

    .rights {
        p {
            margin: 0;
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-transform: capitalize;
            color: $primary-blue;
            background: #ffffff;
            background: $primary-yellow;
            /* height: 30px; */
            display: flex;
            justify-content: start;
            align-items: center;
            padding: 8px 10px;
            margin-top: 10px;
        }
    }
}
// Keep the footer where it belongs
body {
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}
footer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -23px;
    width: 100%;
    height: 790px;
    background-color: $primary-blue;

    @include breakpoint(sm) {
        height: 740px;
    }
    @include breakpoint(md) {
        bottom: 0;
        height: 650px;
    }
    @include breakpoint(md1) {
        height: 380px;
    }
    @include breakpoint(xl) {
        height: 300px;
    }
}
#main {
    padding-bottom: 750px;
    @include breakpoint(sm) {
        padding-bottom: 714px;
    }
    @include breakpoint(md) {
        padding-bottom: 648px;
    }
    @include breakpoint(md1) {
        padding-bottom: 378px;
    }
    @include breakpoint(xl) {
        padding-bottom: 298px;
    }
}
@media screen and (min-width: 991px) and (max-width: 1110px) {
    // footer {height:360px}
    // #main {padding-bottom: 350px;}
}
