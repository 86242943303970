.services-header {
    border-top: 1px solid $primary-yellow;
    margin-top: 3.5rem;
    padding-top: 3.5rem;
    h2 {
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 600;
        font-size: 4rem;
        line-height: 140%;
        /* identical to box height, or 56px */

        letter-spacing: -0.3px;
        text-transform: uppercase;

        /* Primary/color-primary-blue */

        color: $primary_blue;
        margin-bottom: 20px;
    }
    p {
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 400;
        font-size: 2rem;
        line-height: 140%;
        /* or 28px */

        letter-spacing: -0.3px;
    }
}
.services-list {
    > .row {
        column-gap: 3rem;
        justify-content: space-around;
        > .col-12 {
            max-width: 94%;
            @include breakpoint(sm) {
                max-width: 47%;
            }
            @include breakpoint(md) {
                max-width: 31%;
            }
            // @include breakpoint(md1) {
            //     max-width: 23%;
            // }
            @include breakpoint(lg) {
                max-width: 23%;
            }
            .ms-content-block {
                background: #ffffff;
                /* drop shadow */

                box-shadow: 0px 4px 12px rgba(163, 163, 163, 0.25);
                border-radius: 8px;
                display: flex;
                align-items: center;
                gap: 20px;
                padding: 15px 14px;
                margin-top: 3rem;
                .ms-content-block__image {
                    padding: 1.6rem 2rem;
                    width: 9rem;
                    height: 10rem;

                    /* Secondary-3 */
                    background: $primary_yellow;
                    /* drop shadow */
                    box-shadow: 0px 4px 12px rgba(163, 163, 163, 0.25);
                    border-radius: 8px;
                    @include breakpoint(md1){
                        width: 10rem;
                    }
                    img {
                        @include img-fit;
                        object-fit: contain;
                    }
                }
                .ms-content-block__details {
                    width: calc(100% - 12rem);
                    h3 {
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 2rem;
                        line-height: 150%;
                        /* identical to box height, or 30px */

                        /* Gray/700 */

                        color: #373f47;
                    }
                }
            }
        }
    }
}
