.homepage_popup {
    button.close {
        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2jbv?pubver=0') !important;
        background-position: center;
        background: no-repeat;
        opacity: 1;
        background-size: 1.8rem;
        width: 1.8rem;
        height: 1.8rem;
        padding: 0;
        margin: 0;
        span {
            display: none;
        }
    }
    .modal-content {
        border: 1px solid #eaecee;
        border-radius: 12px;
        .modal-header {
            border: 0;
        }
        .modal-body {
            padding: 0 2rem 0;
            .szf-modal-body {
                .festival-activities-item {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    gap: 1rem;

                    .festival-activities-item__img {
                        .ms-content-block__image {
                            width: 100%;
                            height: auto;
                            overflow: hidden;

                            // @include breakpoint(sm) {
                            //     height: 30rem;
                            // }
                            // @include breakpoint(md1) {
                            //     height: 35rem;
                            // }
                            // @include breakpoint(lg) {
                            //     height: auto;
                            // }
                            img {
                                @include img-fit;
                                border-radius: 12px;
                                transition: all 0.3s ease-in-out;
                            }
                        }
                    }
                    .msc-product {
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        .msc-product__img {
                            display: none;
                        }
                        .msc-product__title {
                            font-family: $font-family-primary;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 2.8rem;
                            line-height: 150%;
                            color: #373f47;
                            // min-height: 7.5rem;
                            margin-bottom: 0;
                            margin-top: 1rem;
                            @include text-ellipsis(3);
                            @include breakpoint(xl) {
                                font-size: 2.4rem;
                            }
                        }
                    }
                    .msc-product__desc {
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1.6rem;
                        line-height: 150%;
                        color: #373f47;
                        min-height: 6.5rem;
                        @include text-ellipsis(3);
                        @include breakpoint(xl) {
                            font-size: 2rem;
                        }
                    }

                    &__date {
                        display: flex;
                        gap: 10px;
                        svg {
                            width: 2rem;
                            height: 2rem;
                            object-fit: contain;
                            object-position: center;
                        }
                        p {
                            font-family: $font-family-primary;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 1.4rem;
                            line-height: 150%;
                            color: #373f47;
                            margin-bottom: 0;
                            margin-top: 1px;
                        }
                    }
                }
            }
        }
        .modal-footer {
            padding: 0 2rem 2rem;
            justify-content: flex-start;
            border: 0;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 40px;
                gap: 4px;
                background: $primary-blue;
                border-radius: 24px;
                border: none;
                height: 48px;
                transition: all 0.3s ease-in-out;

                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 140%;
                text-align: center;
                letter-spacing: -0.4px;
                color: #ffffff;
                text-decoration: none;
                width: 100%;
                @include breakpoint(md) {
                    width: auto;
                }
                &:hover {
                    background: $blue-hover;
                }
            }
        }
    }
}
