// Container Width
.partners-carousel-container {
    > .row {
        > .col-12 {
            &:nth-child(2) {
                max-width: 85%;
                margin: 0 auto;
            }
        }
    }
}
// Partners section styling
.partners-container {
    min-height: 50vh;
    background-color: #ffd367;
    background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBq1z?pubver=0');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 70px 0;
    // margin-top: -6px;
   
    .partners-heading h2 {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 3.4rem;
        line-height: 140%;
        letter-spacing: -0.3px;
        text-transform: uppercase;
        color: #ffffff;
        &:before {
            @include bottom-line($home_maroon);
            left:auto;
            right:auto
            // left: 36%;
            // @include breakpoint(md) {
            //     left: 46%;
            // }
        }
        text-align: center;
        @include breakpoint(md) {
            text-align: left;
        }

        @include breakpoint(xxl) {
            font-size: 4rem;
        }
    }
    .slick-slider {
        .ms-content-block {
            background: #ffffff;
            box-shadow: 0px 3px 12px rgba(166, 166, 166, 0.25);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            border-bottom: 6px solid #fbad36;
            height: 170px;
            margin: 60px 20px 30px;
            cursor: pointer;
        }
    }
    .partners-images {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        @include breakpoint(xxxl) {
            max-width: 95%;
        }

        > .row {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 0;

            > .col-12 {
                max-width: 25%;
                padding: 0;
                > .ms-content-block {
                    background: #ffffff;
                    box-shadow: 0px 3px 12px rgba(166, 166, 166, 0.25);
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    border-bottom: 6px solid #fbad36;
                    height: 170px;
                    margin-bottom: 30px;
                    margin-left: 40px;

                    img {
                        @include img-fit;
                    }
                }
            }
            .col-12:first-child {
                .ms-content-block {
                    // margin-left: 0;
                }
            }
        }
    }
}
