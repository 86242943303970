.social_links_container {
    z-index: 20;
    position: fixed;
    top: 25%;
    left: 0%;
    width: 40px;
    display: flex;
    flex-direction: column;

    .social_link {
        cursor: pointer;
        flex-direction: row-reverse;
        width: 167px;
        color: #fff;
        padding: 4px 6px;
        height: 30px;
        transform: translate(-140px, 0px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        margin: 0px;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-family: 'Oswald', sans-serif;
        transition: all 0.5s;
        &:hover {
            color: #fff;
            transform: translate(0px, 0px);
        }

        @include breakpoint(sm) {
            width: 180px;
            padding: 4px 10px;
            font-size: 11px;
            // padding: 4px 7px;
            height: 38px;
            // width: 160px;
        }
        a {
            color: #fff;
            text-decoration: none;

            .ms-content-block__image {
                height: 1.8rem;
                width: 2rem;
                img {
                    @include img-fit;
                }
            }
        }
    }
    .youtube {
        background: #aa2d2d;
    }
    .instagram {
        background: #892185;
    }
    .twitter {
        background: #067dec;
    }
    .snapchat {
        background: #ffe81a;
    }
    .facebook {
        background: #1f4285;
    }
}

// ------------------Site Picker------------------//
.ms-site-picker {
    z-index: 1;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    position: fixed;
    top: 25%;
    right: 0;
    display: flex;
    align-items: center;
    background: #fff;
    transition: all 0.5s ease-in-out;
    box-shadow: 0px 4px 12px rgba(163, 163, 163, 0.25);
    border-radius: 30px 0px 0px 30px;
    .drawer.ms-site-picker__drawer {
        display: flex;
        align-items: center;
        width: 100%;
    }

    button {
        background: transparent;
        background-color: transparent !important;
        height: 6rem;
        width: 7rem;
        border: none;
        position: relative;
        transition: all 0.5s ease-in-out;
        background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBq1C?pubver=1');
        background-repeat: no-repeat;
        background-size: 3.2rem;
        background-position: center;
        color: transparent;
        // box-shadow: 0px 4px 12px rgba(163, 163, 163, 0.25);
        // border-radius: 30px 0px 0px 30px;
        @include no-outline;
        &:focus {
            box-shadow: none !important;
        }
    }
    .ms-site-picker__list {
        margin-bottom: 0;
        list-style: none;
        padding-left: 0;
        transition: all 0.5s ease-in-out;
        padding-right: 1rem;
        height: 6rem;
        width: calc(100% - 7rem);
        transition: all 0.5s ease-in-out;
        li {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            font-family: $arabic-font-family;
            a {
                color: #000;
                font-weight: 600;
                text-decoration: none;
                cursor: pointer;
                margin: 0;
                font-family: $arabic-font-family;
            }
        }
    }
}

.ms-site-picker__list__item__link {
    font-size: 1.4rem;
    color: #000;
    text-decoration: none;
    height: 6rem;
    font-weight: bold;
    margin: 0 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
        color:#000;
        text-decoration: none;
    }
}

.drawer.ms-site-picker__drawer .btn-secondary:hover {
    color: transparent;
}
// Animation
.ms-site-picker,
.ms-site-picker__list,
.ms-site-picker__span {
    animation: slideIn 0.5s ease-in-out;
}

@keyframes slideIn {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
