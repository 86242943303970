.festival-activities__info {
    &__al-wathba-nights,
    &__layali-alwathba,
    &__festival-activities {
        display: none;
    }
    &__events-and-shows,
    &__daily-events,
    &__festival-attractions,
    &__festivals-list,
    &__fun-fair-city,
    &__competitions,
    &__the-emarati-civilization-and-culture-zone {
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 26px;
    gap: 10px;
    background: rgba(255, 211, 103, 0.14);
    border-radius: 12px;
    h5 {
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 140%;
        letter-spacing: -0.3px;
        text-transform: capitalize;
        color: $primary_blue;
        margin-bottom: 0;
    }
}
}