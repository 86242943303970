// -----------------Main Carousel-------------------

// .szf_homepage {
//     background: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBkOl?pubver=5');
//     background-attachment: fixed;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     transition: all 0.5s ease-in-out;
// }
// .szf_homepage.fireworks2 {
//     background: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBKPf?pubver=1');
//     background-attachment: fixed;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     transition: all 0.5s ease-in-out;
// }
// .szf_homepage.fireworks3 {
//     background: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBve4?pubver=1');
//     background-attachment: fixed;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     transition: all 0.5s ease-in-out;
// }

.main-promotion {
    // padding-bottom: 30px;
    margin-top: -$header-height;
    padding-top: $header-height;
    background: $banner-overlay;
    .ms-content-block__details {
        min-height: calc(100vh - 120px);
        min-height: 100vh;
        display: flex !important;
        justify-content: center !important;
        flex-direction: column;
        margin-left: 8%;
        margin-right: 8%;
        width: auto;

        // @include breakpoint(md) {
        //     width: 70%;
        // }
        // @include breakpoint(xxl) {
        //     width: 45%;
        // }

        // @include breakpoint(xxxl) {
        //     width: 35%;
        // }
        width: auto;
        @include breakpoint(md) {
            width: 70%;
        }
        @include breakpoint(xxl) {
            width: 55%;
        }

        h2,
        .ms-content-block__title {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 3rem;
            line-height: 140%;
            letter-spacing: -0.3px;
            text-transform: uppercase;
            color: #ffffff;
            text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.6);
            @include breakpoint(sm) {
                font-size: 4rem;
            }
            @include breakpoint(xxl) {
                font-size: 4rem;
            }
        }
        p {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 140%;
            text-align: justify;
            letter-spacing: -0.3px;
            color: #ffffff;
            margin: 1.4rem 0 4rem;
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            width: 200px;
            height: 48px;
            background: $primary-yellow;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 24px;
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 2rem;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.4px;
            color: $primary_blue;
        }
    }
    ol.msc-carousel__indicators {
        margin-bottom: 3rem;
    }

    // Hide dots hover text

    .msc-carousel__indicators {
        li[aria-describedby] + div {
            display: none;
        }
        li {
            height: 1.6rem;
            width: 1.6rem;
            border: none;
            background: #ffffff;
            mix-blend-mode: normal;
            opacity: 0.2;
            &:focus {
                outline: none;
                &:after {
                    outline: none;
                }
            }
        }
        .active {
            background: #919191;
            opacity: 1;
        }
    }

    // ---------------------Carousel Arrows ---------------------//
    .msc-carousel__control__prev,
    .msc-carousel__control__next {
        background-color: transparent;
        border: 0;
        &:before {
          
        }
        &:focus {
            background-color: transparent;
            box-shadow: none;
            border: none;
        }
        &:hover {
            background-color: transparent;
            box-shadow: none;
        }
        &__icon {
            background-repeat: no-repeat;
            // top: 13px;
            // left: 18px;
            background-position: center;
            @media screen and (max-width: 768px) {
                width: 18px;
                height: 18px;
                background-size: contain;
            }
            &:before {
                content: '';
            }
        }
    }
    .msc-carousel__control__prev {
        &__icon {
            background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBkOY?pubver=0');
        }
    }
    .msc-carousel__control__next {
        &__icon {
            background-image: url('https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MBkOS?pubver=1');
        }
    }
    .msc-tooltip-inner {
        color: #fff;
    }
}
// ----------------------------------------- Fixed attachment / Fixed bg image
.szf_homepage.has-main-carousel {
    .main-promotion {
        .ms-content-block__image {
            // position: fixed;
            top:0;
        }
    }
}