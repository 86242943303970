.bus-main-container {
    background: #e8d6c0;
    margin-top: 7rem;
    .bus-header {
        border: none;

        .ms-content-block {
            position: relative;
            .ms-content-block__cta {
                @include ghost_cta_with_icon;
                border-radius: 24px;
            }
        }
    }

    .bus-images {
        > .row {
            > .col-12 {
                max-width: 100%;
                @include breakpoint(md) {
                    max-width: 50%;
                }
                .ms-content-block__image {
                    img {
                        @include img-fit;
                    }
                }
            }
        }
    }
}
