.partner-card-container {
    padding-top: 40px;
    @include breakpoint(md) {
        padding-top: 90px;
    }
    h4 {
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 600;
        font-size: 4rem;
        line-height: 140%;
        /* identical to box height, or 56px */

        letter-spacing: -0.3px;
        text-transform: uppercase;

        /* Primary/color-primary-blue */

        color: $primary_blue;
        text-align: center;
        @include breakpoint(md) {
            text-align: left;
        }
    }
    .partner-card-div {
        > .row {
            > .col-12 {
                max-width: 100%;
                @include breakpoint(md) {
                    max-width: 50%;
                }
                @include breakpoint(md1) {
                    max-width: 25%;
                }
                .partner-card {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 24px 14px;
                    gap: 20px;
                    // width: 272px;
                    min-height: auto;

                    /* Base/background white */
                    background: #ffffff;
                    /* Gray/200 */
                    border: 1px solid #eaecee;
                    border-radius: 12px;
                    text-align: center;
                    margin-top: 25px;

                    @include breakpoint(md) {
                        min-height: 300px;
                    }
                    @include breakpoint(md1) {
                        margin-top: 40px;
                    }

                    p {
                        font-family: $font-family-primary;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 2rem;
                        line-height: 150%;
                        /* or 24px */

                        text-align: center;

                        /* Gray/700 */

                        color: $text-color;
                    }
                }
            }
        }
    }
}

.overview-container {
    padding: 40px 0;
    @include breakpoint(md) {
        padding: 90px 0;
    }
    .overview-header {
        h4 {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 4rem;
            line-height: 140%;
            /* identical to box height, or 56px */
            letter-spacing: -0.3px;
            text-transform: uppercase;
            color: $primary_blue;
            margin-bottom: 20px;
            text-align: center;
            @include breakpoint(md) {
                text-align: left;
            }
        }
        p {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 400;
            font-size: 2rem;
            line-height: 140%;
            /* identical to box height, or 28px */

            letter-spacing: -0.3px;
            color: $primary_blue;
            text-align: center;
            @include breakpoint(md) {
                text-align: left;
            }
        }
    }
    .overview-cards-div {
        padding: 0;
        .ms-content-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0px 0px 24px;
            gap: 20px;

            // width: 390px;
            min-height: 370px;

            /* Base/background white */

            background: #ffffff;
            /* Gray/200 */

            border: 1px solid #eaecee;
            border-radius: 12px;
            margin-top: 25px;
            @include breakpoint(md1) {
                margin-top: 40px;
            }

            .ms-content-block__details {
                padding: 0 20px 0;
                h2 {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 150%;
                    /* identical to box height, or 30px */

                    /* Gray/700 */

                    color: #373f47;
                }
                p {
                    font-family: $font-family-primary;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 150%;
                    /* or 21px */

                    /* Gray/700 */

                    color: #373f47;
                }
            }

            .ms-content-block__image {
                overflow: hidden;
                width: 100%;
                img {
                    @include img-fit();
                    border-radius: 12px 12px 0 0;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}
