.arabic {
    .product-list-page__festivals .festival-activities-item__desc,
    .product-list-page__festivals .festival-activities-item p {
        text-align: right;
    }
    .product-list-page__festivals .festival-activities-item .msc-product h2 {
        text-align: right;
    }

    .szf-plp-banner__container {
        .szf-plp-banner__content {
            padding-right: 8%;
            @include breakpoint(md) {
                padding-left: 30%;
               
            }

            @include breakpoint(xxl) {
                padding-left: 35%;
            }
        }
    }
}
