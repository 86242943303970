.product-list-page__festivals__al-wathba-nights,
.product-list-page__festivals__layali-alwathba {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 4%;
    row-gap: 2rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
    // @include breakpoint(sm){
    //     column-gap: 3%;
    //  }
    @include breakpoint(lg) {
        column-gap: 3%;
    }
    .festival-activities-item {
        width: 98%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 2.4rem;
        gap: 2rem;
        background: #ffffff;
        border: 1px solid #eaecee;
        border-radius: 12px;
        @include breakpoint(sm) {
            width: 48%;
        }
        @include breakpoint(lg) {
            width: 31%;
        }
        .festival-activities-item__img {
            width: 100%;
            height: 35rem;
            overflow: hidden;
            &:hover {
                img {
                    transform: scale(1.5);
                }
            }
            @include breakpoint(sm) {
                height: 30rem;
            }
            @include breakpoint(xl) {
                height: 35rem;
            }
            img {
                @include img-fit;
                border-radius: 12px;
                transition: all 0.3s ease-in-out;
            }
        }
        .msc-product {
            display: flex;
            width: 100%;
            flex-direction: column;
            .msc-product__img {
                display: none;
            }
            .msc-product__title {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 600;
                font-size: 2.8rem;
                line-height: 150%;
                color: #373f47;
                // min-height: 7.5rem;
                margin-bottom: 0;
                @include text-ellipsis(2);
                @include breakpoint(xl) {
                    font-size: 2.4rem;
                }
            }
        }
        .msc-product__desc {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 150%;
            color: #373f47;
            min-height: 6.5rem;
            @include text-ellipsis(3);
            @include breakpoint(xl) {
                font-size: 2rem;
            }
        }
        .festival-activities-item__cta {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            gap: 4px;
            background: $primary-blue;
            border-radius: 24px;
            border: none;
            height: 48px;
            transition: all 0.3s ease-in-out;
            &:hover {
                background: $blue-hover;
            }
            a {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 140%;
                text-align: center;
                letter-spacing: -0.4px;
                color: #ffffff;
                text-decoration: none;
            }
            // display: none;
        }
        .festival-activities-item__cta.hide{
            display: none;
        }
        &__date {
            display: flex;
            gap: 10px;
            svg {
                width: 2rem;
                height: 2rem;
                object-fit: contain;
                object-position: center;
            }
            p {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 600;
                font-size: 1.4rem;
                line-height: 150%;
                color: #373f47;
                margin-bottom: 0;
                margin-top:1px;
            }
        }
    }
}

// .concerts {
//     .press-release-list {
//         .default-container {
//             > .row {
//                 > .col-12 {
//                     &:first-child {

//                         bottom:30rem;
//                         top:auto;
//                         @include breakpoint(sm){
//                             bottom:28.5rem;
//                         }@include breakpoint(xxl){
//                             bottom:27rem;
//                         }
//                         @include breakpoint(xxxl){
//                             bottom:27rem;
//                         }
//                     }

//                 }
//             }
//         }
//         .ms-content-block {
//             h3 {
//                 height: 3rem;
//                 @include text-ellipsis(1);
//             }
//             p {
//                 @include text-ellipsis(3);
//                 min-height: 7rem;
//             }
//             .ms-content-block__image {
//                 height: auto;
//                 // @include breakpoint(xxxl) {
//                 //     height: 50rem;
//                 // }
//             }
//         }
//     }
// }
